import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.div``;
export const Row = styled.div``;

export const Title = styled.h4`
  font-weight: 600;
  align-self: center;
  text-align: center;
  color: var(--app-color-primary);
  letter-spacing: 1px;
  margin-bottom: 0;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    margin-top: 40px;
  }
`;

export const SubTitle = styled.h4`
  font-weight: 400;
  align-self: center;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px;
`;

export const AddRecs = styled.div`
  color: var(--app-color-primary);
  cursor: pointer;
  text-decoration: underline;
`;

export const Location = styled.h3`
  font-weight: 600;
  align-self: center;
  text-align: center;
  letter-spacing: 1px;
  margin: 10px;
`;

export const Description = styled.p`
  margin-top: 0;
  line-height: 24px;
`;

export const Text = styled.p`
  line-height: 24px;
`;

export const Bold = styled.strong`
  vertical-align: revert;
`;

export const AbandonCart = styled.div`
  margin: auto;
  margin-top: 30px;
  max-width: 600px;
  .subtitle{
    font-size: 1.4rem;
  }
`;

export const Backdrop = styled.div`
  background-color: white;
  height: 100vh;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  margin-top: 50px;
`;

export const Button = styled.div`
  align-self: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 190px;
  width: 50%;
  button{
    background: white;
    color: black;
  }
  p{
    font-weight: 300;
    text-align: center;
  }
`;
