import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFavorites } from '../../provider/favorites-context';
import {
  Title,
  SubTitle,
  AddRecs,
  Location,
  Description,
  Bold,
  Text,
} from './save-favorites-form.styled';
import { navigate } from 'gatsby';
import { Form } from '../';
import { Checkbox, SendToSelfLabel } from '../form/form.styled';
import { usePatient } from '../../provider/patient-context';
import { useECPLocation } from '../../provider/location.context';
import FDButton from '../button/button';
import { environment } from '../../environments/environments';
import { Event } from '../../utils';
import { useLensSelector } from '../../provider/lens-context';

const SaveFavoritesForm = ({
  handleModalClose,
  openAbandonCart,
  handleAbandonCartClose,
  handleCloseCancel,
  setHasSentFavorites,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showFormFields, setShowFormFields] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [sendToSelf, setSendToSelf] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const { submitSendFavoritesRequest, favorites } = useFavorites();
  const { location } = useECPLocation();
  const { patient } = usePatient();
  const [errorSendingFavorites, setErrorSendingFavorites] = useState(false);
  const { lensRecs, handleLensOpen, lensRecNames, lensRecDescs } =
    useLensSelector();

  const [sendFavoritesRequest, setSendFavoritesRequest] = useState({
    doSendToSelf: true,
    doSendToConsumerDataPlatform: true,
    ecpAccountCode: environment.getAccountCode(),
    patientId: environment.getPatientId(),
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    upcs: favorites.map((favorite) =>
      favorite.data.SelectedFrame.Upc.toString()
    ),
    lensRecs: lensRecs,
    lensRecNames: lensRecNames,
    lensRecDescs: lensRecDescs,
  });
  const patientArr = [
    {
      id: 'FirstName',
      placeholder: '',
      label: 'First Name',
      required: 'Please enter your first name',
      maxLength: {
        value: 50,
        message: 'Character limit exceeded: 50',
      },
      type: 'text',
      value: patient.FirstName,
    },
    {
      id: 'LastName',
      placeholder: '',
      label: 'Last Name',
      required: 'Please enter your last name',
      maxLength: {
        value: 100,
        message: 'Character limit exceeded: 100',
      },
      type: 'text',
      value: patient.LastName,
    },
    {
      id: 'Phone',
      placeholder: '',
      label: 'Phone',
      required: 'Please enter your phone number',
      maxLength: {
        value: 50,
        message: 'Character limit exceeded: 50',
      },
      type: 'tel',
      value: patient.Phone,
    },
    {
      id: 'Email',
      placeholder: '',
      label: 'Email',
      required: 'Please enter your email',
      maxLength: {
        value: 200,
        message: 'Character limit exceeded: 200',
      },
      type: 'email',
      value: patient.Email,
    },
  ];

  useEffect(() => {
    if (
      patient &&
      patient.FirstName &&
      patient.LastName &&
      patient.Phone &&
      patient.Email
    ) {
      setShowFormFields(false);
    }
  }, []);

  const formInputs = patientArr.map((item, i) => {
    return (
      <span key={item.id}>
        {errors[item.id] && <Form.Error>{errors[item.id].message}</Form.Error>}

        <Form.Label htmlFor={item.id}>
          <span>{item.label}</span>
          <input
            {...register(item.id, {
              required: item.required,
              maxLength: item.maxLength,
              defaultValues: item.value,
            })}
            type={item.type}
            id={item.id}
            maxLength={201}
            placeholder={item.placeholder}
            defaultValue={item.value}
          />
        </Form.Label>
      </span>
    );
  });

  const handelOnSubmit = (data) => {
    setIsValid(true);
    setSendFavoritesRequest({
      ...sendFavoritesRequest,
      doSendToSelf: sendToSelf,
      firstName: data.FirstName,
      lastName: data.LastName,
      phone: data.Phone,
      email: data.Email,
      lensRecs: lensRecs,
      lensRecNames: lensRecNames,
      lensRecDescs: lensRecDescs,
    });
  };

  const handelSendFavoritesRequest = () => {
    submitSendFavoritesRequest(sendFavoritesRequest, setSubmitError);
  };

  useEffect(() => {
    if (isValid) {
      setHasSentFavorites(true);
      handelSendFavoritesRequest();
    }
  }, [sendFavoritesRequest]);

  return (
    <>
      {isValid && (
        <>
          <br />
          <p className='txt__center txt__caps font__size__small'>
            FAVORITES SENT TO:
          </p>
          <Location className='txt__center txt__caps font__size__large'>
            <Bold>{location.Name}</Bold>
          </Location>
          <hr size='1' width='100%'></hr>
          <br />
          <h1 className='txt__center color__primary txt__caps font__size__large'>
            <Bold>Congratulations</Bold>
          </h1>
          <Description className='txt__center font__size__small'>
            You have successfully
            <Bold> sent</Bold> a copy of
            <Bold> your Favorite Frame Selections</Bold> to
            <Bold> Try-On</Bold> in our office!
          </Description>
          <br />
          {sendFavoritesRequest.doSendToSelf && (
            <>
              <Text className='txt__center font__size__small'>
                As well as sent a copy to yourself at this address:
              </Text>
              <Text className='txt__center font__size__small'>
                <Bold>{sendFavoritesRequest.email}</Bold>
              </Text>
            </>
          )}
          <hr size='1' width='100%'></hr>
          <Text className='txt__center font__size__small'>
            If you've sent this list in error, you may correct your selections
            and re-send.
          </Text>
          <br />

          <FDButton handleClick={() => handleCloseCancel()}>Close</FDButton>
        </>
      )}
      {!isValid && (
        <>
          <Title className='txt__center '>Send Favorites to</Title>
          <Location className='txt__center txt__caps'>{location.Name}</Location>
          <SubTitle className='txt__center '>
            {favorites.length} Frame Favorite(s),&nbsp;
            {lensRecs.length === 0 ? (
              <AddRecs onClick={handleLensOpen}>Customize Your Lens</AddRecs>
            ) : (
              'With Customized Lens'
            )}
          </SubTitle>
          <Form>
            {!showFormFields && (
              <Form.ShowForm handleClick={() => setShowFormFields(true)} />
            )}
            <Form.Inner style={showFormFields ? {} : { display: 'none' }}>
              {formInputs}
              <hr />
              <Title className=' color__primary'>
                <SendToSelfLabel htmlFor='doSendToSelf'>
                  <Checkbox
                    type='checkbox'
                    id='doSendToSelf'
                    {...register('doSendToSelf', {
                      required: false,
                    })}
                    onClick={() => setSendToSelf(!sendToSelf)}
                    onChange={() => null}
                    checked={sendToSelf}
                  />
                  Send a copy of my Favorites to myself
                </SendToSelfLabel>
              </Title>
            </Form.Inner>

            <Form.CenterRow>
              <Form.CancelBtn handleClose={() => handleModalClose()} />
              <Form.SubmitBtn
                onSubmit={handleSubmit((data) => {
                  const action = sendToSelf
                    ? 'Click_Send_Self_Favorites'
                    : 'Click_Send_Favorites';
                  const keys = favorites.map((f) => {
                    return f.key;
                  });
                  Event('FD_Favorites_Form', action, `upcs:{${keys.join()}}`);
                  handelOnSubmit(data);
                })}
              />
            </Form.CenterRow>
          </Form>
        </>
      )}
    </>
  );
};

export default SaveFavoritesForm;
