import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.div`
  text-align: center;
`;

export const Description = styled.h3`
  letter-spacing: 1px;
`;

export const Title = styled.h1`
  letter-spacing: 1px;
  font-weight: var(--app-font-weight-bold);
`;
