import React, { useEffect, useState } from 'react';
import { useCatalog } from '../../provider/catalog-context';
import { useFavorites } from '../../provider/favorites-context';
import Card from '../card/card';
import OpenHeartIcon from '../../images/open-heart-icon.js';
import XIcon from '../../images/x-icon.js';
import ClosedHeartIcon from '../../images/closed-heart-icon.js';
import { ContainerFullWidth } from '../app/app.styled';
import FDButton from '../button/button';
import { Link, navigate } from 'gatsby';
import {
  Container,
  AlignLeft,
  ActionText,
  AlignRight,
  Text,
  ActionContainer,
} from './skeleton-cards.styled';
import { Event } from '../../utils';

const SkeletonCards = ({
  handleOpenFrameSelectorModal,
  handleOpenLensSelectorModal,
  list,
  favoritesCards,
  handleColorSelect,
  pageNumber,
  pageSize,
  resultsSize,
  loadNextPage,
  showResultsBar,
  colorUrlMap,
  noCardsText,
}) => {
  const {
    favorites,
    addFavorite,
    removeFavorite,
    checkFavorite,
    reachedMaxLimit,
    toggleMaxFavoritesAlert,
    handleSendFavoritesModalOpenFavPage,
  } = useFavorites();
  const { catalog, getFacetFilters } = useCatalog();

  const handelColorSelect = ({ frame, modelNumber }) => {
    Event(
      'FD_Catalog',
      'Click_Frame_Color',
      `upc:{${frame.Upc}},color:{${frame.Color}}`
    );
    handleColorSelect(frame, modelNumber);
  };

  const handleFavorite = (model, frame) => {
    if (reachedMaxLimit()) {
      toggleMaxFavoritesAlert();
    } else {
      addFavorite(model, frame);
    }
    Event(
      'FD_Catalog',
      'Click_Add_Favorites',
      `upc:{${frame.Upc}},count:{${favorites.length + 1}}`
    );
  };

  const handleUnFavorite = (model) => {
    removeFavorite(model);
    Event(
      favoritesCards ? 'FD_Favorites' : 'FD_Catalog',
      'Click_Remove_Favorites',
      `upc:{${model.Upc}},count:{${favorites.length - 1}}`
    );
  };

  const isFavorite = (key) => {
    return checkFavorite(key);
  };

  const favoriteIcon = (model, frame) => {
    if (favoritesCards) {
      return <XIcon handleClick={() => handleUnFavorite(frame)} />;
    } else if (isFavorite(frame)) {
      return <ClosedHeartIcon handleClick={() => handleUnFavorite(frame)} />;
    } else
      return <OpenHeartIcon handleClick={() => handleFavorite(model, frame)} />;
  };

  let cards = list ?? [
    {
      SelectedFrame: { PreviewImageURL: '' },
      Model: {},
      Frames: [{}],
      PreviewImageURL: '',
      ModelName: '',
      ModelNumber: '',
    },
  ];

  if (list) {
    let tempArray = [];
    list.forEach((element) => {
      tempArray.push(element.data ?? element);
    });
    cards = tempArray;
  }

  useEffect(() => {
    let delay = setTimeout(() => {
      if (cards.length === 0) {
        Event('FD_Catalog', 'Display_No_Frames', getFacetFilters());
      }
    }, 3000);
    return () => {
      clearTimeout(delay);
    };
  }, [cards]);

  return (
    <>
      <Container>
        {showResultsBar && (
          <ContainerFullWidth className='border__bottom__mobile'>
            <AlignLeft>
              Use our&nbsp;
              <ActionText
                onClick={() => {
                  Event('FD_Catalog', 'Click_Use_Frame_Selector', '');
                  handleOpenFrameSelectorModal();
                }}
              >
                Frame Selector
              </ActionText>
              &nbsp;to help you&nbsp;<b>find the perfect pair</b>!
            </AlignLeft>
            <AlignRight className='md-down-hide'>
              Showing {catalog.length > 0 ? `1-${catalog.length} of ` : ``}
              {resultsSize} Results
            </AlignRight>
          </ContainerFullWidth>
        )}

        {favoritesCards && favorites.length > 0 && (
          <ContainerFullWidth className='border__bottom__mobile'>
            <AlignLeft>
              Customize your lens with our&nbsp;
              <ActionText
                onClick={() => {
                  Event('FD_Favorites', 'Click_Use_Lens_Selector', '');
                  navigate('/');
                  handleOpenLensSelectorModal();
                }}
              >
                Lens Selector!
              </ActionText>
            </AlignLeft>
            <AlignRight className='md-down-hide'>
              Showing your {`${favorites.length} of 10`} Favorites
            </AlignRight>
          </ContainerFullWidth>
        )}

        {cards.length > 0 ? (
          cards.map((model, i) => {
            return (
              <Card
                key={model.ModelNumber}
                frames={model.Frames}
                favClass={
                  isFavorite(model.SelectedFrame) ? 'favorited-frame' : ''
                }
              >
                <Card.Favorite
                  tooltip={
                    isFavorite(model.SelectedFrame)
                      ? 'Remove Favorite'
                      : 'Add Favorite'
                  }
                >
                  {favoriteIcon(model, model.SelectedFrame)}
                </Card.Favorite>
                <Card.Image
                  frame={model.SelectedFrame ?? model.data.SelectedFrame}
                />
                <Card.Title
                  model={model}
                  onClick={() => {
                    Event(
                      favoritesCards ? 'FD_Favorites' : 'FD_Catalog',
                      'Click_View_Frame',
                      `upc:{${model.SelectedFrame.Upc}}`
                    );
                    navigate(
                      `/product/${model.ModelNumber}?upc=${model.SelectedFrame.Upc}`
                    );
                  }}
                >
                  {model.Brand + ' ' + model.ModelName}
                </Card.Title>
                <Card.ClickDiv
                  model={model}
                  onClick={() => {
                    Event(
                      favoritesCards ? 'FD_Favorites' : 'FD_Catalog',
                      'Click_View_Frame',
                      `upc:{${model.SelectedFrame.Upc}}`
                    );
                    navigate(
                      `/product/${model.ModelNumber}?upc=${model.SelectedFrame.Upc}`
                    );
                  }}
                />
                <Card.ColorSelectRow>
                  {model?.Frames?.map((frame, j) => (
                    <span key={j}>
                      {frame.Upc === model.SelectedFrame.Upc && (
                        <Card.ColorHighlight />
                      )}
                      <Card.ColorSelect
                        colorUrlMap={colorUrlMap}
                        key={frame.Upc}
                        frame={frame}
                        modelNumber={model.ModelNumber}
                        swatch={frame.Color}
                        handelColorSelect={handelColorSelect}
                      />
                    </span>
                  ))}
                </Card.ColorSelectRow>
              </Card>
            );
          })
        ) : (
          <Text>{noCardsText}</Text>
        )}
        <ContainerFullWidth className='m__t__large'>
          {catalog.length === pageSize * pageNumber && !favoritesCards && (
            <FDButton
              handleClick={() => {
                const frames = catalog.map((c) => {
                  return c.Frames;
                });
                const upcs = frames.map((f) => {
                  return f.map((u) => {
                    return u.Upc;
                  });
                });
                Event('FD_Catalog', 'Click_Show_More', `ucps:{${upcs.join()}}`);
                loadNextPage();
              }}
              style={{ border: '0' }}
            >
              Show More
            </FDButton>
          )}
        </ContainerFullWidth>

        {favoritesCards && (
          <ActionContainer>
            {favorites.length === 0 && (
              <ActionText
                onClick={() => {
                  Event('FD_Catalog', 'Click_Use_Frame_Selector', '');
                  navigate('/');
                  handleOpenFrameSelectorModal();
                }}
              >
                Use our guided quiz to find the perfect match for you
              </ActionText>
            )}
            {favorites.length > 0 && (
              <>
                <Text>
                  <b>Send Favorite Frames</b> to your eyecare provider and they
                  will be ready for you to try-on during your visit at{' '}
                  <b>no additional cost</b>.
                </Text>
                <FDButton
                  handleClick={() => handleSendFavoritesModalOpenFavPage()}
                  disabled={favorites.length === 0}
                  style={{ border: '0' }}
                >
                  Send Now
                </FDButton>
              </>
            )}
          </ActionContainer>
        )}
      </Container>
    </>
  );
};

export default SkeletonCards;
