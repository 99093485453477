import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';
import { useProduct } from '../provider/product-context';
import { useCatalog } from './catalog-context';

const PopularProductsContext = createContext();
const usePopularProducts = () => useContext(PopularProductsContext);
const PopularProductsContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const { facetFilters } = useCatalog();
  const [popularCurrentPage, setPopularCurrentPage] = useState(0);
  const [popularPaginatedArray, setPopularPaginatedArray] = useState([]);
  const [popularProductsPerFrame, setPopularProductsPerFrame] = useState(5);

  useEffect(() => {
    if (popularPaginatedArray.length === 0) {
      fetchList([]);
    } else {
      if (environment.getEnv() === 'local' || environment.getEnv() === 'dev') {
        fetchList([]);
      }
    }
  }, [facetFilters]);

  useEffect(() => {
    let cards = products ?? [
      {
        Model: {},
        Frames: [{}],
        PreviewImageURL: '',
        ModelName: '',
        ModelNumber: '',
      },
    ];
    setPopularPaginatedArray([]);
    let index = 0;
    for (index = 0; index < cards.length; index += popularProductsPerFrame) {
      let page = cards.slice(index, index + popularProductsPerFrame);
      setPopularPaginatedArray((popularPaginatedArray) => [
        ...popularPaginatedArray,
        page,
      ]);
    }
  }, [products, popularProductsPerFrame]);

  const fetchList = (list) => {
    const url = environment.getBaseAPI() + 'api/frames/popular';
    const data = {
      AccountCode: environment.getAccountCode(),
      GoCode: environment.getPatientId(),
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        AccountCode: data.AccountCode,
        PatientId: data.GoCode,
        FacetFilters:
          environment.getEnv() === 'local' || environment.getEnv() === 'dev'
            ? facetFilters
            : [],
      }),
      credentials: 'same-origin',
    })
      .then((res) => res.json())
      .then((result) => Promise.resolve(result))
      .then((response) => {
        setPopularCurrentPage(0);
        setProducts(
          list.concat(
            response.models.map((model) => {
              return {
                ModelNumber: model.modelNumber,
                ModelName: model.modelName,
                Brand: model.brand,
                Frames: model.frameItems.map((frame) => {
                  return {
                    Upc: frame.upc,
                    Fpc: frame.fpc,
                    Color: frame.color,
                    PreviewImageURL: frame.largeImageUrl,
                  };
                }),
                SelectedFrame: {
                  Upc: model.frameItems[0].upc,
                  Fpc: model.frameItems[0].fpc,
                  Color: model.frameItems[0].color,
                  PreviewImageURL: model.frameItems[0].largeImageUrl,
                },
              };
            })
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const value = {
    popularCurrentPage,
    setPopularCurrentPage,
    popularPaginatedArray,
    setPopularProductsPerFrame,
  };

  return (
    <PopularProductsContext.Provider value={value}>
      {props.children}
    </PopularProductsContext.Provider>
  );
};

export { PopularProductsContextProvider, usePopularProducts };
