import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.div`
  display: flex;
  align-items: space-between;
  div {
    cursor: pointer;
    margin: auto;
    padding: 20px 40px;
    border: 1px solid var(--app-color-primary);
    font-size: var(--app-font-size-large);
    font-weight: 900;
    color: var(--app-color-bg-bright);
    background: var(--app-color-primary);
    border-radius: var(--app-border-radius);
    white-space: nowrap;

    :hover {
      background: var(--app-color-bg-bright);
      color: var(--app-color-primary);

      @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
        background: var(--app-color-primary);
        color: var(--app-color-bg-bright);
      }
    }
  }
`;
