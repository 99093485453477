import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 5;
  justify-content: center;
  display: flex;
`;

export const SearchInput = styled.input`
  border-radius:50px;
  padding:15px;
  border:none;
  background-color:var(--app-color-bg-light);
  display: flex;
  flex-grow: .4;
  transform: translateY(-15%);
`;




