import React, { useEffect, useState } from 'react';
import { useCatalog } from '../../provider/catalog-context';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Typography } from '@material-ui/core';
import {
  Container,
  FiltersHolder,
  FiltersHeadline,
  FiltersList,
  FiltersListItem,
  FiltersListItemHolder,
  ClearFilters,
  FiltersMobileBar,
  LightTooltip,
} from './catalog-filters.styled';
import { FiAlertCircle } from 'react-icons/fi';
import IconArrow from '../../images/icon-arrow-left.svg';
import { BsFilterLeft } from 'react-icons/all';
import FDFilter from '../../images/icon-filter.svg';

import CloseIcon from '@material-ui/icons/Close';
import { isMobile, useWindowDimension } from '../../utils';
import FrameSizeImage from '../../images/gfx-frame-size.svg';
import { Event } from '../../utils';
import { SCREEN_SIZE } from '../app/app.styled';

const CatalogFilters = () => {
  const {
    facets,
    facetFilters,
    addOrRemoveFacetFilter,
    clearAllFacetFilters,
    toggleModalFilters,
    showModalFilters,
    selectAllOfOneFacet,
    clearOneFacetFilter,
    getFacetFilters,
  } = useCatalog();
  const [isActiveFilter, setIsActiveFilter] = React.useState(null);

  const handleOnUpdateActiveFilter = (_filter) => {
    if (isActiveFilter !== _filter.FacetName) {
      setIsActiveFilter(_filter.FacetName);
    } else {
      setIsActiveFilter(null);
    }
  };

  useEffect(() => {
    let value = showModalFilters ? 'hidden' : '';
    document.body.style.overflow = value;
  }, [showModalFilters]);

  const handleOnClick = (facetIndex, optionIndex) => {
    Event(
      'FD_Catalog',
      'CLick_Filter_Selected',
      `filter_name:{${facets[facetIndex].FacetName}},filter_value:{${facets[facetIndex].FacetOptions[optionIndex].Name}}`
    );

    addOrRemoveFacetFilter(
      facets[facetIndex].FacetName,
      facets[facetIndex].FacetOptions[optionIndex].Name
    );
  };

  const handleOnClickTooltip = (event) => {
    event.stopPropagation();
  };

  const handleSelectAllOnClick = (facetIndex) => {
    if (isSelectAllChecked(facetIndex)) {
      clearOneFacetFilter(facets[facetIndex].FacetName);
    } else {
      selectAllOfOneFacet(facets[facetIndex].FacetName);
    }
  };

  const isSelectAllChecked = (facetIndex) => {
    let index = facetFilters.findIndex(
      (filter) => filter.FilterName === facets[facetIndex].FacetName
    );
    return (
      index > -1 &&
      facetFilters[index].FacetValues.length ===
        facets[facetIndex].FacetOptions.length
    );
  };

  const isFacetChecked = (facetIndex, optionIndex) => {
    let index = facetFilters.findIndex(
      (filter) => filter.FilterName === facets[facetIndex].FacetName
    );
    return index > -1
      ? facetFilters[index].FacetValues.findIndex(
          (value) => value === facets[facetIndex].FacetOptions[optionIndex].Name
        ) > -1
      : false;
  };

  const [width, height] = useWindowDimension();

  return (
    <Container id='filters'>
      {(showModalFilters || !isMobile() || width < SCREEN_SIZE.sm) && (
        <FiltersHolder className={'layout__max-width'}>
          <div className='md-up-hide'>
            <FiltersMobileBar>
              <div
                className='btn__filter cursor'
                onClick={() => toggleModalFilters()}
              >
                <img src={FDFilter} />
              </div>
              <p>Sort Results</p>
              <div
                className='btn__close cursor'
                onClick={() => toggleModalFilters()}
              >
                <CloseIcon />
              </div>
            </FiltersMobileBar>
          </div>

          {facets.map((facet, facetIndex) => {
            return (
              <FiltersList
                key={facetIndex}
                className={isActiveFilter === facet.FacetName ? 'active' : ''}
              >
                <FiltersHeadline
                  onClick={() => handleOnUpdateActiveFilter(facet)}
                  key={facetIndex + 'H'}
                  style={
                    facetFilters.find(
                      (filter) => filter.FilterName === facet.FacetName
                    )
                      ? { color: 'var(--app-color-primary)' }
                      : {}
                  }
                >
                  {facet.FacetName.replace(
                    /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                    '$1$4 $2$3$5'
                  )}

                  <img
                    className={
                      isActiveFilter === facet.FacetName && isMobile()
                        ? 'active'
                        : ''
                    }
                    src={IconArrow}
                    alt='dropdown arrow'
                  />
                </FiltersHeadline>
                <FiltersListItemHolder key={facetIndex + 'L'}>
                  <FiltersListItem key={facetIndex + '.' + 'sa' + 'j'}>
                    <label
                      htmlFor={'SelectAll' + facetIndex}
                      key={facetIndex + '.' + 'sa' + 'l'}
                    >
                      <input
                        key={facetIndex + '.' + 'sa' + 'i'}
                        id={'SelectAll' + facetIndex}
                        type='checkbox'
                        onClick={() => handleSelectAllOnClick(facetIndex)}
                        onChange={() => null}
                        checked={isSelectAllChecked(facetIndex)}
                      />
                      Select All
                    </label>
                  </FiltersListItem>
                  {facet.FacetOptions.map((option, optionIndex) => {
                    return (
                      <FiltersListItem
                        key={facetIndex + '.' + optionIndex + 'j'}
                      >
                        <label
                          htmlFor={option.Name}
                          key={facetIndex + '.' + optionIndex + 'l'}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <input
                            key={facetIndex + '.' + optionIndex + 'i'}
                            id={option.Name}
                            type='checkbox'
                            onClick={() =>
                              handleOnClick(facetIndex, optionIndex)
                            }
                            onChange={() => null}
                            checked={isFacetChecked(facetIndex, optionIndex)}
                          />
                          {option.Name} ({option.Quantity})
                        </label>
                      </FiltersListItem>
                    );
                  })}

                  {facet.FacetName == 'Size' ? (
                    <Grid container direction='row'>
                      <Grid item style={{ width: '85%', marginLeft: '5px' }}>
                        <Typography>Size is based on the lens Width</Typography>
                      </Grid>
                      <Grid
                        item
                        style={{
                          width: '10%',
                          marginBottom: '10px',
                          marginTop: '-10px',
                        }}
                      >
                        <LightTooltip
                          enterTouchDelay={20}
                          leaveTouchDelay={2000}
                          arrow
                          onClick={handleOnClickTooltip}
                          placement='top-end'
                          title={
                            <Grid
                              container
                              direction='column'
                              style={{
                                padding: '5px 4px',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '170px',
                              }}
                            >
                              <Grid item style={{ width: '150px' }}>
                                <Typography variant='caption'>
                                  {'Small: 40-48mm'}
                                </Typography>
                                <br />
                                <Typography variant='caption'>
                                  {'Medium: 49-54mm'}
                                </Typography>
                                <br />
                                <Typography variant='caption'>
                                  {'Large: 55mm+'}
                                </Typography>
                              </Grid>
                              <Grid item style={{ width: '150px' }}>
                                <img
                                  src={FrameSizeImage}
                                  alt='dropdown arrow'
                                />
                              </Grid>
                            </Grid>
                          }
                        >
                          <IconButton>
                            <FiAlertCircle />
                          </IconButton>
                        </LightTooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </FiltersListItemHolder>
              </FiltersList>
            );
          })}
          <ClearFilters
            onClick={() => {
              Event('FD_Catalog', 'Click_Clear_Filters', getFacetFilters());
              clearAllFacetFilters();
            }}
            className={facetFilters.length > 0 ? 'filters-applied' : ''}
          >
            Clear Filters
          </ClearFilters>
        </FiltersHolder>
      )}
    </Container>
  );
};

export default CatalogFilters;
