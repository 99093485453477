export const logoNameMap = new Map([
  ['BLOOM', 'logo-bloom.png'],
  ['BURBERRY', 'logo-burberry.png'],
  ['CARRERA', 'logo-carrera.png'],
  ['COACH', 'logo-coach.png'],
  ['COSTA', 'logo-costa.png'],
  ['DANNY GOKEY', 'logo-danny-gokey.png'],
  ['DOLCE & GABBANA', 'logo-dolce-gabbana.png'],
  ['EMPORIO ARMANI', 'logo-emporio-armani.png'],
  ['EVATIK', 'logo-evatik.png'],
  ['FILA', 'logo-fila.png'],
  ['FYSH', 'logo-fysh.png'],
  ['GARGOYLES', 'logo-gargoyles.png'],
  ['GIORGIO ARMANI', 'logo-giorgio-armani.png'],
  ['GUCCI', 'logo-gucci.png'],
  ['GUESS', 'logo-guess.png'],
  ['GUESS BY MARCIANO', 'logo-guess-marciano.png'],
  ['GWEN STEFANI GX', 'logo-gwen-stefani-gx.png'],
  ['HACKETT', 'logo-hackett.png'],
  ['HELIUM PARIS', 'logo-helium-paris.png'],
  ['JIMMY CHOO', 'logo-jimmy-choo.png'],
  ['KATE SPADE', 'logo-kate-spade.png'],
  ['KENZO', 'logo-kenzo.png'],
  ['KLIIK', 'logo-kliik.png'],
  ['KOALI', 'logo-koali.png'],
  ['L.A.M.B.', 'logo-lamb.png'],
  ['LIGHTEC', 'logo-lightec.png'],
  ['LULU GUINNESS', 'logo-lulu-guinness.png'],
  ['MICHAEL KORS', 'logo-michael-kors.png'],
  ['OAKLEY', 'logo-oakley.png'],
  ['OGA', 'logo-oga.png'],
  ['POLO', 'logo-polo.png'],
  ['PRADA', 'logo-prada.png'],
  ['PRADA LINEA ROSSA', 'logo-prada-linea-rossa.png'],
  ['PUMA', 'logo-puma.png'],
  ['RALPH', 'logo-ralph.png'],
  ['RAY BAN', 'logo-rayban.png'],
  ['RISERVA', 'logo-riserva.png'],
  ['SUPERFLEX', 'logo-superflex.png'],
  ['SWAROVSKI', 'logo-swarovski.png'],
  ['TED BAKER', 'logo-ted-baker.png'],
  ['TIFFANY', 'logo-tiffany.png'],
  ['TIMBERLAND', 'logo-timberland.png'],
  ['TITANFLEX', 'logo-titanflex.png'],
  ['TOM FORD', 'logo-tom-ford.png'],
  ['TORY BURCH', 'logo-tory-burch.png'],
  ['TURA', 'logo-tura.png'],
  ['VERSACE', 'logo-versace.png'],
  ['VISION SOURCE SMART', 'logo-vision-source-smart.png'],
  ['VOGUE', 'logo-vogue.png'],
]);
