import * as React from 'react';
import { Container, Image, InnerHero } from './hero.styled';
import classNames from 'classnames';
import { FlexRow } from '../app/app.styled';

const Hero = ({ classes, children, ...restProps }) => {
  return (
    <Container className={classNames('hero', classes)} {...restProps}>
      <InnerHero className={'layout__max-width'}>{children}</InnerHero>
    </Container>
  );
};

Hero.Image = ({ children, classes, ...restProps }) => {
  if (restProps.hasOwnProperty('full-height')) {
    classes += ' hero__full__height';
  }
  return (
    <Image className={classNames('hero__image', classes)}>{children}</Image>
  );
};

Hero.Headline = ({ children, alt, classes, ...restProps }) => {
  return <h1>{children}</h1>;
};

Hero.SubHeadline = ({ children, alt, classes, ...restProps }) => {
  return <h2>{children}</h2>;
};

Hero.Buttons = ({ children, alt, classes, ...restProps }) => {
  return (
    <FlexRow className={classNames('hero__buttons', classes)}>
      {children}
    </FlexRow>
  );
};

export default Hero;
