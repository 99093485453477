import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
import HeroBg from './hero-bg.jpg';
import HeroImageLarge from './hero@large.jpg';

export const Container = styled.div`
  position: relative;
  padding-bottom: 50px;
  background-image: url(${HeroBg});
  h1,
  h2 {
    position: relative;
    z-index: 2;
    font-size: var(--app-font-size-xlarge);
    padding-left: 50px;
    max-width: 600px;
  }
  h1 {
    padding-top: 7%;
  }
  h2 {
    font-size: var(--app-font-size-large);
  }
  .shop_by {
    font-weight: 500;
    margin-left: 50px;
  }
  .shop_by {
    margin-left: 50px;
  }
  .hero__buttons {
    padding-left: 50px;
  }
  .hero__bg__blue {
    background-color: var(--app-color-bg-hero);
    padding-bottom: 100px;
    padding-top: 20px;
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      background-color: transparent;
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    padding-bottom: 0px;
    h1,
    h2 {
      text-align: left;
      padding-left: 0px;
      max-width: 450px;
      padding-left: var(--app-gutter);
      padding-right: var(--app-gutter);
    }
    h1 {
      font-size: var(--app-font-size-xlarge);
      max-width: 100%;
      margin-bottom: 10px;
    }
    h2 {
      font-size: var(--app-font-size-larger);
      margin-top: 0;
    }
    .shop_by {
      margin-left: 32px;
    }
    .shop_by {
      margin-left: 32px;
    }
    .hero__buttons {
      padding-left: 0px;
      margin: 25px 0;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .m__r__medium {
        margin-right: 10px;
        margin: 0;
        width: calc(33% - 10px);
        div {
          padding: 20px 25px;
        }
        :last-of-type {
          margin-right: 0;
        }
      }
      button {
        font-size: 1rem;
        margin-bottom: 12px;
        padding: 20px;
        width: 100%;
      }
    }
  }
`;
export const InnerHero = styled.div`
  width: 90vw;
  margin: auto;
  margin-top: 65px;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    margin-top: 0;
  }
`;
export const Image = styled.div`
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 573px;
  background-color: var(--app-color-bg-bright);
  background-image: url(${HeroImageLarge});
  background-position: center bottom 60px;

  &.hero__full__height {
    background-position: center bottom 0px;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: transparent;
    background-image: none;
    margin: 0 auto;
    min-height: 450px;
    /* max-height: 600px; */
    transition: background-blend-mode 1s;
  }
`;
