import * as React from 'react';
import { useCatalog } from '../../provider/catalog-context';
import { SkeletonCards } from '../../components';
import { useLensSelector } from '../../provider/lens-context';

const CatalogCards = ({ handleOpen, colorUrlMap }) => {
  const {
    catalog,
    updateCatalogColorSelect,
    pageNumber,
    pageSize,
    setPageSize,
    resultsSize,
    loadNextPage,
  } = useCatalog();

  const { handleLensOpen } = useLensSelector();

  return (
    <SkeletonCards
      handleOpenFrameSelectorModal={handleOpen}
      handleOpenLensSelectorModal={handleLensOpen}
      list={catalog}
      handleColorSelect={updateCatalogColorSelect}
      pageNumber={pageNumber}
      pageSize={pageSize}
      setPageSize={setPageSize}
      resultsSize={resultsSize}
      loadNextPage={loadNextPage}
      showResultsBar={true}
      colorUrlMap={colorUrlMap}
      noCardsText='Sorry! This rarely happens, but there are no frames that fit the filters you have chosen. Please adjust your filters to continue browsing.'
    />
  );
};

export default CatalogCards;
