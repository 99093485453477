import React, { useContext, useEffect, useState, createContext } from 'react';
import { Event } from '../utils';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';
import { add } from 'date-fns';

import lensDescJson from '../components/lens-selector/lens-description-texts.json';

const LensContext = createContext();
const useLensSelector = () => useContext(LensContext);
const LensContextProvider = (props) => {
  const [lensQandA, setLensQandA] = useState(
    !isBrowser() ? [] : JSON.parse(localStorage.getItem('lensQandA')) || []
  );
  const [lensIndex, setLensIndex] = useState(
    !isBrowser() ? 0 : JSON.parse(localStorage.getItem('lensIndex')) || 0
  );
  const [lensOpen, setLensOpen] = useState(false);
  const [lensRecs, setLensRecs] = useState(
    !isBrowser() ? [] : JSON.parse(localStorage.getItem('lensRecs')) || []
  );
  const [openLensOption, setOpenLensOption] = React.useState(false);
  const [lensRecNames, setLensRecNames] = useState([]);
  const [lensRecDescs, setLensRecDescs] = useState([]);
  const [lensDescDict, setLensDescDict] = React.useState({});
  const lastLensSelectorIndex = 6;

  const addOrRemoveQuestionAnswer = (question, answer) => {
    let questionIndex = lensQandA.findIndex(
      (QandA) => QandA.Question === question
    );
    if (questionIndex > -1) {
      setLensQandA(
        lensQandA
          .map((QandA) => {
            if (QandA.Question === question) {
              let answerIndex = QandA.Answers.findIndex((a) => a === answer);
              if (answerIndex > -1) {
                QandA.Answers.splice(answerIndex, 1);
              } else {
                QandA.Answers.push(answer);
              }
            }
            return QandA;
          })
          .filter((QandA) => QandA.Answers.length > 0)
      );
    } else {
      setLensQandA([
        ...lensQandA,
        {
          Question: question,
          Answers: [answer],
        },
      ]);
    }
  };

  const clearOneQuestion = (question) => {
    setLensQandA(lensQandA.filter((QandA) => !(QandA.Question === question)));
  };

  const handleLensStartOver = () => {
    setLensQandA([]);
    setLensIndex(0);
  };

  const checkIfLastScreen = () => {
    if (lensIndex > lastLensSelectorIndex) {
      handleLensClose();
      setLensIndex(lastLensSelectorIndex - 1);
      return;
    }
  };

  const handleLensButtonClick = (question, answer) => {
    Event(
      'FD_Lens_Selector',
      getActionValue(lensIndex),
      `question:{${question}},answer:{${answer}}`
    );
    addOrRemoveQuestionAnswer(question, answer);
    if (question == 'Age' && answer == '45+ Years Old') {
      setLensIndex(lensIndex + 2);
    } else {
      setLensIndex(lensIndex + 1);
    }

    checkIfLastScreen();
  };

  const getActionValue = (lensIndex) => {
    switch (lensIndex) {
      case 0:
        return 'Click_Lens_Distance_Page_1';
      case 1:
        return 'Click_Lens_Age_Page_2';
      case 2:
        return 'Click_Lens_Screen_Page_3';
      case 3:
        return 'Click_Lens_Exposure_Page_4';
      case 4:
        return 'Click_Lens_Challenges_Page_5';
      case 5:
        return 'Click_Lens_Activity_Page_6';
      case 6:
        return 'Click_Lens_Summary_Page_7';
      default:
        return '';
    }
  };

  const handleLensSingleSelect = (question, answer) => {
    Event(
      'FD_Lens_Selector',
      getActionValue(lensIndex),
      `question:{${question}},answer:{${answer}}`
    );
    addOrRemoveQuestionAnswer(question, answer);
  };

  const handleLensNextButton = () => {
    Event('FD_Lens_Selector', getActionValue(lensIndex), `next_question`);
    setLensIndex(lensIndex + 1);
    checkIfLastScreen();
  };

  const handleLensBackButton = () => {
    clearOneQuestion(
      lensIndex === 1
        ? 'Distance'
        : lensIndex === 2
        ? 'Age'
        : lensIndex === 3
        ? 'Screen'
        : lensIndex === 4
        ? 'Exposure'
        : lensIndex === 5
        ? 'Challenges'
        : 'Activity'
    );
    if (lensIndex === 6) {
      setLensRecs([]);
    }
    setLensIndex(lensIndex - 1);
  };

  const handleLensOpen = () => {
    setLensOpen(true);
  };

  const handleLensClose = () => {
    if (lensIndex === lastLensSelectorIndex) {
      Event(
        'FD_Lens_Selector',
        getActionValue(lensIndex),
        `question:{},answer:{}`
      );
    }
    setLensOpen(false);
  };

  const handleOpenLensOption = () => {
    setOpenLensOption(true);
  };

  const handleCloseLensOption = () => {
    setOpenLensOption(false);
  };

  const lensQandACheck = (question, answer) => {
    var QandA = lensQandA.find((q) => q.Question === question);
    if (QandA) {
      return QandA.Answers.includes(answer);
    }
    return false;
  };

  const lensQCheck = (question) => {
    var QandA = lensQandA.find((q) => q.Question === question);
    if (QandA) {
      return QandA.Answers.length > 0;
    }
    return false;
  };

  useEffect(() => {
    var tempDescDict = {};
    lensDescJson.Options.forEach((descObj) => {
      tempDescDict[descObj.Key] = descObj;
    });
    setLensDescDict(tempDescDict);
  }, []);

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem('lensQandA', JSON.stringify(lensQandA));
    }

    var tempRecList = [];
    if (
      (lensQandACheck('Age', '30 - 44 Years Old') ||
        lensQandACheck('Age', '18 - 29 Years Old') ||
        lensQandACheck('Age', 'For My Children')) &&
      lensQandACheck('Screen', '0 to 3 Hours Per Day')
    ) {
      tempRecList.push('Single Vision');
    }
    if (lensQandACheck('Age', '45+ Years Old')) {
      tempRecList.push('Varilux');
    }
    if (
      (lensQandACheck('Age', '30 - 44 Years Old') ||
        lensQandACheck('Age', '18 - 29 Years Old')) &&
      (lensQandACheck('Screen', '4 to 6 Hours Per Day') ||
        lensQandACheck('Screen', '7 to 9 Hours Per Day') ||
        lensQandACheck('Screen', '10+ Hours Per Day'))
    ) {
      tempRecList.push('Eyezen+');
    }
    if (
      lensQandACheck('Age', 'For My Children') &&
      (lensQandACheck('Screen', '4 to 6 Hours Per Day') ||
        lensQandACheck('Screen', '7 to 9 Hours Per Day') ||
        lensQandACheck('Screen', '10+ Hours Per Day'))
    ) {
      tempRecList.push('Eyezen+0');
    }
    if (
      (lensQandACheck('Age', '45+ Years Old') ||
        lensQandACheck('Age', '30 - 44 Years Old') ||
        lensQandACheck('Age', '18 - 29 Years Old')) &&
      (lensQCheck('Challenges') || lensQCheck('Activity'))
    ) {
      tempRecList.push('Crizal');
    }
    if (
      lensQandACheck('Age', 'For My Children') &&
      (lensQCheck('Challenges') || lensQCheck('Activity'))
    ) {
      tempRecList.push('Crizal for Kids');
    }
    if (
      lensQandACheck('Exposure', 'Mostly Indoors') ||
      lensQandACheck('Exposure', 'Both Indoors and Outdoors') ||
      lensQandACheck('Exposure', 'Mostly Outdoors')
    ) {
      tempRecList.push('Transitions');
    }
    if (lensQandACheck('Exposure', 'All Day Outdoors')) {
      tempRecList.push('Xperio UV');
      var index = tempRecList.findIndex(
        (rec) => rec === 'Crizal' || rec === 'Crizal for Kids'
      );
      if (index > 0) {
        tempRecList.splice(index, 1);
      }
    }

    setLensRecs(tempRecList);
  }, [lensQandA]);

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem('lensIndex', JSON.stringify(lensIndex));
    }
  }, [lensIndex]);

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem('lensRecs', JSON.stringify(lensRecs));
    }
    if (lensDescDict) {
      var tempNames = [];
      var tempDescs = [];
      for (var i = 0; i < lensRecs.length; i++) {
        if (lensDescDict[lensRecs[i]]) {
          tempNames.push(lensDescDict[lensRecs[i]].Name);
          tempDescs.push(lensDescDict[lensRecs[i]].Description);
        }
      }
      setLensRecNames(tempNames);
      setLensRecDescs(tempDescs);
    }
  }, [lensRecs]);

  const value = {
    lensIndex,
    setLensIndex,
    lensOpen,
    setLensOpen,
    handleLensButtonClick,
    handleLensSingleSelect,
    handleLensBackButton,
    handleLensNextButton,
    handleLensOpen,
    handleLensClose,
    lensRecs,
    lensQandACheck,
    handleLensStartOver,
    openLensOption,
    handleOpenLensOption,
    handleCloseLensOption,
    lensDescDict,
    lensRecNames,
    lensRecDescs,
  };
  return (
    <LensContext.Provider value={value}>{props.children}</LensContext.Provider>
  );
};

export { LensContextProvider, useLensSelector };
