import * as React from 'react';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { isBrowser, initGA, Event } from '../../utils';
import { environment } from '../../environments/environments';

import {
  Seo,
  Product,
  ProductCard,
  ProductDetailsCard,
} from '../../components/';
import { FlexRow } from '../../components/app/app.styled';
import ProductSuggestion from '../../components/product-suggestions/product-suggestions';
import { useRelatedProducts } from '../../provider/related-products-context';
import { usePopularProducts } from '../../provider/popular-products-context';
import { useVto } from '../../provider/vto-context';

const ProductRoute = () => {
  const {
    currentPage,
    setCurrentPage,
    paginatedArray,
    setProductsPerFrame
  } = useRelatedProducts();
  const {
    popularCurrentPage,
    setPopularCurrentPage,
    popularPaginatedArray,
    setPopularProductsPerFrame,
  } = usePopularProducts();
  const {
    vtoAppInstance,
    closeVto,
    setIsVtoModalOpen
  } = useVto();

  const title = 'More Frames Like This One';
  const popularTitle = 'Popular In Your Area';

  const onBackButtonEvent = (e) => {
    let scrollPosition = null;

    if (isBrowser()) {
      scrollPosition = localStorage.getItem(
        'scrollPosition',
        window.pageYOffset
      );
    }

    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      localStorage.removeItem('scrollPosition');
    }

    if (!!vtoAppInstance) {
      closeVto();
      setTimeout(() => setIsVtoModalOpen(false), 150);
    }
  };

  useEffect(() => {
    initGA();
    Event('FD_Catalog', 'Virtual_Page_Views', '');
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  return (
    <>
      <Seo title='Frame Dream Product' />

      <Product.ProductHero>
        <FlexRow
          className={
            'm__l__medium m__r__medium layout__center layout__max-width'
          }
        >
          <ProductCard handleNavBack={onBackButtonEvent} />
        </FlexRow>
      </Product.ProductHero>

      {paginatedArray !== undefined && paginatedArray[currentPage]?.length > 0 && (
        <FlexRow
          className={
            'm__l__medium m__r__medium layout__center layout__max-width'
          }
        >
          <ProductSuggestion
            title={title}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginatedArray={paginatedArray}
            setProductsPerFrame={setProductsPerFrame}
          ></ProductSuggestion>
        </FlexRow>
      )}

      <FlexRow
        className={'m__l__medium m__r__medium layout__center layout__max-width'}
      >
        <ProductDetailsCard />
      </FlexRow>

      {popularPaginatedArray !== undefined &&
        popularPaginatedArray[popularCurrentPage]?.length > 0 && (
          <FlexRow
            className={
              'm__l__medium m__r__medium layout__center layout__max-width'
            }
          >
            <ProductSuggestion
              title={popularTitle}
              currentPage={popularCurrentPage}
              setCurrentPage={setPopularCurrentPage}
              paginatedArray={popularPaginatedArray}
              setProductsPerFrame={setPopularProductsPerFrame}
              origin={'product'}
              isProductPage={true}
              isNavB={false}
            ></ProductSuggestion>
          </FlexRow>
        )}
    </>
  );
};

export default ProductRoute;
