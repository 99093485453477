import React from 'react';

const ComposerContext = ({ components = [], children }) => {
  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
};

export { ComposerContext };
