import * as React from 'react';
import BrowseOption from './browse-option';
import FDModal from '../modal/modal';
import { useFrameSelector } from '../../provider/frame-selector-context';
import { isBrowser } from '../../utils';
import { navigate } from 'gatsby';

const BrowseOptionModal = () => {
  const [openBrosweOption, setBrosweOptionOpen] = React.useState(false);
  const { handleOpen } = useFrameSelector();

  const handleBrosweOptionClose = () => {
    navigate('/');
    setBrosweOptionOpen(false);
  };

  const handleSelectModal = () => {
    handleBrosweOptionClose();
    handleOpen();
  };

  const queryParams = isBrowser()
    ? window.location.search.match(/(\?|&)frame-select\=([^&]*)/)
    : null;

  React.useEffect(() => {
    if (queryParams) {
      let showFrameSelect = queryParams[2];
      if (showFrameSelect === 'true') {
        setBrosweOptionOpen(true);
      }
    }
  }, []);

  return (
    <>
      <FDModal
        open={openBrosweOption}
        body={
          <BrowseOption
            handleClose={handleBrosweOptionClose}
            handleOpen={handleSelectModal}
          />
        }
        handleClose={handleBrosweOptionClose}
      />
    </>
  );
};

export default BrowseOptionModal;
