import * as React from 'react';
import {
  SelectionContainer,
  ResultsContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
  ChoiceHorizontal,
  SelectAllContainer,
  Text,
  SubText,
  SubTitle,
} from './lens-selector.styled';
import { useLensSelector } from '../../provider/lens-context';

const LensPageSummary = ({
  handleButtonClick,
  handleClose,
  handleStartOver,
  lensRecs,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  const { lensDescDict } = useLensSelector();

  return (
    <>
      <hr width='80%' />
      {lensDescDict && (
        <ResultsContainer>
          {lensRecs.map((rec, i) => {
            return (
              lensDescDict[rec] && (
                <>
                  <Text key={i} style={{ textAlign: 'left' }}>
                    {lensDescDict[rec].Name}
                  </Text>
                  <SubText key={i + '_desc'} style={{ textAlign: 'left' }}>
                    {lensDescDict[rec].Description}
                  </SubText>
                </>
              )
            );
          })}
        </ResultsContainer>
      )}
      <SelectAllContainer>
        <ButtonHorizontal
          style={{ margin: '10px' }}
          className={'btn__custom active'}
          onClick={(e) => handleClose()}
        >
          Continue to Shop
        </ButtonHorizontal>
      </SelectAllContainer>
    </>
  );
};

export default LensPageSummary;
