import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Box = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  align-self: center;
  text-align: center;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-top: 10px;
  }
`;

export const SubHeadline = styled.h2`
  letter-spacing: 1px;
  font-weight: var(--app-font-weight-bold);
  width: 85%;
  margin: auto;
`;

export const Description = styled.h4`
  letter-spacing: 1px;
  font-weight: var(--app-font-weight-bold);
  margin-block-start: 0;
`;

export const Title = styled.h1`
  letter-spacing: 1px;
  font-weight: var(--app-font-weight-bold);
  margin-block-end: 0;
`;

export const Headline = styled.h1`
  letter-spacing: 1px;
  font-weight: var(--app-font-weight-bold);
  margin-block-end: 0;
  color: var(--app-color-primary);

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-bottom: -20px;
  }
`;

export const ContentHeader = styled.div`
  margin: 0 auto;
  width: 65%;
  text-align: center;
  min-height: 70px;
  height: auto;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 90%;
    top: 10%;
    right: 50%;
    background: var(--app-color-bg-dark-shadow);
    display: block;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin: 0;
    padding-top: 0px;
    height: fill-available;
    &:before {
      content: none;
    }
  }
`;

export const Column = styled.div`
  align-items: center;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const ContentImage = styled.img`
  object-fit: contain;
  padding-bottom: 20px;
  max-width: 100%;
  height: 30vh;
  max-height: 200px;
  @media only screen and (max-height: 600px) and (min-width: ${SCREEN_SIZE.sm}) {
    display: none;
  }
`;

export const ContentImageSmall = styled.img`
  object-fit: contain;
  padding-bottom: 20px;
  max-width: 100%;
  height: 30vh;
  max-height: 200px;

  @media only screen and (max-height: 600px) and (min-width: ${SCREEN_SIZE.sm}) {
    display: none;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    max-width: 80%;
    margin: -50px -50px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    padding: 0;
    padding-top: 20px;
    justify-content: center;
  }
`;

export const GridContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  & > div {
    max-width: 310px;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
`;
