import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  height: 260px;
  &.no-underline{
    border-bottom: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    height: auto;
  }
`;

export const InnerContainer = styled.div`
  position: relative;
  padding: 0px 30px;
  justify-content: center;
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: var(--app-font-size-large);
  color: var(--app-color-primary);
  width: 100%;
  text-align: left;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
  }
`;

export const Card = styled.div`
  cursor: pointer;
  max-width: 300px;
  width: auto;
  max-height: 230px;
  padding: 0 20px;
  height: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
  }
`;
export const ImageRow = styled.div`
  margin: -40px 0px -40px 0px;
  height: 120px;
  width: 200px;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
  }
`;
export const CardImage = styled.div``;

export const CardTitle = styled.div`
  font-weight: var(--app-font-weight-bolder);
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
  }
`;

export const CardColors = styled.div`
  margin-top: -30px;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
  }
`;
