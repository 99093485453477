import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 50px;

  button.active {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);

    :hover {
      color: var(--app-color-primary);
      background: var(--app-color-bg-bright);
      border: 1px solid var(--app-color-primary);
    }
  }
`;

export const Text = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 24px;
  font-weight: 500;
`;

export const Question = styled.h1`
  width: 60%;
  font-weight: 600;
  align-self: center;
  text-align: center;
  color: var(--app-color-primary);
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-top: 60px;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    width: 80%;
  }
`;

export const Image = styled.img`
  object-fit: contain;
  height: 250px;
  align-self: center;
  text-align: center;
  max-width: 350px;
  height: auto;
  min-heigth: 300px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    max-height: fit-content;
    width: auto;
    max-width: 250px;
    max-height: 150px;
    min-width: 180px;
    margin: -10px 0 !important;
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  align-self: center;
  justify-content: center;

  button.active {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);

    :hover {
      color: var(--app-color-primary);
      background: var(--app-color-bg-bright);
      border: 1px solid var(--app-color-primary);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    flex-direction: column;
    height: 100%;
    display: flex;
    justify-content: revert;
  }
`;

export const Choice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    padding-bottom: 0px;
    min-height: 200px;
  }
`;

export const ButtonHorizontal = styled.button`
  margin: auto;
  width: fit-content;
  min-width: 240px;
  padding: 10px 10px;
  border: none;
  font-size: var(--app-font-size);
  font-weight: 900;
  color: 'black';
  background: var(--app-color-bg-bright);
  border: 1px solid var(--app-color-primary);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  z-index: 2;
  line-height: 20px;

  :hover {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    width: 100%;
    padding: 12px 35px;
    z-index: 3;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    height: 45px;
  }
`;
