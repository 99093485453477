import styled from 'styled-components';
import { Link } from 'gatsby';
import { SCREEN_SIZE } from '../app/app.styled';

export const Nav = styled.nav`
  width: var(--app-menu-width);
  height: 100vh;
  position: fixed;
  background: var(--app-color-bg-bright);
  display: flex;
  flex-direction: column;
  align-items: center;
  top: var(--app-menu-width);
  border-right: var(--app-border-light);
  transition: all var(--app-speed) ease;
  overflow: hidden;
  z-index: 5;
  span {
    align-items: left;
  }
  &:hover {
    width: 11rem;
    align-items: left;
    a {
      align-items: left;
    }
    span {
      display: block;
      width: 100%;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    top: initial;
    bottom: 0;
    width: 100vw;
    height: var(--app-menu-width);
    flex-direction: row;
    border-top: var(--app-border-light);
    padding-top: 0;

    &:hover {
      context: '';
      width: 100vw;

      span {
        display: none;
      }
    }
  } ;
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding-left: calc(var(--app-menu-width) - 2.5rem);
  text-transform: capitalize;
  color: var(--app-color-dark);
  transition: all var(--app-speed) ease;
  opacity: 1;

  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    .menu__shortcut__txt {
      display: none;
    }
  } ;
`;

export const Img = styled.div`
  svg{
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-left: 2px;
  }
`;
