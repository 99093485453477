import * as React from 'react';
import {
  ButtonHorizontal,
  Image,
  ImageGrid,
  ChoiceHorizontal,
  Choice,
} from './frame-selector.styled';
import Male from '../../images/frame-select/gender_male.png';
import Female from '../../images/frame-select/gender_female.png';
import Kid from '../../images/frame-select/gender_youth.png';

const GuidedScreen1 = ({
  handleButtonClick,
  classes,
  children,
  ...restProps
}) => {
  const facetType = 'Gender';

  return (
    <>
      <ImageGrid>
        <Choice>
          <Image
            margin={'-20px -30px'}
            src={Male}
            onClick={() => handleButtonClick(facetType, 'Men')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Men'
          >
            Mens Styles
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            margin={'-20px -30px'}
            src={Female}
            onClick={() => handleButtonClick(facetType, 'Women')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Women'
          >
            Womens Styles
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            margin={'-20px -30px'}
            src={Kid}
            onClick={() => handleButtonClick(facetType, 'Kids')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Kids'
          >
            Kids Styles
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </>
  );
};

export default GuidedScreen1;
