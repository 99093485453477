import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { SCREEN_SIZE } from '../app/app.styled';

export const Sheet = styled.div`
  z-index: 10;
  margin: 6vh auto;
  display: flex;
  background: white;
  padding: 40px;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: fit-content;
  min-height: 290px;
  min-width: 600px;
  overflow: auto;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    max-height: fit-content;
    max-width: var(--app-max-width);
    height: 100%;
    width: 100% !important;
    margin: 0;
    padding: 20px;
    min-height: 300px;
    min-width: 300px;
  }
`;

export const NavigationBar = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

//MuiBackdrop-root
export const StyledModal = styled(Modal)`
  .MuiBackdrop-root {
    background: rgba(255, 255, 255, 0.3);
  }
`;
