import * as React from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Container,
  FavoriteRow,
  FavoriteIcon,
  TitleDiv,
  ClickDiv,
  Title,
  CardRow,
  ColorsGroup,
  ImageRow,
  Image,
  ColorHighlight,
  ColorSelect,
} from './card.styled';
import { Button } from '../app/app.styled';
import { colorNameMap } from './color-map';

const Card = ({ favClass, className, classes, children, ...restProps }) => {
  return (
    <Container
      className={classNames(`card ${favClass}`, classes)}
      {...restProps}
    >
      {children}
    </Container>
  );
};

Card.Favorite = ({ tooltip, children, classes, ...restProps }) => {
  return (
    <FavoriteRow className={classNames('card__favrow', classes)} {...restProps}>
      <Tooltip title={tooltip} placement='top'>
        <FavoriteIcon>{children}</FavoriteIcon>
      </Tooltip>
    </FavoriteRow>
  );
};

Card.ClickDiv = ({ classes, ...restProps }) => {
  return (
    <CardRow>
      <ClickDiv
        className={classNames('card__clickdiv', classes)}
        {...restProps}
      />
    </CardRow>
  );
};

Card.Image = ({ frame, alt, classes, ...restProps }) => {
  return (
    <ImageRow className={classNames('card__imagerow', classes)} {...restProps}>
      {/* <Image
        src={frame.PreviewImageURL}
        alt={alt}
        className={classNames('card__image', classes)}
      /> */}
      <div
        style={{
          backgroundImage: `url("${frame.PreviewImageURL}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '80%',
          width: '100%',
        }}
        alt={alt}
        className={classNames('card__image', classes)}
      />
    </ImageRow>
  );
};

Card.Title = ({ classes, children, ...restProps }) => {
  return (
    <TitleDiv className={classNames('card__title', classes)}>
      <Title {...restProps}>{children}</Title>
    </TitleDiv>
  );
};

Card.ColorSelectRow = ({ classes, children, ...restProps }) => {
  return (
    <CardRow
      style={{ height: '30px' }}
      className={classNames('card__row', classes)}
      {...restProps}
    >
      <ColorsGroup>{children}</ColorsGroup>
    </CardRow>
  );
};

Card.ColorHighlight = ({ classes, children, ...restProps }) => {
  return (
    <ColorHighlight
      className={classNames('card__highlight', classes)}
      {...restProps}
    />
  );
};

Card.ColorSelect = ({
  colorUrlMap,
  swatch,
  frame,
  modelNumber,
  selected,
  classes,
  children,
  ...restProps
}) => {
  return (
    <Tooltip title={swatch} placement='top'>
      <ColorSelect
        onClick={() => restProps.handelColorSelect({ modelNumber, frame })}
        style={{
          backgroundImage: `url(${colorUrlMap.get(colorNameMap.get(swatch))})`,
        }}
        className={classNames('card__swatch', classes)}
        {...restProps}
      />
    </Tooltip>
  );
};

Card.Button = ({ model, classes, children, ...restProps }) => {
  return (
    <CardRow className={classNames('card__row', classes)} {...restProps}>
      <Button className={classNames('card__button', classes)} {...restProps}>
        {children}
      </Button>
    </CardRow>
  );
};

export default Card;
