import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { environment } from '../../environments/environments';

export const SCREEN_SIZE = {
  xs: '600px',
  sm: '900px',
  md: '1300px',
  md2: '1500px',
};

export const Button = styled.button`
  margin: auto;
  margin-top: 25px;
  padding: 10px 40px;
  border: none;
  font-size: var(--app-font-size-medium);
  font-weight: 900;
  color: var(--app-color-primary);
  background: var(--app-color-bg-bright);
  border: 1px solid var(--app-color-primary);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  :hover {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
  }
`;

export const FlexRow = styled.div`
  display: flex;
  &.full__width {
    width: 100%;
  }
  .center__width {
    width: 50vw;
    text-align: right;
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      width: 100%;
    }
  }
`;
export const ContainerFullWidth = styled.div`
  width: 100%;
  margin-top: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    height: auto;
  }
`;
export const AppStyle = createGlobalStyle`
  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    vertical-align: text-top;
  }

  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  p {
    letter-spacing: .06rem;
  }
  strong {
    letter-spacing: .06rem;
  }

  h1 {
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      font-size: var(--app-font-size-large);
    }
  }

  h2 {    
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      font-size: var(--app-font-size-medium);
    }
  }

  h4 {    
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      font-size: var(--app-font-size);
    }
  }

  .cursor {
    cursor: pointer;
  }
  .gutters {
    
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      margin-right: var(--app-gutter);
      margin-left: var(--app-gutter);
    }
  }
  .m {
    &__l {
      &__medium {
        margin-left: 20px;
      }
    }
    &__b {
      &__medium {
        margin-bottom: 20px;
      }
    }
    &__r {
      &__medium {
        margin-right: 20px;
      }
    }
    &__t {
      &__medium {
        margin-top: 30px;
      }
      &__large {
        margin-top: 80px;
      }
    }
  }

  .layout {
    &__center {
      margin-left: auto;
      margin-right: auto;
    }
    &__right {
      margin-left: auto;
    }
    &__max-width {
      max-width: 1668px;
      @media only screen and (max-width: 900px) {
        max-width: 100vw;
      }

      &-inner {
        max-width: var(--app-max-width);
      }
    }
  }
  .txt {
    &__center {
      text-align:center;
    }
    &__caps {
      text-transform:uppercase;
    }
    &__bold {
      font-weight: 600;
    }
  }
  .color {
    &__primary {
      color:var(--app-color-primary);
    }
  }
  .font {
    &__size {
      &__small {
        font-size:var(----app-font-size-small);
      }
    }
  }

  .noselect, button {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  input[type=submit] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      width: 100%;
    }
  }

  a {
    &.btn__disabled {
      background-color:var(--app-color-bg-light-shade) !important;
      border:1px solid var(--app-color-bg-light-shade) !important;
      color:var(--app-color-bg-dark-shadow) !important;
    }
  }

  .btn__filter {
    font-size: var(--app-font-size-large);
    border: 2px solid var(--app-color-primary);
    border-radius: 50px;
    padding: 8px 11px 3px 8px;
    height: 40px;
    width: 40px;
    margin: 3px 0 0;
    img {
      position: relative;
      top: 3px;
    }
  }
  .btn__custom {
    height:45px;
    &.btn__larger {
      height:45px !important;
    }
  }
  .border__bottom {
    &__mobile {
      @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
        border-bottom: 1px solid var(--app-color-bg-light-shadow);
      }
    }
  }

  .md-up-hide {
    display: none !important;
  }
  @media only screen and (max-width: 900px) {
    .md-down-hide {
      display: none !important;
    }
    .md-up-hide {
      display: initial !important;
    }
  }

  *:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  :root {
    --app-border-radius: 5px;
    --app-font-size-xlarge: 50px;
    --app-font-size-larger: 25px;
    --app-font-size-large: 20px;
    --app-font-size-xlarge: 40px;
    --app-font-size-medium: 18px;
    --app-font-size: 16px;
    --app-font-size-small: 10px;

    --app-font-weight-normal: 300;
    --app-font-weight-bold: 500;
    --app-font-weight-bolder: 600;

    --app-gutter: 2rem; 

    --app-speed: 240ms;
    --app-max-width:1200px;
    --app-color-primary: ${environment.themePrimaryColor};
    --app-color-danger: #CE0303;
    --app-color-secondary: ${environment.themeSecondaryColor};
    --app-filter-primary: invert(25%) sepia(94%) saturate(1785%)
      hue-rotate(244deg) brightness(105%) contrast(95%);

    --app-color-primary-border: #cfa500;
    --app-color-link: #4366c2;

    --app-color-dark: #2b2c2b;
    --app-color-bg-hero: #c8d9e3;
    --app-color-bg-medium: #f7f7f7;
    --app-color-bg-bright: #ffffff;
    --app-color-bg-light: #f1f3f4;
    --app-color-bg-light-shade: #f7f7f9;
    --app-color-bg-light-shadow: #dbdfe6;
    --app-color-bg-dark-shadow: #aeaeae;
    --app-color-bg-dark: #232323;
    --app-border-light: 1px solid var(--app-color-bg-light-shadow);
    --app-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    --app-menu-width: 4rem;

    --app-breakpoint-sm: 600px;

    font-size: var(--app-font-size);
    font-family: var(--app-font-family);
  }

`;


export const FrameSelectorText = styled.div`
  margin: 40px 0;
  padding: 0 32px;
  @media screen and (min-width: ${SCREEN_SIZE.sm}) {
    padding: 0 50px;
  }
`;

export const ActionText = styled.a`
  color: var(--app-color-link);
  font-weight: var(--app-font-weight-bolder);
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid var(--app-color-primary);
    color: var(--app-color-primary);
  }
`;
