import * as React from 'react';
import LensOption from './lens-option';
import FDModal from '../modal/modal';
import { useLensSelector } from '../../provider/lens-context';

const LensOptionModal = () => {
  const { handleLensOpen, openLensOption, handleCloseLensOption } =
    useLensSelector();

  return (
    <>
      <FDModal
        open={openLensOption}
        tIndex={0}
        body={
          <LensOption
            handleButtonClick={() => {
              handleCloseLensOption();
              handleLensOpen();
            }}
            handleClose={handleCloseLensOption}
          />
        }
        handleClose={handleCloseLensOption}
      />
    </>
  );
};

export default LensOptionModal;
