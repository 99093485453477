import * as React from 'react';
import classNames from 'classnames';
import {
  Image,
  ImageContainer,
  ProductContainer,
  PreviewContainer,
  VtoContainer,
  InfoContainer,
  VariationContainer,
  Container,
  InnerHero,
  Col,
  Row,
  Title,
  Text,
  Label,
  Card,
  Icon,
  Separator,
  Highlights,
  Button,
  TryOnButton
} from './product.styled';
import TryOnIcon from '../../images/vto-try-on-icon.svg';

const Product = ({ classes, children, ...restProps }) => {
  return (
    <ProductContainer
      className={classNames('product__container', classes)}
      {...restProps}
    >
      {children}
    </ProductContainer>
  );
};

Product.ProductHero = ({ classes, children, ...restProps }) => {
  return (
    <Container className={classNames('hero', classes)} {...restProps}>
      <InnerHero className={'layout__max-width'}>{children}</InnerHero>
    </Container>
  );
};

Product.Col = ({ classes, children, ...restProps }) => {
  return (
    <Col
      className={classNames('product__container-col', classes)}
      {...restProps}
    >
      {children}
    </Col>
  );
};

Product.Row = ({ classes, children, ...restProps }) => {
  return (
    <Row
      className={classNames('product__container-row', classes)}
      {...restProps}
    >
      {children}
    </Row>
  );
};

Product.Vto = ({ frame, product, classes, ...restProps }) => {
  return (
    <VtoContainer className={classNames('vto__container', classes)} frame={frame} product={product} {...restProps} >
      <div className='vto-container'></div>
    </VtoContainer>
  );
};

Product.Preview = ({ frame, product, src, classes, children, isVtoModalOpen, onTryOnClick, ...restProps }) => {
  return (
    <PreviewContainer className={classNames('product__preview', classes)}>
      {
        frame.HasVto && !isVtoModalOpen &&
        <Product.VtoButton onClick={() => onTryOnClick()}>
          <image className="vto__try-on--icon" src={TryOnIcon} />
          Try on
        </Product.VtoButton>
      }
      <ImageContainer>
        <Image
          src={src}
          className={classNames('product__preview-image', classes)}
          {...restProps}
        />
        {children}
      </ImageContainer>
    </PreviewContainer>
  );
};

Product.Info = ({ classes, children, ...restProps }) => {
  return (
    <InfoContainer
      className={classNames('product__info', classes)}
      {...restProps}
    >
      {children}
    </InfoContainer>
  );
};

Product.Name = ({ classes, children, ...restProps }) => {
  return (
    <Title className={classNames('product__info-name', classes)} {...restProps}>
      {children}
    </Title>
  );
};

Product.Description = ({ classes, children, ...restProps }) => {
  return (
    <Text
      className={classNames('product__info-description', classes)}
      {...restProps}
    >
      {children}
    </Text>
  );
};

Product.Code = ({ classes, children, ...restProps }) => {
  return (
    <Text className={classNames('product__info-code', classes)} {...restProps}>
      {children}
    </Text>
  );
};

Product.Label = ({ classes, children, ...restProps }) => {
  return (
    <Label
      className={classNames('product__info-label', classes)}
      {...restProps}
    >
      {children}
    </Label>
  );
};

Product.Text = ({ classes, children, ...restProps }) => {
  return (
    <Text className={classNames('product__info-text', classes)} {...restProps}>
      {children}
    </Text>
  );
};

Product.Selector = ({ classes, children, ...restProps }) => {
  return (
    <VariationContainer
      className={classNames('product__info', classes)}
      {...restProps}
    >
      {children}
    </VariationContainer>
  );
};

Product.Highlight = ({ classes, children, ...restProps }) => {
  return (
    <Highlights
      className={classNames('product__highlight', classes)}
      {...restProps}
    >
      {children}
    </Highlights>
  );
};

Product.Variation = ({ src, classes, children, ...restProps }) => {
  return (
    <Card
      src={src}
      className={classNames('product__variation', classes)}
      {...restProps}
    />
  );
};

Product.Icon = ({ src, classes, children, ...restProps }) => {
  return (
    <Icon
      src={src}
      className={classNames('product__variation', classes)}
      {...restProps}
    />
  );
};

Product.Separator = ({ classes, children, ...restProps }) => {
  return (
    <Separator
      className={classNames('product__info-separator', classes)}
      {...restProps}
    >
      {children}
    </Separator>
  );
};

Product.Button = ({ icon, classes, children, ...restProps }) => {
  return (
    <Button className={classNames('product__button', classes)} {...restProps}>
      {icon}
      {children}
    </Button>
  );
};

Product.VtoButton = ({ children, ...restProps  }) => {
  return (
    <TryOnButton {...restProps}>
      {children}
    </TryOnButton>
  )
}

export default Product;
