import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';

const CatalogContext = createContext();
const useCatalog = () => useContext(CatalogContext);
const CatalogContextProvider = (props) => {
  const [catalog, setCatalog] = useState([]);
  const [facets, setFacets] = useState([]);
  const [brands, setBrands] = useState([]);
  const [facetFilters, setFacetFilters] = useState(
    !isBrowser() ? [] : JSON.parse(localStorage.getItem('facetFilters')) || []
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [resultsSize, setResultsSize] = useState(0);
  const [showModalFilters, setShowModalFilters] = useState(false);

  const toggleModalFilters = () => {
    setShowModalFilters((showModalFilters) => !showModalFilters);
  };
  const updateCatalogColorSelect = (frame, modelNumber) => {
    setCatalog(
      catalog.map((card) => {
        if (card.ModelNumber === modelNumber) {
          card.SelectedFrame.Color = frame.Color;
          card.SelectedFrame.PreviewImageURL = frame.PreviewImageURL;
          card.SelectedFrame.Upc = frame.Upc;
          card.SelectedFrame.Name = frame.Name;
        }
        return card;
      })
    );
  };

  const loadNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const addOrRemoveFacetFilter = (facetName, facetValue) => {
    if (facetName !== 'Face Shape') {
      let index = facetFilters.findIndex(
        (filter) => filter.FilterName === facetName
      );
      if (index > -1) {
        setFacetFilters(
          facetFilters
            .map((filter) => {
              if (filter.FilterName === facetName) {
                let facetIndex = filter.FacetValues.findIndex(
                  (value) => value === facetValue
                );
                if (facetIndex > -1) {
                  filter.FacetValues.splice(facetIndex, 1);
                } else {
                  filter.FacetValues.push(facetValue);
                }
              }
              return filter;
            })
            .filter((filter) => filter.FacetValues.length > 0)
        );
      } else {
        setFacetFilters([
          ...facetFilters,
          {
            FilterName: facetName,
            FacetValues: [facetValue],
          },
        ]);
      }
    } else {
      let facetValues =
        facetValue === 'Round'
          ? ['Rectangle', 'Square', 'Pilot/Aviator', 'Geometric']
          : facetValue === 'Oval'
          ? [
              'Rectangle',
              'Oval',
              'Square',
              'Round',
              'Cat Eye/Butterfly',
              'Pilot/Aviator',
              'Panthos/Browline',
            ]
          : facetValue === 'Square'
          ? ['Round', 'Oval', 'Panthos/Browline']
          : facetValue === 'Heart'
          ? ['Rectangle', 'Oval']
          : facetValue === 'Diamond'
          ? ['Rectangle', 'Oval', 'Cat Eye/Butterfly']
          : [];
      if (facetValues.length > 0) {
        setFacetFilters([
          ...facetFilters,
          {
            FilterName: 'Shape',
            FacetValues: facetValues,
          },
        ]);
      }
    }
  };

  const addFacetFilterList = (facetName, facetValues) => {
    setFacetFilters([
      ...facetFilters,
      {
        FilterName: facetName,
        FacetValues: facetValues,
      },
    ]);
  };

  const selectOneFacetFilters = (facetName, facetValue) => {
    if (
      facetName === 'Gender' &&
      (facetValue === 'Men' || facetValue === 'Women')
    ) {
      setFacetFilters([
        {
          FilterName: facetName,
          FacetValues: [facetValue, 'Unisex'],
        },
      ]);
    } else {
      setFacetFilters([
        {
          FilterName: facetName,
          FacetValues: [facetValue],
        },
      ]);
    }
  };

  const selectAllOfOneFacet = (facetName) => {
    let index = facetFilters.findIndex(
      (filter) => filter.FilterName === facetName
    );
    if (index > -1) {
      setFacetFilters(
        facetFilters.map((filter) => {
          if (filter.FilterName === facetName) {
            filter.FacetValues = facets
              .filter((facet) => facet.FacetName === facetName)
              .flatMap((facet) => {
                return facet.FacetOptions.map((option) => {
                  return option.Name;
                });
              });
          }
          return filter;
        })
      );
    } else {
      setFacetFilters([
        ...facetFilters,
        ...facets
          .filter((facet) => facet.FacetName === facetName)
          .map((facet) => {
            return {
              FilterName: facet.FacetName,
              FacetValues: facet.FacetOptions.map((option) => {
                return option.Name;
              }),
            };
          }),
      ]);
    }
  };

  const clearOneFacetFilter = (facetName) => {
    setFacetFilters(
      facetFilters.filter((filter) => !(filter.FilterName === facetName))
    );
  };

  const getFacetFilters = () => {
    const getType = (filtername) => {
      switch (filtername) {
        case 'Type':
          return 'type:{data}';
          break;
        case 'Gender':
          return 'gender:{data}';
          break;
        case 'Material':
          return 'material:{data}';
          break;
        case 'Color':
          return 'color:{data}';
          break;
        case 'Shape':
          return 'shape:{data}';
          break;
        case 'FrameStyle':
          return 'frame_type:{data}';
          break;
        case 'Size':
          return 'size:{data}';
          break;
        case 'Brand':
          return 'brand:{data}';
          break;
        default:
          return 'null:{data}';
      }
    };

    const replaced = facetFilters.map((f) => {
      const toReplace = getType(f.FilterName);
      const values = f.FacetValues.map((fv) => {
        return fv;
      });
      return toReplace.replace('data', values.join());
    });

    return replaced.join();
  };

  const clearAllFacetFilters = () => {
    setFacetFilters([]);
  };

  const sortItem = (a, b, property) => {
    if (a[property].toLowerCase() < b[property].toLowerCase()) return -1;
    if (a[property].toLowerCase() > b[property].toLowerCase()) return 1;
    return 0;
  };

  const fetchList = (list) => {
    const url = environment.getBaseAPI() + 'api/frames';
    const data = {
      AccountCode: environment.getAccountCode(),
    };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        AccountCode: data.AccountCode,
        PageNumber: pageNumber,
        PageSize: pageSize,
        FacetFilters: facetFilters,
      }),
      credentials: 'same-origin',
    })
      .then((res) => res.json())
      .then((result) => Promise.resolve(result))
      .then((response) => {
        setResultsSize(response.results);
        setFacets(
          response.facets.map((facet) => {
            return {
              FacetName: facet.facetName,
              FacetOptions: facet.facetOptions
                .sort((a, b) => sortItem(a, b, 'name'))
                .map((option) => {
                  return {
                    Name: option.name,
                    Quantity: option.quantity,
                  };
                }),
            };
          })
        );
        setCatalog(
          list.concat(
            response.models.map((model) => {
              return {
                ModelNumber: model.modelNumber,
                ModelName: model.modelName,
                Brand: model.brand,
                Frames: model.frameItems.map((frame) => {
                  return {
                    Upc: frame.upc,
                    Fpc: frame.fpc,
                    Color: frame.color,
                    PreviewImageURL: frame.largeImageUrl,
                  };
                }),
                SelectedFrame: {
                  Upc: model.frameItems[0].upc,
                  Fpc: model.frameItems[0].fpc,
                  Color: model.frameItems[0].color,
                  PreviewImageURL: model.frameItems[0].largeImageUrl,
                },
              };
            })
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (brands.length === 0) {
      facets.forEach((facet) => {
        if (facet.FacetName === 'Brand') {
          setBrands(facet.FacetOptions.map((option) => option.Name));
        }
      });
    }
  }, [facets]);

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem('facetFilters', JSON.stringify(facetFilters));
    }
    if (pageNumber > 1) {
      setPageNumber(1);
    } else {
      fetchList([]);
    }
  }, [facetFilters]);

  useEffect(() => {
    if (catalog.length) {
      if (pageNumber > 1) {
        fetchList(catalog);
      } else {
        fetchList([]);
      }
    }
  }, [pageNumber]);

  const value = {
    catalog,
    facets,
    brands,
    pageNumber,
    loadNextPage,
    pageSize,
    setPageSize,
    resultsSize,
    updateCatalogColorSelect,
    facetFilters,
    addOrRemoveFacetFilter,
    addFacetFilterList,
    selectAllOfOneFacet,
    clearAllFacetFilters,
    selectOneFacetFilters,
    clearOneFacetFilter,
    showModalFilters,
    toggleModalFilters,
    getFacetFilters,
  };

  return (
    <CatalogContext.Provider value={value}>
      {props.children}
    </CatalogContext.Provider>
  );
};

export { CatalogContextProvider, useCatalog };
