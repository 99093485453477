import * as React from 'react';
import {
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './frame-selector.styled';
import FullRim from '../../images/frame-select/style_fullrim.png';
import PartialRim from '../../images/frame-select/style_partialrim.png';
import Rimless from '../../images/frame-select/style_rimless.png';

const GuidedScreen5 = ({
  handleButtonClick,
  classes,
  children,
  ...restProps
}) => {
  const facetType = 'FrameStyle';

  return (
    <>
      <ImageGrid>
        <Choice>
          <Image
            src={FullRim}
            onClick={() => handleButtonClick(facetType, 'Full-Rim')}
          />
          <ButtonHorizontal
            className='btn__custom btn__larger'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Full-Rim'
          >
            Full Rim
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={PartialRim}
            onClick={() => handleButtonClick(facetType, 'Semi-Rimless')}
          />
          <ButtonHorizontal
            className='btn__custom btn__larger'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Semi-Rimless'
          >
            Partial Rim
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={Rimless}
            onClick={() => handleButtonClick(facetType, 'Rimless')}
          />
          <ButtonHorizontal
            className='btn__custom btn__larger'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Rimless'
          >
            Rimless
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </>
  );
};

export default GuidedScreen5;
