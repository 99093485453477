import * as React from 'react';
import { FDAlert, FavoritesLimitAlert } from '../../components';
import { useFavorites } from '../../provider/favorites-context';

const FavoritesLimitAlertModal = () => {
  const { maxFavoritesAlert, toggleMaxFavoritesAlert } = useFavorites();
  return (
    <FDAlert
      open={maxFavoritesAlert}
      body={<FavoritesLimitAlert handleClose={toggleMaxFavoritesAlert} />}
      handleClose={toggleMaxFavoritesAlert}
    />
  );
};

export default FavoritesLimitAlertModal;
