import React, { useContext, useEffect, useState, createContext } from 'react';
import { environment } from '../environments/environments';

const LocationContext = createContext();
const useECPLocation = () => useContext(LocationContext);
const LocationContextProvider = (props) => {
  const [location, setLocation] = useState({});

  const setDefaultEcp = () => {
    setLocation({
      Name: 'Test',
      LogoURL: null,
      Address1: '123 Main St.',
      Address2: null,
      City: 'Dallas',
      State: 'Texas',
      Zip: '70750',
      Phone: '555-1234',
      ThemePrimaryColor: environment.themePrimaryColor,
      ThemeSecondaryColor: environment.themeSecondaryColor,
    });
  };

  useEffect(() => {
    const data = {
      AccountCode: environment.getAccountCode(),
    };
    if (environment.getAccountCode()) {
      const url =
        environment.getBaseAPI() +
        'api/ecp/details?AccountCode=' +
        environment.getAccountCode();
      fetch(url, {
        method: 'GET',
      })
        .then((res) => res.json())
        .catch((e) => {
          setDefaultEcp();
        })
        .then((result) => Promise.resolve(result))
        .catch((e) => {
          setDefaultEcp();
        })
        .then((ecpConfig) => {
          setLocation({
            Name: ecpConfig.locationName,
            LogoURL: ecpConfig.logo,
            Address1: ecpConfig.locationAddress1,
            Address2: ecpConfig.locationAddress2,
            City: ecpConfig.locationCity,
            State: ecpConfig.locationState,
            Zip: ecpConfig.locationZip,
            Phone: ecpConfig.phone,
            ThemePrimaryColor: ecpConfig.themePrimaryColor
              ? ecpConfig.themePrimaryColor
              : environment.themePrimaryColor,
            ThemeSecondaryColor: ecpConfig.themeSecondaryColor
              ? ecpConfig.themeSecondaryColor
              : environment.themeSecondaryColor,
          });
        })
        .catch((e) => {
          setDefaultEcp();
        });
    }
  }, []);

  const value = { location };
  return (
    <LocationContext.Provider value={value}>
      {props.children}
    </LocationContext.Provider>
  );
};

export { LocationContextProvider, useECPLocation };
