import * as React from 'react';
import {
  SelectionContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './lens-selector.styled';

import ChildrenPets from '../../images/lens-select/Children-Pets.svg';
import CompetitiveSports from '../../images/lens-select/Competitive-Sports.svg';
import ActiveHobbies from '../../images/lens-select/Active-Hobbies.svg';
import OutdoorActivities from '../../images/lens-select/Outdoor-Activities.svg';

const LensPageActivity = ({
  handleButtonClick,
  handleSingleSelect,
  isActive,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  return (
    <SelectionContainer>
      <ImageGrid>
        <Choice>
          <Image
            src={ChildrenPets}
            onClick={() =>
              handleSingleSelect(question, 'Playing with Children/Pets')
            }
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Playing with Children/Pets')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Playing with Children/Pets'
          >
            Playing with Children/Pets
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            style={{ margin: '25px' }}
            src={CompetitiveSports}
            onClick={() => handleSingleSelect(question, 'Competitive Sports')}
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Competitive Sports')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Competitive Sports'
          >
            Competitive Sports
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={ActiveHobbies}
            onClick={() => handleSingleSelect(question, 'Active Hobbies')}
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Active Hobbies')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Active Hobbies'
          >
            Active Hobbies
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={OutdoorActivities}
            onClick={() => handleSingleSelect(question, 'Outdoor Activities')}
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Outdoor Activities')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Outdoor Activities'
          >
            Outdoor Activities
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </SelectionContainer>
  );
};

export default LensPageActivity;
