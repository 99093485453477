import ReactGA from 'react-ga';
import { environment } from '../src/environments/environments';
import React, { useState, useEffect } from 'react';

export const isBrowser = () => typeof window !== 'undefined';
export const isMobile = () => {
  let isMobileDetected = false;
  if (isBrowser()) {
    const agent = navigator.userAgent.toLowerCase();
    const obj = {
      viewport: {
        is: {
          android: !!agent.match(/android/),
          iOS: !!(
            agent.match(/iphone/) ||
            agent.match(/ipod/) ||
            agent.match(/ipad/)
          ),
        },
      },
    };

    if (obj.viewport.is.android || obj.viewport.is.iOS) {
      isMobileDetected = true;
    }
    if (window.innerWidth <= 900) {
      isMobileDetected = true;
    }
    if (window.innerWidth > 900) {
      isMobileDetected = false;
    }
  }
  return isMobileDetected;
};

export const initGA = () => {  
  const ga = environment.getGA()
  ReactGA.initialize(ga)
  PageView('Pageload')
}

export const Event = (category, action, label) => {
  const accountCode = localStorage.getItem('accountCode');
  const patientId = parseInt(localStorage.getItem('patientId')) * 64;

  const postLabel = label
    ? `ecp_id:{${accountCode}},patient_id:{${patientId}},${label}`
    : `ecp_id:{${accountCode}},patient_id:{${patientId}}`;

  ReactGA.event({
    category: category,
    action: action,
    label: postLabel,
  });
};

export const PageView = (page) => {
  ReactGA.pageview(page);
};

export const useWindowDimension = () => {
  let winW = isBrowser() ? window.innerWidth : 100;
  let winH = isBrowser() ? window.innerWidth : 100;

  const [dimension, setDimension] = useState([winW, winH]);
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      winW = isBrowser() ? window.innerWidth : 100;
      winH = isBrowser() ? window.innerWidth : 100;
      setDimension([winW, winH]);
    }, 100);

    if (isBrowser()) {
      window.addEventListener('resize', debouncedResizeHandler);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', debouncedResizeHandler);
      }
    };
  }, []);
  return dimension;
};

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
