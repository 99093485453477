import styled from 'styled-components';
import IconCheck from '../../images/icon-check-white.svg';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.form`
  hr {
    margin: 50px 0;
  }
`;
export const Row = styled.div`
  padding: 5px;
`;
export const CenterRow = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
`;
export const ShowText = styled.div`
  color: var(--app-color-primary);
  cursor: pointer;
  margin: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  text-decoration: underline;
`;
export const Input = styled.input`
  cursor: pointer;
  margin: auto;
  padding: 15px 40px;
  border: 1px solid var(--app-color-primary);
  font-size: var(--app-font-size);
  font-weight: 900;
  color: var(--app-color-bg-bright);
  background: var(--app-color-primary);
  border-radius: var(--app-border-radius);
  white-space: nowrap;
  display: block;
  :hover {
    background: transparent;
    color: var(--app-color-primary);
  }
`;
export const SendButton = styled.div`
  text-align: center;
  margin-right: auto;
  margin-left: 15px;
  cursor: pointer;
  padding: 15px 40px;
  border: 1px solid var(--app-color-primary);
  font-size: var(--app-font-size);
  font-weight: 900;
  color: var(--app-color-bg-bright);
  background: var(--app-color-primary);
  border-radius: var(--app-border-radius);
  white-space: nowrap;
  width: 140px;
  :hover {
    background: transparent;
    color: var(--app-color-primary);
  }
`;
export const CancelButton = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
  padding: 15px 40px;
  border: 1px solid var(--app-color-primary);
  font-size: var(--app-font-size);
  font-weight: 900;
  background: transparent;
  color: var(--app-color-primary);
  border-radius: var(--app-border-radius);
  white-space: nowrap;
  width: 140px;
  :hover {
    color: var(--app-color-bg-bright);
    background: var(--app-color-primary);
  }
`;

export const FormInner = styled.div`
  margin: 0 auto;
  max-width: 500px;
  margin-bottom: 40px;
`;

export const Label = styled.label`
  display: flex;

  span {
    width: 120px;
    display: inline-block;
    text-align: left;
    position: relative;
    transform: translateY(30%);
  }

  input {
    border-radius: var(--app-border-radius);
    border: 1px solid var(--app-color-bg-dark-shadow);
    padding: 12px;
    font-size: var(--app-font-size);
    margin: 5px 10px;
    display: inline-block;
    width: 100%;
  }
`;

export const SendToSelfLabel = styled.label`
  display: flex;
  justify-content: center;
`;

export const Checkbox = styled.input`
  margin-right: 20px;
  display: inline-block;
  visibility: hidden;

  -moz-appearance: initial;
  @-moz-document url-prefix() {
    height: 20px;
  }

  &[type='checkbox']:before {
    border-radius: 3px;
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    content: '';
    background: var(--app-color-bg-bright);
    visibility: visible;
  }

  &[type='checkbox']:after {
    position: relative;
    display: block;
    top: -18px;
    width: 16px;
    height: 16px;
    position: relative;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    border-color: var(--app-color-bg-dark-shadow);
    content: '';
    visibility: visible;
  }
  &:checked: after {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    border-color: var(--app-color-bg-dark);
    content: '';
    visibility: visible;
    background-image: url(${IconCheck});
    background-color: black;
    border-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-right: 8px;
  }
`;

export const Error = styled.span`
  color: var(--app-color-danger);
  font-weight: bold;
  margin-left: 10px;
  display: inlint-block;
`;
