import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
export const ActionText = styled.a`
  color: var(--app-color-link);
  font-weight: var(--app-font-weight-bolder);
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid var(--app-color-primary);
    color: var(--app-color-primary);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .card {
    margin: 20px;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    .card {
      margin: 20px 0;
      border-bottom: 1px solid var(--app-color-bg-light-shadow);
      padding-bottom: 50px;
    }
  }
`;
export const Button = styled.button`
  min-width: 100px;
  width: max-content;
  margin: 10px;
  min-width: 100px;
  height: 36px;
  padding: 0 20px;
  border: none;
  font-size: 16px;
  font-weight: 900;
  color: white;
  background: var(--app-color-primary);
  border-radius: 3px;
`;
export const Select = styled.select``;
export const AlignLeft = styled.div`
  width: 50%;
  text-align: left;
  padding: 0 40px;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    text-align: center;
    font-size: var(--app-font-size-larger);
    margin: 0 auto 40px auto;
  }
`;
export const AlignMiddle = styled.div`
  letter-spacing: 0.5px;
  width: 50%;
  text-align: center;
  padding: 0px 20px 0px 20px;
`;
export const AlignRight = styled.div`
  width: 50%;
  text-align: right;
  padding: 0 60px;
`;

export const Text = styled.p`
  text-align: right;
  padding-right: 10px;
  letter-spacing: 1px;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    text-align: center;
    padding: 0 20px;
  }
`;

export const ActionContainer = styled.div`
  width: 100%;
  padding-left: 12.5%;
  padding-right: 12.5%;
  margin-top: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;
