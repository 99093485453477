import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
import HeroBg from '../hero/hero-bg.jpg';

export const Container = styled.div`
  position: relative;
  padding-bottom: 50px;
  background-image: url(${HeroBg});
  h1,
  h2 {
    position: relative;
    z-index: 2;
    font-size: var(--app-font-size-xlarge);
    padding-left: 50px;
    max-width: 500px;
  }
  h1 {
    padding-top: 7%;
  }
  h2 {
    font-size: var(--app-font-size-large);
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    padding-bottom: 0px;
    padding-top: 65px;
    h1,
    h2 {
      text-align: left;
      padding-left: 0px;
      max-width: 450px;
      padding-left: var(--app-gutter);
      padding-right: var(--app-gutter);
    }
    h1 {
      font-size: var(--app-font-size-xlarge);
      max-width: 100%;
      margin-bottom: 10px;
    }
    h2 {
      font-size: var(--app-font-size-larger);
      margin-top: 0;
    }
  }
`;

export const InnerHero = styled.div`
  width: 90vw;
  margin: auto;
  margin-top: 65px;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    margin-top: 0;
  }
`;

export const VtoContainer = styled.div`
  //flex: 3;
  //position: relative;
  //height: 100%;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //width: 100%;
`;

export const PreviewContainer = styled.div`
  flex: 2;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const InfoContainer = styled.div`
  flex: 1;
  margin-left: 100px;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const VariationContainer = styled.div``;

export const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin: 0;
    div:first-child {
      align-items: flex-start;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 20px;
  position: relative;

  &:first-child {
    padding-bottom: 0;
  }
  &:nth-child(2) {
    padding-top: 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-height: 500px;
`;

export const Card = styled.img`
  width: 125px;
  max-height: 90px;
`;

export const Icon = styled.img`
  position: absolute;
  width: ${(props) => props.width}px;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
`;

export const Navigate = styled.img`
  position: absolute;
  bottom: 410px;
  left: 0px;
  width: 400px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: var(--app-font-size-large);
`;

export const HoverText = styled.p`
  position: absolute;
  bottom: -20px;
  color: var(--app-color-bg-dark-shadow);
  left: 33%;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    display: none;
  }
`;

export const HoverIcon = styled.img`
  height: 13%;
  position: absolute;
  bottom: 1%;
  right: 8%;
  opacity: 0.7;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    height: 30px;
    right: 40px;
    bottom: 5px;
  }
`;
export const Text = styled.p`
  margin-block-start: 0em;
  margin-block-end: 0.2em;
  font-weight: ${(props) => (props.bold ? '600' : '')};
`;
export const Label = styled.p`
  font-weight: 600;
  margin-block-end: 0.5em;
`;

export const Highlights = styled.div`
  position: relative;
  &.active {
    &:before {
      content: '';
      width: 100%;
      border-bottom: solid 4px #000;
      position: absolute;
      left: 0;
      bottom: -8px;
      z-index: 1;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid var(--app-color-bg-light-shadow);
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Button = styled.button`
  cursor: pointer;
  height: ${(props) => props.height ?? '3em'};
  background: ${(props) =>
    props.filled ? 'var(--app-color-primary)' : 'transparent'};
  border: ${(props) =>
    props.outline ? 'solid var(--app-color-primary) 1px;' : 'none'};
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  top: ${(props) => props.top ?? 'auto'};
  right: ${(props) => props.right ?? 'auto'};
  left: ${(props) => props.left ?? 'auto'};
  position: ${(props) => (props.float ? 'absolute' : 'unset')};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    right: ${(props) => (props.button === 'right' ? '0' : 'auto')};
    left: ${(props) => (props.button === 'left' ? '0' : 'auto')};
  }
`;

export const TryOnButton = styled.button`
  color: #1f1f24;
  background-color: #f2f2f2;
  position: absolute;
  top: 10px;
  border-radius: 40px;
  z-index: 1;
  min-height: 40px;
  border: none;
  cursor: pointer;
  padding: 0 24px;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;

  @media only screen and (max-width: 900px) {
    right: 25px;
  }
`;
