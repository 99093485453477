import * as React from 'react';
import {
  Info,
  Number,
  Question,
  Instruction,
  ButtonHorizontal,
  SelectAllContainer,
} from './lens-selector.styled';

import Age from './lens-page-age';
import Activity from './lens-page-activity';
import Screen from './lens-page-screen';
import Distance from './lens-page-distance';
import Exposure from './lens-page-exposure';
import Challenges from './lens-page-challenges';
import Summary from './lens-page-summary';

const LensSelector = ({
  handleButtonClick,
  handleNextButton,
  handleSingleSelect,
  handleClose,
  handleStartOver,
  lensQandACheck,
  lensRecs,
  index,
  gender,
  classes,
  children,
  ...restProps
}) => {
  const objArray = [
    {
      Question: 'What is your vision challenge?',
      Screen: (
        <Distance
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          question='Distance'
        ></Distance>
      ),
    },
    {
      Question: 'What is your age range?',
      Screen: (
        <Age
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          question='Age'
        ></Age>
      ),
    },
    {
      Question: 'What is your average screen time?',
      Screen: (
        <Screen
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          question='Screen'
        ></Screen>
      ),
    },
    {
      Question: 'Where do you spend most of your day?',
      Screen: (
        <Exposure
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          question='Exposure'
        ></Exposure>
      ),
    },
    {
      Question: 'What lens challenges do you face?',
      Screen: (
        <Challenges
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          question='Challenges'
        ></Challenges>
      ),
    },
    {
      Question: 'What is your activity level?',
      Screen: (
        <Activity
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          question='Activity'
        ></Activity>
      ),
    },
    {
      Question: 'One customized lens, just for you!',
      Screen: (
        <Summary
          handleButtonClick={handleButtonClick}
          gender={gender}
          isActive={lensQandACheck}
          handleSingleSelect={handleSingleSelect}
          handleClose={handleClose}
          handleStartOver={handleStartOver}
          lensRecs={lensRecs}
          question='Summary'
        ></Summary>
      ),
    },
  ];

  return (
    <>
      {index < 6 && (
        <>
          <Info>
            QUESTION<Number>{index + 1}</Number>OF
            <Number>{objArray.length - 1}</Number>
          </Info>
          <Question>{objArray[index].Question}</Question>
          <Instruction>
            {index === 4 || index === 5
              ? 'Choose all that apply'
              : 'Please choose one'}
          </Instruction>
        </>
      )}
      {index === 6 && (
        <>
          <Info>SUMMARY</Info>
          <Question>{objArray[index].Question}</Question>
        </>
      )}
      {objArray[index].Screen}
      {(index === 4 || index === 5) && (
        <>
          <SelectAllContainer>
            <ButtonHorizontal
              className='btn__custom active'
              onClick={() => handleNextButton()}
              active={true}
            >
              Continue
            </ButtonHorizontal>
          </SelectAllContainer>
        </>
      )}
    </>
  );
};

export default LensSelector;
