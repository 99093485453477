import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const DetailsContainer = styled.div`
  border: 1px solid var(--app-color-bg-light-shadow);
  width: 95vw;
  margin-right: var(--app-gutter);
  margin-left: var(--app-gutter);
`;

export const HeaderContainer = styled.div`
  border-bottom: 1px solid var(--app-color-bg-light-shadow);
`;

export const Container = styled.div`
  display: flex;
  padding: 40px;
  justify-content: space-between;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Image = styled.img`
  flex: 1;
  margin-left: 150px;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 18px;
  width: max-content;
  padding: 20px;
  padding: 20px 70px 20px 20px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: var(--app-color-dark);
  background-color: var(--app-color-bg-medium);
  border-right: 1px solid var(--app-color-bg-light-shadow);

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: auto;
  }
`;

export const Text = styled.p`
  margin-block-start: 0em;
  margin-block-end: 0.5em;
`;
