export const colorNameMap = new Map([
  ['Black', 'color-black.jpg'],
  ['Blue', 'color-blue.jpg'],
  ['Brown', 'color-brown.jpg'],
  ['Copper/Bronze', 'color-copper.jpg'],
  ['Gold', 'color-gold.jpg'],
  ['Green', 'color-green.jpg'],
  ['Grey', 'color-grey.jpg'],
  ['Multicolor', 'color-multicolor.jpg'],
  ['Purple', 'color-purple.jpg'],
  ['Red/Burgundy', 'color-red.jpg'],
  ['Silver/Gunmetal', 'color-silver.jpg'],
  ['Tortoise', 'color-tortoise.jpg'],
  ['Transparent', 'color-clear.jpg'],
  ['White', 'color-white.jpg'],
]);
