import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';

const AbandonedCartContext = createContext();
const useAbandonedCart = () => useContext(AbandonedCartContext);
const AbandonedCartContextProvider = (props) => {
  const [hasSentFavorites, setHasSentFavorites] = useState(
    !isBrowser()
      ? 0
      : JSON.parse(localStorage.getItem('hasSentFavorites')) || false
  );
  const [error, setError] = useState(false);

  const handleSendAbandonedCart = (favorites) => {
    fetch(environment.getBaseAPI() + 'api/patients/abandon', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        EcpAccountCode: environment.getAccountCode(),
        GoCode: environment.getPatientId(),
        Cart: favorites,
      }),
      credentials: 'same-origin',
    })
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          setError(true);
          return false;
        }
        return true;
      })
      .catch(() => setError(true));
  };

  const handlCancelAbandonedCart = (favorites) => {
    fetch(environment.getBaseAPI() + 'api/patients/cancelAbandon', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        EcpAccountCode: environment.getAccountCode(),
        GoCode: environment.getPatientId(),
        Cart: favorites,
      }),
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.status !== 200) {
          setError(true);
        }
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem(
        'hasSentFavorites',
        JSON.stringify(hasSentFavorites)
      );
    }
  }, [hasSentFavorites]);

  useEffect(() => {
    if (environment.getAccountCode() && environment.getPatientId()) {
      const sleep = (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      };

      const onUnload = async (ev) => {
        ev.preventDefault();

        if (isBrowser()) {
          const hasSentFavorites = JSON.parse(
            localStorage.getItem('hasSentFavorites')
          );
          const favoriteUpcs = JSON.parse(localStorage.getItem('favoriteUpcs'));
          if (!hasSentFavorites && favoriteUpcs.length > 0) {
            handleSendAbandonedCart(favoriteUpcs);
            sleep(500);
          }
        }
      };

      window.addEventListener('beforeunload', onUnload);

      setTimeout(() => {
        if (isBrowser()) {
          const hasSentFavorites = JSON.parse(
            localStorage.getItem('hasSentFavorites')
          );
          const favoriteUpcs = JSON.parse(localStorage.getItem('favoriteUpcs'));
          if (!hasSentFavorites && favoriteUpcs.length > 0) {
            handlCancelAbandonedCart(favoriteUpcs);
          }
        }
      }, 1000);
    }
  }, []);

  const value = {
    hasSentFavorites,
    setHasSentFavorites,
    handleSendAbandonedCart,
    handlCancelAbandonedCart,
  };
  return (
    <AbandonedCartContext.Provider value={value}>
      {props.children}
    </AbandonedCartContext.Provider>
  );
};

export { AbandonedCartContextProvider, useAbandonedCart };
