import * as React from 'react';
import { Sheet, NavigationBar, StyledAlert } from './alert.styled';
import IconButton from '../icon-button/icon-button';
import CloseIcon from '@material-ui/icons/Close';

const FDAlert = ({
  open,
  handleClose,
  handleBack,
  body,
  classes,
  children,
  ...restProps
}) => {
  return (
    <>
      <StyledAlert open={open} onClose={handleClose}>
        <Sheet>
          <NavigationBar>
            {handleClose && (
              <IconButton
                className='close'
                width='auto'
                height='auto'
                button='right'
                right='0'
                float
                icon={<CloseIcon />}
                onClick={() => {
                  handleClose();
                }}
              />
            )}
          </NavigationBar>
          {body}
        </Sheet>
      </StyledAlert>
    </>
  );
};

export default FDAlert;
