import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.div`
  padding: 25px 30px 10px 30px;
  width: 360px;
  height: 240px;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    border: none;
    width: 100%;
  }
`;
export const ClickDiv = styled.div`
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
  height: 130px;
  width: 300px;
  cursor: pointer;
`;
export const ImageRow = styled.div`
  margin: -20px 0px -20px 0px;
  height: 150px;
`;
export const Image = styled.img`
  width: 300px;
  height: 150px;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin: 0 auto;
    display: block;
  }
`;
export const TitleDiv = styled.div`
  height: 36px;
  display: flex;
  flex-direction: col;
`;

export const Title = styled.div`
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: var(--app-font-size-large);
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    font-weight: normal;
    font-size: var(--app-font-size-medium);
  }
`;
export const FavoriteRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -5px;
  margin-top: -16px;
  height: 20px;
  position: relative;
`;
export const FavoriteIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
    svg {
      fill: var(--app-color-primary);
    }
  }
`;
export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColorsGroup = styled.div`
  padding-top: 15px;
  width: auto;
  margin: auto;
`;
export const ColorSelect = styled.div`
  margin: 0px 8px 0px 8px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  display: inline-block;
  border: 1px solid var(--app-color-bg-light-shadow);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;
`;
export const ColorHighlight = styled.div`
  vertical-align: middle;
  margin-left: 5px;
  margin-right: -27px;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  display: inline-block;
  border: 1px solid var(--app-color-bg-light-shadow);
`;
