import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
import IconCheck from '../../images/icon-check-white.svg';

export const ScrollRow = styled.div`
  display: flex;
  overflow-x: scroll;
`;
export const LogoRow = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  background-color: var(--app-color-bg-bright);
  .rec-slider-container {
    margin: 0;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    display: none;
  }
`;
export const LogoHolder = styled.div`
  width: 120px;
  height: 60px;
  background-origin: content-box;
  background-color: white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-left: 1px solid var(--app-color-bg-bright);
  border-right: 1px solid var(--app-color-bg-bright);
  border-bottom: 2px solid var(--app-color-bg-bright);
  cursor: pointer;
  padding: 5px 10px 3px 10px;

  &.active,
  :hover {
    border-bottom: 2px solid var(--app-color-primary);
  }
`;
