import React, { useState, useContext, useEffect } from 'react';
import {
  Title,
  Container,
  InnerContainer,
  Card,
  CardImage,
  CardTitle,
  CardColors,
  ImageRow,
} from './product-suggestions.styled';
import Product from '../product/product';
import { ContainerFullWidth } from '../app/app.styled';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { navigate } from 'gatsby';
import { isMobile, useWindowDimension, Event } from '../../utils';
import { useProduct } from '../../provider/product-context';

const ProductSuggestion = ({
  title,
  currentPage,
  setCurrentPage,
  paginatedArray,
  setProductsPerFrame,
  origin,
  isProductPage,
  isNavB,
}) => {
  const [width, height] = useWindowDimension();

  const { selectedFrame } = useProduct();

  const navigateBack = () => {
    Event(
      origin === 'catalog' ? 'FD_Catalog' : 'FD_Product_Details',
      isProductPage
        ? 'Click_Related_Nav'
        : isNavB
        ? 'Click_Rec_Nav_B'
        : 'Click_Rec_Nav',
      `nav_click:back`
    );
    setCurrentPage(currentPage - 1);
  };

  const navigateNext = () => {
    Event(
      origin === 'catalog' ? 'FD_Catalog' : 'FD_Product_Details',
      isProductPage
        ? 'Click_Related_Nav'
        : isNavB
        ? 'Click_Rec_Nav_B'
        : 'Click_Rec_Nav',
      `nav_click:forward`
    );
    setCurrentPage(currentPage + 1);
  };

  const navigateClick = (model) => {
    Event(
      origin === 'catalog' ? 'FD_Catalog' : 'FD_Product_Details',
      isProductPage
        ? 'Click_Related_Frame'
        : isNavB
        ? 'Click_Rec_Frame_B'
        : 'Click_Rec_Frame',
      `upc: ${selectedFrame.Upc}, related_upc: ${model.SelectedFrame?.Upc}`
    );

    navigate(`/product/${model.ModelNumber}?upc=${model.SelectedFrame.Upc}`);
  };

  const colorText = (frames) => {
    return frames === 1 ? `1 Color` : `${frames} Colors`;
  };

  useEffect(() => {
    if (isMobile()) {
      setCurrentPage(0);
      setProductsPerFrame(1);
    }
  }, [isMobile()]);

  useEffect(() => {
    switch (true) {
      case width < 900:
        setCurrentPage(0);
        setProductsPerFrame(1);
        break;
      case width < 1300:
        setCurrentPage(0);
        setProductsPerFrame(3);
        break;
      case width > 1300:
        setCurrentPage(0);
        setProductsPerFrame(5);
        break;
      default:
        setCurrentPage(0);
        setProductsPerFrame(5);
    }
  }, [width, height]);

  useEffect(() => {
    if (isMobile()) {
      setProductsPerFrame(1);
    }
  }, [isMobile()]);

  return (
    <>
      <Container
        className={`m__b__small${
          isMobile() || isProductPage ? ' no-underline' : ''
        }`}
      >
        <ContainerFullWidth>
          <Title>{title}</Title>
        </ContainerFullWidth>
        <InnerContainer className='related-product_container'>
          {currentPage > 0 && (
            <Product.Button
              className='preview__button'
              width='auto'
              height='100%'
              float
              left='-20px'
              top='-20px'
              button='left'
              icon={<NavigateBeforeIcon />}
              onClick={() => {
                navigateBack();
              }}
            />
          )}
          {paginatedArray !== undefined &&
            currentPage < paginatedArray?.length - 1 && (
              <Product.Button
                className='preview__button'
                width='auto'
                height='100%'
                right='-20px'
                top='-20px'
                button='right'
                float
                icon={<NavigateNextIcon />}
                onClick={() => {
                  navigateNext();
                }}
              />
            )}

          {paginatedArray !== undefined &&
          paginatedArray[currentPage]?.length > 0 ? (
            paginatedArray[currentPage].map((model, i) => {
              return (
                <Card
                  key={
                    model.ModelNumber + '-' + model.SelectedFrame?.Upc + '-' + i
                  }
                  onClick={() => {
                    navigateClick(model);
                  }}
                >
                  <ImageRow>
                    <CardImage
                      style={{
                        backgroundImage: `url("${
                          model.SelectedFrame.PreviewImageURL ??
                          model.data.SelectedFrame.PreviewImageURL
                        }")`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '100%',
                        width: '100%',
                      }}
                      // src={
                      //   model.SelectedFrame.PreviewImageURL ??
                      //   model.data.SelectedFrame.PreviewImageURL
                      // }
                    ></CardImage>
                  </ImageRow>
                  <CardTitle>{model.Brand + ' ' + model.ModelName}</CardTitle>
                  <CardColors>{colorText(model.Frames.length)}</CardColors>
                </Card>
              );
            })
          ) : (
            <></>
          )}
        </InnerContainer>
      </Container>
    </>
  );
};

export default ProductSuggestion;
