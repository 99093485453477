import * as React from "react";
import classNames from "classnames";
import {
  Image,
  DetailsContainer,
  HeaderContainer,
  Container,
  Col,
  Row,
  Title,
  Text,
} from "./product-details.styled";

const ProductDetails = ({ classes, children, ...restProps }) => {
  return (
    <DetailsContainer
      className={classNames("product__details m__t__large", classes)}
      {...restProps}
    >
      {children}
    </DetailsContainer>
  );
};

ProductDetails.HeaderContainer = ({ classes, children, ...restProps }) => {
  return (
    <HeaderContainer
      className={classNames("product__details-container", classes)}
      {...restProps}
    >
      {children}
    </HeaderContainer>
  );
};

ProductDetails.Container = ({ classes, children, ...restProps }) => {
  return (
    <Container
      className={classNames("product__details-container", classes)}
      {...restProps}
    >
      {children}
    </Container>
  );
};

ProductDetails.Col = ({ classes, children, ...restProps }) => {
  return (
    <Col className={classNames("product__details-col", classes)} {...restProps}>
      {children}
    </Col>
  );
};

ProductDetails.Row = ({ classes, children, ...restProps }) => {
  return (
    <Row className={classNames("product__details-row", classes)} {...restProps}>
      {children}
    </Row>
  );
};

ProductDetails.Image = ({ src, classes, children, ...restProps }) => {
  return (
    <Image
      src={src}
      className={classNames("product__preview-image", classes)}
      {...restProps}
    />
  );
};

ProductDetails.Title = ({ classes, children, ...restProps }) => {
  return (
    <Title
      className={classNames("product__details-description", classes)}
      {...restProps}
    >
      {children}
    </Title>
  );
};

ProductDetails.Text = ({ classes, children, ...restProps }) => {
  return (
    <Text
      className={classNames("product__details-description", classes)}
      {...restProps}
    >
      {children}
    </Text>
  );
};

export default ProductDetails;
