import * as React from 'react';
import {
  Info,
  Number,
  Question,
  Instruction,
  SelectionContainer,
  SelectAll,
  SelectAllContainer,
} from './frame-selector.styled';

import Screen1 from './guided-screen-1';
import Screen2 from './guided-screen-2';
import Screen3 from './guided-screen-3';
import Screen4 from './guided-screen-4';
import Screen5 from './guided-screen-5';

const FrameSelector = ({
  handleButtonClick,
  handleSelectAll,
  index,
  gender,
  classes,
  children,
  ...restProps
}) => {
  let questionNumber = index + 1;

  const objArray = [
    {
      Question: 'What styles are you looking for?',
      Screen: <Screen1 handleButtonClick={handleButtonClick}></Screen1>,
      FacetType: 'Gender',
    },
    {
      Question: 'What kind of frames are you looking for?',
      Screen: <Screen2 handleButtonClick={handleButtonClick}></Screen2>,
      FacetType: 'Type',
    },
    {
      Question: 'What is your face shape?',
      Screen: (
        <Screen3
          handleButtonClick={handleButtonClick}
          gender={gender}
        ></Screen3>
      ),
      FacetType: 'Shape',
    },
    {
      Question: 'What frame materials do you prefer?',
      Screen: <Screen4 handleButtonClick={handleButtonClick}></Screen4>,
      FacetType: 'Material',
    },
    {
      Question: 'What is your favorite frame style?',
      Screen: <Screen5 handleButtonClick={handleButtonClick}></Screen5>,
      FacetType: 'FrameStyle',
    },
  ];

  return (
    <>
      <Info>
        QUESTION<Number>{questionNumber}</Number>OF
        <Number>{objArray.length}</Number>
      </Info>
      <Question>{objArray[index].Question}</Question>
      <Instruction>Please choose one</Instruction>
      <SelectionContainer index={objArray[index]}>
        {objArray[index].Screen}
      </SelectionContainer>
      {questionNumber === 5 && (
        <SelectAllContainer>
          <SelectAll onClick={() => handleSelectAll(objArray[index].FacetType)}>
            select all
          </SelectAll>
        </SelectAllContainer>
      )}
    </>
  );
};

export default FrameSelector;
