import React, { useEffect } from 'react';
import { useCatalog } from '../../provider/catalog-context';
import { ScrollRow, LogoRow, LogoHolder } from './logo-bar.styled';
import { FlexRow } from '../app/app.styled';
import { graphql } from 'gatsby';
import { logoNameMap } from './logo-map';
import Carousel from 'react-elastic-carousel';
import { Event } from '../../utils';

const LogoBar = ({ logoUrlMap }) => {
  const { brands, facetFilters, selectOneFacetFilters, clearAllFacetFilters } =
    useCatalog();

  const handleOnClick = (brandName) => {
    Event('FD_Catalog','Click_Brand_Logo',`brand_name:{${brandName}}`)
    selectOneFacetFilters('Brand', brandName);
  };

  const isActive = (facetName, facetValue) => {
    return (
      facetFilters.length === 1 &&
      facetFilters[0].FilterName === facetName &&
      facetFilters[0].FacetValues[0] === facetValue
    );
  };

  return (
    <LogoRow>
      <Carousel
        margin={0}
        transitionMs={1000}
        itemsToShow={10}
        autoPlaySpeed={5000}
        enableAutoPlay={true}
        enableMouseSwipe={true}
        enableSwipe={true}
        showEmptySlots={false}
        showArrows={false}
        pagination={false}
      >
        {brands.map((brand, i) => (
          <LogoHolder
            key={i}
            className={isActive('Brand', brand) ? 'active' : ''}
            style={{
              backgroundImage: `url(${logoUrlMap.get(
                logoNameMap.get(brand.toUpperCase())
              )})`,
            }}
            onClick={
              isActive('Brand', brand)
                ? () => clearAllFacetFilters()
                : () => handleOnClick(brand)
            }
            alt='Frame Brand'
          ></LogoHolder>
        ))}
      </Carousel>
    </LogoRow>
  );
};

export default LogoBar;
