import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import Localbase from 'localbase';
import { environment } from '../environments/environments';
import { useAbandonedCart } from './abandon-cart-context';
import { Event, isBrowser } from '../utils';

const FavoritesContext = createContext();
const useFavorites = () => useContext(FavoritesContext);
const FavoritesContextProvider = (props) => {
  const favoriteLimit = 10;
  const daysBeforeExpiration = 14;
  const [favorites, setFavorite] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const FD_Favorites = new Localbase('FD_Favorites');
  FD_Favorites.config.debug = false;

  const [maxFavoritesAlert, setMaxFavoriteAlert] = useState(false);
  const toggleMaxFavoritesAlert = () => {
    setMaxFavoriteAlert(!maxFavoritesAlert);
  };

  const [openSendFavorites, setOpenSendFavorites] = useState(false);
  const [openAbandonCart, setOpenAbadnonCart] = useState(false);
  const { hasSentFavorites, setHasSentFavorites } = useAbandonedCart();

  const handleSendFavoritesModalOpen = () => {
    const keys = favorites.map((f) => {
      return f.key;
    });
    Event(
      'FD_Sticky_Footer',
      'Click_Send_Favorites',
      `favorites:{${keys.join()}}`
    );
    setOpenSendFavorites(true);
  };

  const handleSendFavoritesModalOpenFavPage = () => {
    const keys = favorites.map((f) => {
      return f.key;
    });
    Event('FD_Favorites', 'Click_Send_Favorites', `upcs:{${keys.join()}}`);
    setOpenSendFavorites(true);
  };

  const handleSaveFavoritesClose = () => {
    if (!hasSentFavorites) {
      setOpenAbadnonCart(true);
    } else {
      setOpenSendFavorites(false);
    }
  };

  const handleAbandonCartClose = () => {
    setOpenAbadnonCart(false);
  };

  const handleSaveFavoritesCancel = () => {
    setOpenAbadnonCart(false);
    setOpenSendFavorites(false);
  };

  const reachedMaxLimit = () => {
    return favorites.length >= favoriteLimit ? true : false;
  };

  const calcExpirationDate = () => {
    let current = new Date();
    current.setDate(current.getDate() + daysBeforeExpiration);
    return current;
  };

  const cleanFramesList = (model, frame) => {
    let favFrame = [];
    let newModel = JSON.parse(JSON.stringify(model));

    if (newModel.Frames.length > 0) {
      favFrame = newModel.Frames.filter((e) => e.Upc === frame.Upc);
    }
    newModel.Frames = favFrame;
    return newModel;
  };

  const getFavorites = useCallback(async () => {
    await FD_Favorites.collection('favorites')
      .get({ keys: true })
      .then((list) => {
        setFavorite(list);
        if (!loaded) {
          setLoaded(true);
        }
      });
  }, []);

  const getFavoriteByKey = (key) => {
    if (key) {
      let _key = key.toString();
      FD_Favorites.collection('favorites')
        .doc(_key)
        .get()
        .then((model) => {});
    }
  };

  const addFavorite = (model, frame) => {
    let cleanedModel = cleanFramesList(model, frame);
    FD_Favorites.collection('favorites')
      .add(
        {
          ...cleanedModel,
          Frame: frame,
          SelectedFrame: frame,
          Expiration: calcExpirationDate(),
        },
        frame?.Upc + ''
      )
      .then(() => getFavorites());
    setHasSentFavorites(false);
  };

  const checkFavorite = (frame) => {
    return (
      favorites.findIndex((fav) => fav.key === frame?.Upc?.toString()) >= 0
    );
  };

  const removeFavorite = (frame) => {
    FD_Favorites.collection('favorites')
      .doc(frame.Upc + '')
      .delete()
      .then(() => getFavorites());
    setHasSentFavorites(false);
  };

  const removeAllFavorites = () => {
    FD_Favorites.collection('favorites')
      .delete()
      .then(() => getFavorites());
  };

  const removeExpiredFavorites = () => {
    let current = new Date();
    if (favorites) {
      favorites.forEach((e) => {
        if (current >= e.data.Expiration) {
          removeFavorite(e.data.Frame);
        }
      });
    }
  };

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem(
        'favoriteUpcs',
        JSON.stringify(favorites.map((favorite) => favorite.key))
      );
    }
  }, [favorites]);

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  useEffect(() => {
    removeExpiredFavorites();
  }, [loaded]);

  const submitSendFavoritesRequest = (
    sendFavoritesRequest,
    setErrorSendingFavorites
  ) => {
    const url = environment.getBaseAPI() + 'api/frames/favorites';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendFavoritesRequest),
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.status === 200) {
          setErrorSendingFavorites(false);
        } else {
          setErrorSendingFavorites(true);
        }
      })
      .catch(() => setErrorSendingFavorites(true));
  };

  const value = {
    favorites,
    reachedMaxLimit,
    addFavorite,
    checkFavorite,
    removeFavorite,
    getFavorites,
    removeAllFavorites,
    getFavoriteByKey,
    submitSendFavoritesRequest,
    openSendFavorites,
    openAbandonCart,
    handleSaveFavoritesClose,
    handleAbandonCartClose,
    handleSaveFavoritesCancel,
    handleSendFavoritesModalOpen,
    handleSendFavoritesModalOpenFavPage,
    maxFavoritesAlert,
    toggleMaxFavoritesAlert,
  };
  return (
    <FavoritesContext.Provider value={value}>
      {props.children}
    </FavoritesContext.Provider>
  );
};

export { FavoritesContextProvider, useFavorites };
