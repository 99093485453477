import * as React from 'react';
import { useProduct } from '../../provider/product-context';
import { ProductDetails } from '..';

const ProductDetailsCard = () => {
  const { product, selectedFrame } = useProduct();

  return (
    <>
      <ProductDetails>
        <ProductDetails.HeaderContainer>
          <ProductDetails.Title>Product Details</ProductDetails.Title>
        </ProductDetails.HeaderContainer>
        <ProductDetails.Container>
          <ProductDetails.Col>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Gender: </b>
                {selectedFrame.Gender}
              </ProductDetails.Text>
            </ProductDetails.Row>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Brand: </b>
                {product.Brand}
              </ProductDetails.Text>
            </ProductDetails.Row>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Model: </b>
                {product.ModelName}
              </ProductDetails.Text>
            </ProductDetails.Row>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>EdgeType: </b>
                {selectedFrame.EdgeType}
              </ProductDetails.Text>
            </ProductDetails.Row>
          </ProductDetails.Col>
          <ProductDetails.Col>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Frame Material: </b>
                {selectedFrame.FrameMaterial}
              </ProductDetails.Text>
            </ProductDetails.Row>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Frame Size: </b>
                {selectedFrame.FrameSize}
              </ProductDetails.Text>
            </ProductDetails.Row>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Frame Shape: </b>
                {selectedFrame.FrameShape}
              </ProductDetails.Text>
            </ProductDetails.Row>
            <ProductDetails.Row>
              <ProductDetails.Text>
                <b>Color: </b>
                {selectedFrame.Color}
              </ProductDetails.Text>
            </ProductDetails.Row>
          </ProductDetails.Col>
        </ProductDetails.Container>
      </ProductDetails>
    </>
  );
};

export default ProductDetailsCard;
