import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
//import IconFavorite from '../images/open-heart-icon.js';
import IconFavorite from '/src/images/icon-favorite-outline.svg';
import IconFavoriteFilled from '/src/images/icon-favorite-filled.svg';
import IconHome from '/src/images/icon-home.svg';
import { useFavorites } from '../provider/favorites-context';

const MenuContext = createContext();
const useMenu = () => useContext(MenuContext);
const MenuContextProvider = (props) => {
  const [mainMenu] = useState([]);

  const value = { mainMenu };
  return (
    <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>
  );
};

const ShortcutMenuContext = createContext();
const useShortcutMenu = () => useContext(ShortcutMenuContext);
const ShortcutMenuContextProvider = (props) => {
  const { favorites } = useFavorites();
  const [shortcutMenu, setShortCutMenu] = useState([
    {
      link: '/',
      text: 'home',
      icon: IconHome,
    },
    {
      link: '/favorites',
      text: 'favorites',
      icon: favorites.length > 0 ? IconFavoriteFilled : IconFavorite,
      trailingText: favorites.length,
    },
  ]);

  useEffect(() => {
    setShortCutMenu([
      {
        link: '/',
        text: 'home',
        icon: IconHome,
      },
      {
        link: '/favorites',
        text: 'favorites',
        icon: favorites.length > 0 ? IconFavoriteFilled : IconFavorite,
        trailingText: favorites.length,
      },
    ]);
  }, [favorites]);

  const value = { shortcutMenu };
  return (
    <ShortcutMenuContext.Provider value={value}>
      {props.children}
    </ShortcutMenuContext.Provider>
  );
};

export {
  ShortcutMenuContextProvider,
  useShortcutMenu,
  MenuContextProvider,
  useMenu,
};
