import * as React from 'react';
import {
  Button,
  ImageSmall,
  Image,
  Choice,
  ButtonHorizontal,
  SelectionContainer,
  ImageGridWrap,
  ImageGrid,
  ChoiceSmall,
} from './frame-selector.styled';
import Round from '../../images/frame-select/shape_round.png';
import Oval from '../../images/frame-select/shape_oval.png';
import Square from '../../images/frame-select/shape_square.png';
import Heart from '../../images/frame-select/shape_heart.png';
import Diamond from '../../images/frame-select/shape_diamond.png';
import Round_Male from '../../images/frame-select/shape_round_male.png';
import Oval_Male from '../../images/frame-select/shape_oval_male.png';
import Square_Male from '../../images/frame-select/shape_square_male.png';
import Heart_Male from '../../images/frame-select/shape_heart_male.png';
import Diamond_Male from '../../images/frame-select/shape_diamond_male.png';

const GuidedScreen3 = ({
  handleButtonClick,
  gender,
  classes,
  children,
  ...restProps
}) => {
  const facetType = 'Face Shape';

  return (
    <>
      <ImageGrid>
        <Choice>
          <Image
            src={gender === 'M' ? Round_Male : Round}
            onClick={() => handleButtonClick(facetType, 'Round')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Round'
          >
            Round
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? Oval_Male : Oval}
            onClick={() => handleButtonClick(facetType, 'Oval')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Oval'
          >
            Oval
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? Square_Male : Square}
            onClick={() => handleButtonClick(facetType, 'Square')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Square'
          >
            Square
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? Heart_Male : Heart}
            onClick={() => handleButtonClick(facetType, 'Heart')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Heart'
          >
            Heart
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? Diamond_Male : Diamond}
            onClick={() => handleButtonClick(facetType, 'Diamond')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Diamond'
          >
            Diamond
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </>
  );
};

export default GuidedScreen3;
