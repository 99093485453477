import * as React from 'react';
import SaveFavoritesForm from './save-favorites-form';
import FDModal from '../modal/modal';
import AbandonCartReminder from './abandoned-cart-reminder';
import { useAbandonedCart } from '../../provider/abandon-cart-context';
import { useFavorites } from '../../provider/favorites-context';

const SaveFavoritesFormModal = () => {
  const { setHasSentFavorites } = useAbandonedCart();

  const {
    openSendFavorites,
    openAbandonCart,
    handleSaveFavoritesClose,
    handleAbandonCartClose,
    handleSaveFavoritesCancel,
  } = useFavorites();

  return (
    <>
      <FDModal
        open={openSendFavorites}
        body={
          <SaveFavoritesForm
            handleModalClose={handleSaveFavoritesClose}
            openAbandonCart={openAbandonCart}
            handleAbandonCartClose={handleAbandonCartClose}
            handleCloseCancel={handleSaveFavoritesCancel}
            setHasSentFavorites={setHasSentFavorites}
          />
        }
        handleClose={handleSaveFavoritesClose}
      />
      {openAbandonCart && (
        <FDModal
          open={openAbandonCart}
          body={
            <AbandonCartReminder
              handleAbandonCartClose={handleAbandonCartClose}
              handleSaveFavoritesCancel={handleSaveFavoritesCancel}
            />
          }
        />
      )}
    </>
  );
};

export default SaveFavoritesFormModal;
