import * as React from 'react';
import {
  SelectionContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  ChoiceHorizontal,
} from './lens-selector.styled';

import ScreenTime from '../../images/lens-select/Time-Clock.svg';

const LensPageScreen = ({
  handleButtonClick,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  return (
    <>
      <SelectionContainer style={{ height: 'auto', overflow: 'unset' }}>
        <Image style={{ margin: '25px', maxWidth: '200px' }} src={ScreenTime} />
      </SelectionContainer>
      <SelectionContainer>
        <ImageGrid>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className='btn__custom'
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='0 to 3 Hours Per Day'
            >
              0 to 3 Hours Per Day
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className='btn__custom'
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='4 to 6 Hours Per Day'
            >
              4 to 6 Hours Per Day
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className='btn__custom'
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='7 to 9 Hours Per Day'
            >
              7 to 9 Hours Per Day
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className='btn__custom'
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='10+ Hours Per Day'
            >
              10+ Hours Per Day
            </ButtonHorizontal>
          </ChoiceHorizontal>
        </ImageGrid>
      </SelectionContainer>
    </>
  );
};

export default LensPageScreen;
