import React from 'react';
import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
import IconCheck from '../../images/icon-check-white.svg';
import Tooltip from '@material-ui/core/Tooltip';
export const Container = styled.nav`
  position: relative;
  z-index: 2;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid var(--app-color-bg-light-shadow);
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    z-index: 999;
    border: none;
  }
`;

export const FiltersHolder = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-direction: row;
  position: relative;
  justify-content: space-around;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    flex-direction: column;
    position: fixed;
    background: var(--app-color-bg-bright);
    top: 60px;
    text-align: left;
    height: 100vh;
    justify-content: flex-start;
    overflow-y: scroll;
  }
`;

export const FiltersMobileBar = styled.div`
  border-bottom: 1px solid var(--app-color-bg-light-shadow);
  border-top: 1px solid var(--app-color-bg-light-shadow);
  width: 100vw;
  padding: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
    margin: 0 20px;
    width: 100%;
  }
  .btn__filter {
    justify-content: flex-start;
    font-size: var(--app-font-size-xlarge);
  }
  .btn__close {
    justify-content: flex-end;
  }
`;

export const FiltersHeadline = styled.a`
  text-transform: capitalize;
  display: block;
  cursor: pointer;
  padding: 15px 10px;
  z-index: 2;
  position: relative;
  transform: translateY(2px);
  white-space: nowrap;

  img {
    transform: rotate(-90deg);
    top: -3px;
    position: relative;
    left: 5px;
  }
  :hover,
  :active,
  :focus {
    img {
      &.active {
        transform: rotate(90deg);
      }
    }
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    img {
      float: right;
    }
  }
`;

export const FiltersList = styled.li`
  list-style: none;
  border: 1px solid transparent;
  position: relative;
  :hover,
  :active,
  :focus {
    border: 1px solid var(--app-color-bg-light-shadow);
    border-bottom-color: transparent;
    a {
      background: var(--app-color-bg-bright);
    }
    ul {
      display: inline-block;
      position: absolute;
      min-width: 300px;
      overflow-y: hidden;
    }
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      ul {
        position: relative;
        overflow-y: scroll;
      }
    }
    :nth-child(n + 5) ul {
      right: -2px;
    }
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    border: none;

    :hover {
      ul {
        display: none;
      }
    }
    &.active {
      ul {
        display: inline-block;
      }
    }
  }
`;
export const FiltersListItemHolder = styled.ul`
  list-style: none;
  display: none;
  border: 1px solid var(--app-color-bg-light-shadow);
  background: var(--app-color-bg-bright);
  padding: 2rem;
  margin: 0;
  transform: translate(-1px, 1px);
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    border: none;
    border-bottom: 1px solid var(--app-color-bg-light-shadow);
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;
export const FiltersListItem = styled.li`
  z-index: 1;
  position: relative;
  a {
    display: block;
  }
  label {
    padding: 5px 0;
    cursor: pointer;
    padding: 0 0 10px 0;
    display: block;

    input {
      margin-right: 20px;
      display: inline-block;
      visibility: hidden;
      -moz-appearance: initial;
      @-moz-document url-prefix() {
        height: 20px;
      }
      &[type='checkbox']:before {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        background: var(--app-color-bg-bright);
        visibility: visible;
      }

      &[type='checkbox']:after {
        position: relative;
        display: block;
        left: -1px;
        top: -25px;
        height: 20px;
        width: 20px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--app-color-bg-dark);
        content: '';
        visibility: visible;
      }

      &:checked:after {
        background-image: url(${IconCheck});
        background-color: var(--app-color-primary);
        border-color: var(--app-color-primary);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    label {
      padding: 5px 25px 0;
      height: 30px;
    }
  }
`;
export const ClearFilters = styled.div`
  color: var(--app-color-dark);
  transition: all var(--app-speed) ease;
  opacity: 0.5;
  cursor: pointer;
  padding: 0 0 5px 0;

  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    &:hover{
      opacity: 0.5;
    }
  }

  &.filters-applied{
    opacity: 1;
  }
`;
export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-arrow {
    color: var(--app-color-bg-bright);
    font-size: 20px;
    &::before {
      background-color: var(--app-color-bg-bright);
      border: 1px solid var(--app-color-bg-light-shadow);
      box-shadow: 2px 2px 2px 1px var(--app-color-bg-light-shadow);
    }
  }
  & .MuiTooltip-tooltip {
    background-color: var(--app-color-bg-bright);
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid var(--app-color-bg-light-shadow);
    box-shadow: 2px 2px 2px 1px var(--app-color-bg-light-shadow);
    font-size: 11;
  }
`;
