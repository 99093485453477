import * as React from 'react';
import { useEffect } from 'react';
import { Sheet, NavigationBar, StyledModal } from './modal.styled';
import IconButton from '../icon-button/icon-button';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const FDModal = ({
  open,
  tIndex,
  handleClose,
  handleBack,
  body,
  classes,
  children,
  ...restProps
}) => {
  useEffect(() => {
    let value = open ? 'hidden' : '';
    document.body.style.overflow = value;
  }, [open]);

  return (
    <>
      <StyledModal open={open} onClose={handleClose}>
        <Sheet {...restProps}>
          <NavigationBar>
            {handleBack && tIndex > 0 && (
              <IconButton
                className='back'
                width='auto'
                height='auto'
                button='left'
                left='0'
                float
                icon={<ArrowBackIcon />}
                onClick={() => {
                  handleBack();
                }}
              />
            )}
            {handleClose && (
              <IconButton
                className='close'
                width='auto'
                height='auto'
                button='right'
                right='0'
                float
                icon={<CloseIcon />}
                onClick={() => {
                  handleClose();
                }}
              />
            )}
          </NavigationBar>
          {body}
        </Sheet>
      </StyledModal>
    </>
  );
};

export default FDModal;
