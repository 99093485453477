import * as React from 'react';
import classNames from 'classnames';
import {
  Container,
  Row,
  CenterRow,
  ShowText,
  Label,
  Error,
  FormInner,
  SendButton,
  CancelButton,
} from './form.styled';
import { FiAlertTriangle } from 'react-icons/fi';

const Form = ({ classes, children, ...restProps }) => {
  return (
    <Container className={classNames('form', classes)} {...restProps}>
      {children}
    </Container>
  );
};

Form.Label = ({ classes, children, ...restProps }) => {
  return (
    <Row className={classNames('form__row', classes)} {...restProps}>
      <Label>{children}</Label>
    </Row>
  );
};

Form.Inner = ({ classes, children, ...restProps }) => {
  return (
    <FormInner className={classNames('form__row', classes)} {...restProps}>
      {children}
    </FormInner>
  );
};

Form.Error = ({ classes, children, ...restProps }) => {
  return (
    <Row className={classNames('form__row', classes)} {...restProps}>
      <FiAlertTriangle />
      <Error>{children}</Error>
    </Row>
  );
};

Form.ShowForm = ({ classes, ...restProps }) => {
  return (
    <CenterRow
      className={classNames('form__row_center', classes)}
      {...restProps}
    >
      <ShowText onClick={() => restProps.handleClick()}>Show Form</ShowText>
    </CenterRow>
  );
};

Form.CenterRow = ({ classes, children, ...restProps }) => {
  return (
    <CenterRow
      className={classNames('form__row_center', classes)}
      {...restProps}
    >
      {children}
    </CenterRow>
  );
};

Form.SubmitBtn = ({ onSubmit, classes, children, ...restProps }) => {
  return <SendButton onClick={onSubmit}>Send</SendButton>;
};

Form.CancelBtn = ({ handleClose, classes, children, ...restProps }) => {
  return <CancelButton onClick={handleClose}>Cancel</CancelButton>;
};

export default Form;
