import * as React from 'react';
import { CgCopyright } from 'react-icons/all';
import {
  Container,
  FDSnackbar,
  AlignLeft,
  AlignRight,
  FDLink,
  LinkContainer,
  EcpName,
  EcpInfo,
  Copyright,
  FavoriteIcon,
} from './footer.styled';
import { Link } from 'gatsby';
import { useState, useEffect } from 'react';
import { useFavorites } from '../../provider/favorites-context';
import ClosedHeartIcon from '../../images/closed-heart-icon.js';
import OpenHeartIcon from '../../images/open-heart-icon.js';
import { FlexRow } from '../app/app.styled';
import { useECPLocation } from '../../provider/location.context';
import { useCatalog } from '../../provider/catalog-context';
import FDFilter from '../../images/icon-filter.svg';
import { useLocation } from '@reach/router';
import { Event, isBrowser, isMobile, useWindowDimension } from '../../utils';
import { SCREEN_SIZE } from '../app/app.styled';
import { environment } from '../../environments/environments';
import { useVto } from '../../provider/vto-context';

const Footer = ({ text }) => {
  const { favorites, handleSendFavoritesModalOpen } = useFavorites();
  const { isVtoModalOpen } = useVto();
  const { location } = useECPLocation();
  const { toggleModalFilters } = useCatalog();
  const [width, height] = useWindowDimension();
  const [isHome, setIsHome] = useState(false);
  const pathName = useLocation().pathname;

  useEffect(() => {
    let rootPath = '/';
    if (isBrowser()) {
      setIsHome(false);
      if (pathName === rootPath) {
        setIsHome(true);
      }
    }
  }, [pathName]);

  const formattedNumber = (number) => {
    let match = number?.toString().match(/(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  };

  const address = () => {
    var strings = [location.Address2, location.City, location.State];

    var combined = strings.filter(Boolean).join(', ');
    return location.Address1
      ? location.Address1 + ' ' + combined + ' ' + location.Zip
      : '';
  };

  return (
    <>
      {(favorites.length >= 1 || isMobile() || width < SCREEN_SIZE.sm) && (
        <FDSnackbar isVtoModalOpen={isVtoModalOpen}>
          <FlexRow className='full__width'>
            {(isMobile() || width < SCREEN_SIZE.sm) && isHome && (
              <div
                onClick={() => toggleModalFilters()}
                className='btn__filter cursor'
              >
                <img src={FDFilter} />
              </div>
            )}
            <div className='center__width'>
              <LinkContainer>
                <Link className='txt-link' to={'/favorites'}>
                  <h2>
                    <FavoriteIcon>
                      {favorites.length < 1 && <OpenHeartIcon />}
                      {favorites.length >= 1 && <ClosedHeartIcon />}
                    </FavoriteIcon>
                    &nbsp; {favorites.length} Favorites
                  </h2>
                </Link>
              </LinkContainer>

              <p className='md-down-hide'>
                Send frame favorites to your doctor for free
              </p>
            </div>
            {favorites.length > 0 && (
              <a href='#' onClick={handleSendFavoritesModalOpen}>
                Send Now
              </a>
            )}
            {favorites.length <= 0 && <a className='btn__disabled'>Send Now</a>}
          </FlexRow>
        </FDSnackbar>
      )}

      <Container>
        <AlignLeft>
          <EcpName>{location.Name}</EcpName>
          <EcpInfo>
            <FDLink
              className='txt__blue'
              href={`http://maps.google.com/maps?q= ${address()}`}
              target='_blank'
              onClick={() => {
                Event('FD_Footer', 'Click_ECP_Address', '');
              }}
            >
              {address()}
            </FDLink>
          </EcpInfo>
          <EcpInfo>
            <a
              className='txt__blue'
              href={`tel: ${formattedNumber(location.Phone)}`}
              onClick={() => {
                Event('FD_Footer', 'Click_ECP_Phone', '');
              }}
            >
              {formattedNumber(location.Phone)}
            </a>
          </EcpInfo>
        </AlignLeft>
        <AlignRight>
          <div>
            <FDLink
              href='https://www.essilorusa.com/terms-and-conditions'
              onClick={() => {
                Event('FD_Footer ', 'Click_Goto_Terms', '');
              }}
              target='_blank'
            >
              Terms of Use
            </FDLink>
            <FDLink
              href='https://www.essilorusa.com/privacy-policy'
              onClick={() => {
                Event('FD_Footer ', 'Click_Goto_Privacy', '');
              }}
              target='_blank'
            >
              Privacy Policy
            </FDLink>
            <FDLink
              href='https://www.essilorusa.com/about/ethics-compliance'
              onClick={() => {
                Event('FD_Footer ', 'Click_Goto_Ethics', '');
              }}
              target='_blank'
            >
              Ethics &amp; Compliance
            </FDLink>
          </div>
          <Copyright>
            <CgCopyright />
            {new Date().getFullYear()} Built by Essilor v {environment.version}
          </Copyright>
        </AlignRight>
        <script src='https://www.googleoptimize.com/optimize.js?id=OPT-MCXLB55'></script>
        <script src='https://apps.elfsight.com/p/platform.js' defer></script>
        <div className='elfsight-app-85f3fea6-ba3f-426a-bc91-5d16266abe11'></div>
      </Container>
    </>
  );
};

export default Footer;
