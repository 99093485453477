import * as React from 'react';
import { useFavorites } from '../../provider/favorites-context';
import { SkeletonCards } from '../../components';
import { useLensSelector,  } from '../../provider/lens-context';
import { useFrameSelector  } from '../../provider/frame-selector-context';

const FavoritesCards = ({ colorUrlMap }) => {
  const { favorites } = useFavorites();
  const handleColorSelect = () => {};

  const { handleLensOpen } = useLensSelector();
  const { handleOpen } = useFrameSelector();

  return (
    <SkeletonCards
      handleOpenLensSelectorModal={handleLensOpen}
      handleOpenFrameSelectorModal={handleOpen}
      list={favorites}
      handleColorSelect={handleColorSelect}
      showResultsBar={false}
      favoritesCards={true}
      colorUrlMap={colorUrlMap}
      noCardsText="You have 0 favorites. Continue browsing, add up to 10 favorites to send to
      us, and we'll have them ready for you to try on at your appointment!"
    />
  );
};

export default FavoritesCards;
