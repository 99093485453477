import * as React from 'react';
import {
  SelectionContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './lens-selector.styled';

import SeeingNear from '../../images/lens-select/Seeing-Near.png';
import SeeingFar from '../../images/lens-select/Seeing-Far.png';
import SeeingBoth from '../../images/lens-select/Seeing-Both.png';

const LensPageDistance = ({
  handleButtonClick,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  return (
    <SelectionContainer>
      <ImageGrid>
        <Choice>
          <Image
            style={{ width: '250px', margin: '17px 0px 16px 0px' }}
            src={SeeingNear}
            onClick={() => handleButtonClick(question, 'Seeing Near')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='Seeing Near'
          >
            Seeing Near
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            style={{ width: '250px', margin: '17px 0px 16px 0px' }}
            src={SeeingFar}
            onClick={() => handleButtonClick(question, 'Seeing Far')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='Seeing Far'
          >
            Seeing Far
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            style={{ width: '250px', margin: '17px 0px 16px 0px' }}
            src={SeeingBoth}
            onClick={() => handleButtonClick(question, 'Seeing Both')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='Seeing Both'
          >
            Seeing Both
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </SelectionContainer>
  );
};

export default LensPageDistance;
