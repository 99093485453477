import * as React from 'react';
import {
  Box,
  Header,
  SubHeadline,
  Headline,
  Title,
  Description,
  Row,
  Column,
  ContentHeader,
  ContentImage,
  Container,
  GridContainer,
  ContentImageSmall,
} from './browse-option.styled';
import IconButton from '../icon-button/icon-button';
import Automate from '../../images/frame-select/intro_automate.png';
import Manual from '../../images/frame-select/intro_manual.png';
import { Event } from '../../utils';

const BrosweOption = ({
  handleOpen,
  handleClose,
  classes,
  children,
  ...restProps
}) => {
  return (
    <Box>
      <Header>
        <SubHeadline>
          Find, save and try-on your favorite frames at our practice.
        </SubHeadline>
        <Headline>You can either...</Headline>
      </Header>
      <Row>
        <GridContainer container spacing={3}>
          <Container>
            <ContentHeader>
              <Title>Browse Full Selection</Title>
              <Description>Use manual website filters</Description>
            </ContentHeader>
            <Column>
              <ContentImage src={Manual}></ContentImage>
              <IconButton
                filled
                color='white'
                onClick={() => {
                  Event('FD_Catalog', 'Click_Browse_All_Modal', '');
                  handleClose();
                }}
              >
                BROWSE FULL WEBSITE
              </IconButton>
            </Column>
          </Container>
          <Container>
            <ContentHeader>
              <Title>Guide Me Through</Title>
              <Description>
                Use the automated Frame Selector to help me refine my choices
              </Description>
            </ContentHeader>
            <Column>
              <ContentImageSmall src={Automate}></ContentImageSmall>
              <IconButton
                filled
                color='white'
                onClick={() => {
                  Event('FD_Catalog', 'Click_Frame_Selector_Modal', '');
                  handleOpen();
                }}
              >
                FRAME SELECTOR
              </IconButton>
            </Column>
          </Container>
        </GridContainer>
      </Row>
    </Box>
  );
};

export default BrosweOption;
