import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { environment } from '../environments/environments';

const PatientContext = createContext();
const usePatient = () => useContext(PatientContext);
const PatientContextProvider = (props) => {
  const [patient, setPatient] = useState({});

  useEffect(() => {
    const data = {
      EcpAccountCode: environment.getAccountCode(),
      GoCode: environment.getPatientId(),
    };
    if (data.EcpAccountCode && data.GoCode) {
      const url =
        environment.getBaseAPI() +
        'api/patients/details?EcpAccountCode=' +
        encodeURIComponent(data.EcpAccountCode) +
        '&GoCode=' +
        encodeURIComponent(data.GoCode);

      fetch(url, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => Promise.resolve(result))
        .then((patient) => {
          setPatient({
            FirstName: patient.firstName,
            LastName: patient.lastName,
            Phone: patient.primaryPhone,
            Email: patient.email,
          });
        })
        .catch((e) => {
          console.log('request error', e);
        });
    }
  }, []);

  const value = { patient };
  return (
    <PatientContext.Provider value={value}>
      {props.children}
    </PatientContext.Provider>
  );
};

export { PatientContextProvider, usePatient };
