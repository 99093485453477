import * as React from 'react';
import { Container } from './button.styled';
import classNames from 'classnames';

const FDButton = ({ handleClick, active, children, classes, ...restProps }) => {
  return (
    <Container className={classNames('card', classes)} {...restProps}>
      <div onClick={() => handleClick()}>{children}</div>
    </Container>
  );
};

export default FDButton;
