import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import '../app/app.scss';
import './layout.scss';
import '../modal/modal.scss';
import '../product/product.scss';
import { Header, Footer } from '../index';
import { AppStyle } from '../app/app.styled';
import {
  ComposerContext,
  CatalogContextProvider,
  ProductContextProvider,
  LocationContextProvider,
  MenuContextProvider,
  ShortcutMenuContextProvider,
  FavoritesContextProvider,
  PatientContextProvider,
  AbandonedCartContextProvider,
  RelatedProductsContextProvider,
  PopularProductsContextProvider,
  PastPurchaseContextProvider,
  FrameSelectorContextProvider,
  LensContextProvider,
  VtoContextProvider
} from '../../provider';
import createMockServer from '../../mirage/createMockServer';
import { isBrowser } from '../../utils';
import { navigate } from 'gatsby';
import { environment } from '../../environments/environments';
import { Router } from '@reach/router';
import ProductRoute from '../../components/product/product-route';

const Layout = ({ children }) => {
  // Development Feature Not needed anymore but here for reference
  // createMockServer();

  useEffect(() => {
    if (isBrowser) {
      let accountCode = localStorage.getItem('accountCode');
      if (accountCode) {
        environment.accountCode = accountCode;
      } else {
        navigate('/403');
      }
    }
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <ComposerContext
            components={[
              LocationContextProvider,
              PatientContextProvider,
              AbandonedCartContextProvider,
              FavoritesContextProvider,
              CatalogContextProvider,
              MenuContextProvider,
              ProductContextProvider,
              ShortcutMenuContextProvider,
              RelatedProductsContextProvider,
              PopularProductsContextProvider,
              PastPurchaseContextProvider,
              LensContextProvider,
              FrameSelectorContextProvider,
              VtoContextProvider
            ]}
          >
            <AppStyle theme='yellow' />
            <Header />

            <Router basepath='/'>
              <ProductRoute path='/product/*' />
            </Router>

            <main> {children} </main>
            <Footer />
          </ComposerContext>
        </>
      )}
    ></StaticQuery>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
