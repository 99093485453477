import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
import Modal from '@material-ui/core/Modal';

export const Sheet = styled.div`
  z-index: 10;
  width: 60%;
  margin: auto auto;
  display: flex;
  background: white;
  padding: 40px;
  flex-direction: column;
  position: relative;
  height: fit-content;
  min-height: 200px;
  max-width: var(--app-max-width);
  overflow: auto;
  top: 30%;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    max-height: fit-content;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 20px;
  }
`;

export const NavigationBar = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

//MuiBackdrop-root
export const StyledAlert = styled(Modal)`
  .MuiBackdrop-root {
    background: rgba(255, 255, 255, 0.3);
  }
`;
