import * as React from 'react';
import { Container } from './favorite-button.styled';
import { Button } from './favorite-button.styled';

const FavoriteButton = ({ frame, model, handelFavorite }) => {
  return (
    <Container>
      <Button
        onClick={() => {
          handelFavorite(frame, model);
        }}
      >
        {frame.Color}
      </Button>
      *
    </Container>
  );
};

export default FavoriteButton;
