import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Button = styled.button`
  cursor: pointer;
  height: ${(props) => props.height ?? '3em'};
  background: ${(props) =>
    props.filled ? 'var(--app-color-primary)' : 'transparent'};
  border: ${(props) =>
    props.outline ? 'solid var(--app-color-primary) 1px;' : 'none'};
  color: ${(props) => props.color};
  font-size: var(--app-font-size);
  font-weight: 600;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width ?? 'max-content'};
  top: ${(props) => props.top ?? 'auto'};
  right: ${(props) => props.right ?? 'auto'};
  left: ${(props) => props.left ?? 'auto'};
  position: ${(props) => (props.float ? 'absolute' : 'unset')};
  padding: ${(props) => (!props.float ? '12px 50px' : '0px')};

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin: 0;
    font-size: var(--app-font-size);
    min-height: 50px;
  }
`;
