import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';
import { useProduct } from '../provider/product-context';

const RelatedProductsContext = createContext();
const useRelatedProducts = () => useContext(RelatedProductsContext);
const RelatedProductsContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const { product, selectedFrame } = useProduct();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [resultsSize, setResultsSize] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedArray, setPaginatedArray] = useState([]);
  const [productsPerFrame, setProductsPerFrame] = useState(5);

  useEffect(() => {
    let cards = products ?? [
      {
        Model: {},
        Frames: [{}],
        PreviewImageURL: '',
        ModelName: '',
        ModelNumber: '',
      },
    ];
    setPaginatedArray([]);
    let index = 0;
    for (index = 0; index < cards.length; index += productsPerFrame) {
      let page = cards.slice(index, index + productsPerFrame);
      setPaginatedArray((paginatedArray) => [...paginatedArray, page]);
    }
  }, [products, productsPerFrame]);

  const fetchList = (list) => {
    const url = environment.getBaseAPI() + 'api/frames/related';
    const data = {
      AccountCode: environment.getAccountCode(),
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        AccountCode: data.AccountCode,
        ModelNumber: parseInt(product.ModelNumber),
      }),
      credentials: 'same-origin',
    })
      .then((res) => res.json())
      .then((result) => Promise.resolve(result))
      .then((response) => {
        setResultsSize(response.results);
        setCurrentPage(0);
        setProducts(
          list.concat(
            response.models.map((model) => {
              return {
                ModelNumber: model.modelNumber,
                ModelName: model.modelName,
                Brand: model.brand,
                Frames: model.frameItems.map((frame) => {
                  return {
                    Upc: frame.upc,
                    Fpc: frame.fpc,
                    Color: frame.color,
                    PreviewImageURL: frame.largeImageUrl,
                  };
                }),
                SelectedFrame: {
                  Upc: model.frameItems[0].upc,
                  Fpc: model.frameItems[0].fpc,
                  Color: model.frameItems[0].color,
                  PreviewImageURL: model.frameItems[0].largeImageUrl,
                },
              };
            })
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (product && product.Brand) {
      fetchList([]);
    }
  }, [product]);

  const value = {
    products,
    resultsSize,
    currentPage,
    setCurrentPage,
    paginatedArray,
    productsPerFrame,
    setProductsPerFrame,
  };

  return (
    <RelatedProductsContext.Provider value={value}>
      {props.children}
    </RelatedProductsContext.Provider>
  );
};

export { RelatedProductsContextProvider, useRelatedProducts };
