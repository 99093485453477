import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Info = styled.p`
  width: 90%;
  position: absolute;
  top: 10px;
  letter-spacing: 2px;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    width: 80%;
    right: 10%;
  }
`;

export const Number = styled.div`
  margin: 0;
  align-self: center;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: var(--app-font-size-medium);
  padding: 0 5px;
`;

export const Question = styled.h1`
  width: 60%;
  font-weight: 600;
  align-self: center;
  text-align: center;
  color: var(--app-color-primary);
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-top: 60px;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    width: 80%;
  }
`;

export const Instruction = styled.p`
  position: relative;
  width: fit-content;
  align-self: center;
  text-align: center;
  font-style: italic;
  font-size: var(--app-font-size-large);

  &:before {
    content: '';
    position: absolute;
    width: 200px;
    height: 3px;
    bottom: 9px;
    left: 250px;
    background: var(--app-color-bg-dark-shadow);
    display: block;
  }
  &:after {
    content: '';
    position: absolute;
    width: 200px;
    height: 3px;
    bottom: 9px;
    right: 250px;
    background: var(--app-color-bg-dark-shadow);
    display: block;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    &:before {
      width: 125px;
    }
    &:after {
      width: 125px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    font-size: var(--app-font-size);

    &:before {
      width: 55px;
      left: 170px;
    }
    &:after {
      width: 55px;
      right: 170px;
    }
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  align-self: center;
  justify-content: center;

  button.active {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);

    :hover {
      color: var(--app-color-primary);
      background: var(--app-color-bg-bright);
      border: 1px solid var(--app-color-primary);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: revert;
    max-height: 420px;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    max-height: 100%;
  }
`;

export const SelectAllContainer = styled.div`
  align-self: center;
  margin-top: 15px;

  button.active {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);

    :hover {
      color: var(--app-color-primary);
      background: var(--app-color-bg-bright);
      border: 1px solid var(--app-color-primary);
    }
  }
`;

export const Image = styled.img`
  object-fit: contain;
  height: 250px;
  align-self: center;
  text-align: center;
  max-width: 250px;
  height: auto;
  min-heigth: 300px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    max-height: fit-content;
    width: auto;
    max-width: 25%;
    max-height: 100px;
    min-width: 120px;
    margin: -10px 0 !important;
  }
`;

export const Button = styled.button`
  margin: auto;
  width: fit-content;
  padding: 5px;
  border: none;
  font-size: var(--app-font-size);
  font-weight: 900;
  color: 'black';
  background: var(--app-color-bg-bright);
  border: 1px solid var(--app-color-primary);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  z-index: 2;

  :hover {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    height: 100%;
    min-height: 45px;
  }
`;

export const ButtonHorizontal = styled.button`
  margin: auto;
  width: fit-content;
  min-width: 260px;
  max-width: 400px;
  padding: 5px;
  border: none;
  font-size: var(--app-font-size);
  font-weight: 900;
  color: 'black';
  background: var(--app-color-bg-bright);
  border: 1px solid var(--app-color-primary);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  z-index: 2;
  line-height: 20px;

  :hover {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    width: 100%;
    padding: 12px 35px;
    z-index: 3;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    height: 45px;
  }
`;

export const Choice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    padding-bottom: 0px;
    min-height: 170px;
  }
`;

export const Text = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 24px;
  font-weight: 500;
`;

export const SubText = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  margin: -10px 0px 10px 0px;
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  align-self: center;
  justify-content: revert;
  overflow: hidden;
  height: 100%;
  max-height: 420px;
  margin: 30px;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    width: 60%;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 80%;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.xs}) {
    width: 90%;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 24px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    font-size: 16px;
    width: 80%;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.xs}) {
    width: 90%;
  }
`;

export const ChoiceHorizontal = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    flex-direction: row;
  }
`;

export const ImageGrid = styled.div`
  direction: row;
  justify: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZE.md2}) {
    flex-direction: column;
  }
`;
