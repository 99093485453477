import * as React from 'react';
import LensSelector from './lens-selector';
import FDModal from '../modal/modal';
import { useFrameSelector } from '../../provider/frame-selector-context';
import { useLensSelector } from '../../provider/lens-context';

const LenseSelectorModal = () => {
  const { gender } = useFrameSelector();

  const {
    lensIndex,
    lensOpen,
    handleLensButtonClick,
    handleLensSingleSelect,
    handleLensNextButton,
    handleLensBackButton,
    handleLensClose,
    lensRecs,
    lensQandACheck,
    handleLensStartOver,
  } = useLensSelector();

  return (
    <FDModal
      style={{ width: '85%' }}
      open={lensOpen}
      tIndex={lensIndex}
      handleBack={handleLensBackButton}
      body={
        <LensSelector
          index={lensIndex}
          gender={gender}
          handleButtonClick={handleLensButtonClick}
          handleNextButton={handleLensNextButton}
          handleSingleSelect={handleLensSingleSelect}
          lensQandACheck={lensQandACheck}
          handleClose={handleLensClose}
          handleStartOver={handleLensStartOver}
          lensRecs={lensRecs}
        />
      }
      handleClose={handleLensClose}
    />
  );
};

export default LenseSelectorModal;
