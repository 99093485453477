import * as React from 'react';
import FrameSelector from './frame-selector';
import FDModal from '../modal/modal';
import { useFrameSelector } from '../../provider/frame-selector-context';

const FrameSelectorModal = () => {
  const {
    open,
    index,
    gender,
    handleSelectAll,
    handleButtonClick,
    handleBack,
    handleClose,
  } = useFrameSelector();

  return (
    <>
      <FDModal
        style={{ width: '85%' }}
        open={open}
        tIndex={index}
        handleBack={handleBack}
        body={
          <FrameSelector
            index={index}
            gender={gender}
            handleButtonClick={handleButtonClick}
            handleSelectAll={handleSelectAll}
          />
        }
        handleClose={handleClose}
      />
    </>
  );
};

export default FrameSelectorModal;
