import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.header`
  z-index: 1000;
  height: var(--app-menu-width);
  padding: 1.3rem;
  background: var(--app-color-secondary);
  position: fixed;
  top: 0px;
  width: 100vw;
  border-bottom: var(--app-border-light);
  flex-basis: 50%;
  justify-content: space-around;
  color: var(--app-color-bg-dark);
  div {
    flex-basis: 1;
  }
  
  display: ${(props) => props.isVtoModalOpen ? 'none' : 'flex'};
`;

export const HeaderLogo = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-indent: -9999px;
  display: inline-block;
  width: 180px;
  margin-top: -20px;
  margin-bottom: -20px;
  top: 0px;
  position: relative;
  cursor: pointer;
`;

export const Headline = styled.div`
  h1 {
    margin: 0;
    font-size: var(--app-font-size-large);
    font-weight: 700;
    text-transform: capitalize;
    min-width: 300px;
    text-align: center;
    display: none;
    @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
      display: none;
    }
  }
`;
