import * as React from 'react';
import {
  AbandonCart,
  ButtonContainer,
  Button,
  Description,
  Bold,
  Text,
} from './save-favorites-form.styled';
import FDButton from '../button/button';

const AbandonCartReminder = ({
  handleAbandonCartClose,
  handleSaveFavoritesCancel,
}) => {
  return (
    <AbandonCart>
      <h1 className='txt__center color__primary txt__caps font__size__large'>
        <Bold>Oops! Did you mean to do that?</Bold>
      </h1>
      <Description className='txt__center subtitle'>
        You've chosen to cancel sending your Favorites.
      </Description>
      <Text className='txt__center font__size__small'>
        Please note, this is NOT an agreement to purchase these frames. It is a
        courtesy to you, to{' '}
        <Bold>allow you to simply try them on during your exam</Bold>.
      </Text>

      <ButtonContainer>
        <Button>
          <FDButton handleClick={handleAbandonCartClose}>Go Back</FDButton>
        </Button>
        <Button>
          <FDButton handleClick={handleSaveFavoritesCancel}>Close</FDButton>
        </Button>
      </ButtonContainer>
    </AbandonCart>
  );
};

export default AbandonCartReminder;
