import React, { useContext } from 'react';
import { useState, createContext } from 'react';
import { useProduct } from './product-context';

const VtoContext = createContext();
const useVto = () => useContext(VtoContext);
const VtoContextProvider = (props) => {

  const { product, selectedFrame } = useProduct();
  
  const [isVtoModalOpen, setIsVtoModalOpen] = useState(false);
  const [vtoAppInstance, setVtoAppInstance] = useState(null);

  const closeVto = () => {
    if (vtoAppInstance) {
      console.log('VTO closing...');
      try {
        vtoAppInstance.unmount();
      }
      catch (error) {
        console.log(`ERROR: VTO unmount() failed: ${error}`);
      }
      finally {
        setVtoAppInstance(null);
        console.log('VTO closed.');
      }
    }
  }

  const openVto = () => {
    if (!vtoAppInstance) {
      toggleVto(selectedFrame, product);
    }
  }

  // this needs to be defined stand-alone to keep it from being considered anonymous, which will cause it to be repeatedly added
  const closeTandCModal = (e) => {
    var spanElem = e.currentTarget.firstChild;
    var spanId = spanElem.getAttribute('id');
    //console.log(spanId);
    // "Decline": PRIVACY_BUTTON_CANCEL
    // "Back"   : BIPA_FORM_BUTTON_CANCEL

    // if the user clicks the X button (any context), close down the modal: the X button target has no span child
    // if the user clicks the Decline button, close down the modal
    // if the user clicks Back button, bubble the event, which takes the user back one page (in the modal)
    if (spanId == null || spanId == 'PRIVACY_BUTTON_CANCEL') {
      closeVto();
      setIsVtoModalOpen(false);
      setVtoAppInstance(null);
    }
  }

  // useState() not working for timers so use a local var
  let intervalId = 0;
  const prepareAndShowModals = () => {
    intervalId = setInterval(() => {
    const exitButton      = document.querySelector('div.styles__CloseIconWrapper-sc-qrseoe-2');
    const vtoExitButton = document.querySelector('span.vto-close');
    const declineButton   = document.querySelector('button#bipa-cancel-button');
    //const acceptButton    = document.querySelector('button#bipa-submit-button');
    const mirrorContainer = document.querySelector('div.mirror-container');

      const clearLocalInterval = () => {
        if (intervalId > 0) {
          clearInterval(intervalId);
        }
      }

      const revealCloseButton = () => {
        if (vtoExitButton) {
          vtoExitButton.classList.add('yes-show');
        }
      }

      // At some point the T&C modal will appear (unless the user has already accepted the terms -- see below)

      // If the Terms and Conditions modal is showing we need to add button handlers for it
      if (exitButton && declineButton) {
        exitButton.removeEventListener('click', closeTandCModal);
        exitButton.addEventListener('click', closeTandCModal);
        declineButton.removeEventListener('click', closeTandCModal);
        declineButton.addEventListener('click', closeTandCModal);
      }
      // If the vto container is already showing then the user has already accepted the terms -- including the biometric data collection waiver for Illinois.
      else if (mirrorContainer) {
        revealCloseButton();
        clearLocalInterval();
      }

    }, 150);
  }

  const initVto = (configData) => {
    if (!vtoAppInstance) {
      try {
        console.log("Initializing VTO...");
        const newInstance = new vmmv.VMWidgetApp(configData);
        newInstance.init();
        setVtoAppInstance(newInstance);
        console.log(`...Initializing VTO done:  UPC ${selectedFrame.Upc} has ${selectedFrame?.HasVto ? '' : 'NO '}backend VTO support`);
      }
      catch (error) {
        console.log(`...ERROR: VTO instantiation failed:  ${error}`);
        return;
      }
      finally {
        prepareAndShowModals();
      }
    }
  }

  const toggleVto = (frame, product) => {
    console.log(`VTO is ${vtoAppInstance ? '' : 'NOT '}active`);

    if (vtoAppInstance) {
      closeVto();
      return;
    }
  
    const params = new URLSearchParams(document.location.search.substring(1))
    const locale = params.get('locale') ?? 'en-US';
    const region = params.get('region') ?? 'US';
    const glassesEnv = 'PROD'; //params.get('glassesEnv') ?? 'TEST'
    const vmEnv = 'PRODUCTION'; //params.get('vmEnv') ?? 'STAGING';
    const selectedUpc = frame.Upc.toString() ?? params.get('upc');
  
    if (!selectedUpc || selectedUpc == '') {
      console.log('cannot evaluate selectedUpc');
      return false;
    }
  
    if (!frame?.Upc || frame.Upc == '') {
      console.log('no frame selected');
      return false;
    }
  
    console.log(`setting up Virtual Mirror: frame.Upc=${frame.Upc} : selectedUpc=${selectedUpc}...`);
  
    const vtoProduct = {
      upc: selectedUpc,
      code: product.ModelName,
      name: product.ModelName,
      colorCode: frame.Color,
      thumbnailUrl: frame.PreviewImageURL,
      brand: {
        name: product.Brand,
        logoUrl: frame.PreviewImageURL,
      },
      lensColorLabel: '',
      frameColorLabel: frame.Color,
      styleName: product.ModelName,
      size: frame.FrameSize,
      isTransition: false,
      rxAvailable: false,
    };

    const configData = {
      selector: '.vto-container',
      locale: locale,
      termsAndConditions : '',
      prices: [],
      products: [ vtoProduct ],
      store: {
        id: '',
        storeId: '',
        type: 'rb',
        name: '',
        address: '',
        standAlone: true,
        logo: '',
      },
      analytics: {
        adobeSessionId: '',
        sourcePosition: '',
        pageEnvironment: '',
        storeGlobalId: '',
        style: '',
        pagePlatform: 'QRcodePlatform',
        pageBrand: 'In store - All channel',
        storeId: 'test 8056597079785',
        storeRegion: region,
        storeCompany: 'Generic WHS experience',
        source: 'qrcode',
      },
      style: '',
      fromStore: false,
      vmInit: {
        source: 'VMMV_DITAC',
        key: '4fe983ac-ac4b-41b2-a3b7-3074f2fc49e2',
        channel: 'other',
        brand: 'ditac',
        glassesEnv: glassesEnv,
        environment: vmEnv ? vmEnv.toUpperCase() : undefined,
      },
      onToggleWishlist: pr => {
        //console.log(pr)
        return Promise.resolve()
      },
      onAddToBag: pr => {
        //console.log(pr)
        return Promise.resolve()
      },
      onAddPrescriptionLenses: pr => {
        //console.log(pr)
        return Promise.resolve()
      },
      onShare: (img, pr) => {
        //console.log(img, pr)
        return Promise.resolve()
      },
      onClose: () => {
        // Product.CloseVtoApp('vto', frame, product);
        closeVto();
      },
      transitionFeature: { enabled: false /*transition === 'true'*/ },
      isPictureModeEnabled: true, //pictureMode === 'true',
      isUploadPictureEnabled: true, //uploadPicture === 'true',
      isTakePictureEnabled: true, //takePicture === 'true',
      isTryOnEnabled: true, //liveTryOnMode === 'true',
      isVideoModeEnabled: true, //videoMode === 'true',
      isQrCodeEnabled: false, //qrcode === 'true',
      isTakeScreenshotEnabled: false, //takeScreenshot === 'true',
      addToCartText: '', //addToCartText || null,
      disableSavePolaroid: false, //disableSavePolaroid === 'true',
      experience: '', //experience,
    };
  
    initVto(configData);
  };

  const value = {
    openVto,
    closeVto,
    isVtoModalOpen,
    setIsVtoModalOpen,
    vtoAppInstance,
  };

  return (
    <VtoContext.Provider value={value}>
      {props.children}
    </VtoContext.Provider>
  );
};

export { VtoContextProvider, useVto };
