import * as React from 'react';
import { Button } from './icon-button.styled';
import classNames from 'classnames';

const IconButton = ({ icon, classes, children, ...restProps }) => {
  return (
    <Button className={classNames('icon__button', classes)} {...restProps}>
      {icon}
      {children}
    </Button>
  );
};

export default IconButton;
