import * as React from 'react';
import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useProduct } from '../../provider/product-context';
import { useFavorites } from '../../provider/favorites-context';
import { Button } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Product from '../product/product';
import FDAlert from '../alert/alert';
import FavoritesLimitAlert from '../favorites-limit-alert/favorites-limit-alert';
import { HoverIcon } from '../product/product.styled';
import DoneIcon from '@material-ui/icons/Done';
import { isBrowser } from '../../utils';
import { Event } from '../../utils';
import Icon360 from '../../images/icon-360.png';
import XIcon from '../../images/x-icon';
import { useVto } from '../../provider/vto-context';

const ProductCard = ({ handleNavBack }) => {
  const [maxFavoritesAlert, setMaxFavoriteAlert] = React.useState(false);
  const {
    product,
    selectedFrame,
    setSelectedFrame,
    index,
    setIndex,
    check360
  } = useProduct();
  const {
    favorites,
    reachedMaxLimit,
    addFavorite,
    checkFavorite,
    removeFavorite,
  } = useFavorites();
  const {
    openVto,
    closeVto,
    isVtoModalOpen,
    setIsVtoModalOpen,
    vtoAppInstance,
  } = useVto();

  useEffect(() => {
    document.body.style.overflow = isVtoModalOpen ? 'hidden' : '';
  }, [isVtoModalOpen]);

  useEffect(() => {
    if (isVtoModalOpen) {
      handleActiveCamera();
    }
  }, [isVtoModalOpen]);

  const handleSelectFrame = (item) => {
    Event(
      'FD_Product_Details ',
      'Click_Color',
      `upc:{${item.Upc}},color:{${item.Color}}`
    );
    setSelectedFrame(item);
  };

  const navigateNext = () => {
    setIndex((index + 1) % selectedFrame.List360URLs.length);
  };

  const navigateBack = () => {
    setIndex(
      (index + selectedFrame.List360URLs.length - 1) %
        selectedFrame.List360URLs.length
    );
  };

  const handleBackButton = () => {
    /* navigate(-1); */
    if (isBrowser()) {
      window.history.back();
    }

    Event('FD_Product_Details ', 'Click_Back', `upc:{${selectedFrame.Upc}}`);
    handleNavBack();
  };

  const handleFavorite = (model, frame) => {
    if (reachedMaxLimit()) {
      toggleMaxFavoritesAlert();
    } else {
      addFavorite(model, frame);
    }
  };

  const toggleMaxFavoritesAlert = () => {
    setMaxFavoriteAlert(!maxFavoritesAlert);
  };
  
  const handleActiveCamera = () => {
    openVto();
  };

  const handleVtoClose = () => {
    closeVto();
    setTimeout(() => setIsVtoModalOpen(false), 150);
  };

  const handleTryOnClick = () => {
    setIsVtoModalOpen(true);
  };

  return (
    <>
      {
        isVtoModalOpen &&
        <div id='vtoModal' class='vto-modal'>
          <XIcon class='vto-close' handleClick={handleVtoClose}></XIcon>
          <div class='vto-modal-content'>
            { selectedFrame && <Product.Vto frame={selectedFrame} product={product}></Product.Vto> }
          </div>
        </div>
      }
      <>
        { !vtoAppInstance &&
          <>
            <FDAlert
              open={maxFavoritesAlert}
              body={<FavoritesLimitAlert handleClose={toggleMaxFavoritesAlert} />}
              handleClose={toggleMaxFavoritesAlert}
            />
            <Product className='gutters'>
              <Product.Row>
                <Button onClick={handleBackButton} startIcon={<ArrowBackIcon />}>
                  BACK
                </Button>
              </Product.Row>
              <Product.Row>
                <Product.Preview
                  frame={selectedFrame}
                  product={product}
                  onTryOnClick={handleTryOnClick}
                  isVtoModalOpen={isVtoModalOpen}
                  src={
                    check360(selectedFrame)
                      ? selectedFrame.List360URLs[index]
                      : selectedFrame.PreviewImageURL
                  }
                >
                  {check360(selectedFrame) && (
                    <>
                      <Product.Button
                        className='preview__button'
                        width='auto'
                        height='auto'
                        float
                        left='0'
                        button='left'
                        top='50%'
                        icon={<NavigateBeforeIcon />}
                        onClick={() => {
                          Event(
                            'FD_Product_Details',
                            'Click_360_View',
                            `upc:{${selectedFrame.Upc}}`
                          );
                          navigateBack();
                        }}
                      />
                      <Product.Button
                        className='preview__button'
                        width='auto'
                        height='auto'
                        top='50%'
                        right='0'
                        button='right'
                        float
                        icon={<NavigateNextIcon />}
                        onClick={() => {
                          Event(
                            'FD_Product_Details',
                            'Click_360_View',
                            `upc:{${selectedFrame.Upc}}`
                          );
                          navigateNext();
                        }}
                      />
                    </>
                  )}{' '}
                  {/* <HoverText>Hover to zoom / Click to enlarge</HoverText> */}
                  {check360(selectedFrame) && <HoverIcon src={Icon360}></HoverIcon>}
                </Product.Preview>
    
              <Product.Info>
                <Product.Name>
                  {product.Brand} : {product.ModelName}
                </Product.Name>
                <Product.Description>
                  Eyeglasses{' '}
                  {selectedFrame.Gender ? ` | ${selectedFrame.Gender}` : ''}
                </Product.Description>
                <Product.Code>{`UPC: ${selectedFrame.Upc || ''}`}</Product.Code>
                <Product.Separator />
                <Product.Label>
                  Select Frame Color ({product.Frames?.length} Colors):
                </Product.Label>
                <Product.Text>{selectedFrame.Color}</Product.Text>
                <Product.Selector>
                  <Grid item xs={12}>
                    <Grid container direction='row' alignItems='center' spacing={3}>
                      {product.Frames?.map((frame, index) => (
                        <Grid key={index} item className={checkFavorite(selectedFrame) ? 'favorited-frame' : ''}>
                          {
                            !isVtoModalOpen &&
                            <Product.Highlight
                              className={frame.Upc === selectedFrame.Upc && 'active'}
                            >
                              <Product.Variation
                                src={frame.PreviewImageURL}
                                onClick={(e) => {
                                  handleSelectFrame(frame);
                                }}
                              ></Product.Variation>
                              {check360(frame) && (
                                <Product.Icon
                                  width={35}
                                  top={43}
                                  right={0}
                                  src={Icon360}
                                ></Product.Icon>
                              )}
                            </Product.Highlight>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Product.Selector>
                <Product.Separator />
                {checkFavorite(selectedFrame) ? (
                  <Product.Button
                    outline
                    color='black'
                    icon={<DoneIcon />}
                    onClick={() => {
                      Event(
                        'FD_Product_Details ',
                        'Click_Remove_Favorites',
                        `upc:{${selectedFrame.Upc}},count:{${favorites.length - 1}}`
                      );
                      removeFavorite(selectedFrame);
                    }}
                  >
                    Remove from Favorites
                  </Product.Button>
                ) : (
                  <Product.Button
                    filled
                    color='white'
                    icon={<FavoriteIcon />}
                    onClick={() => {
                      Event(
                        'FD_Product_Details ',
                        'Click_Add_Favorites',
                        `upc:{${selectedFrame.Upc}},count:{${favorites.length + 1}}`
                      );
                      handleFavorite(product, selectedFrame);
                    }}
                  >
                    Add to Favorites
                  </Product.Button>
                )}
                <Product.Button
                  color='var(--app-color-bg-dark-shadow)'
                  underline
                  onClick={() => {
                    Event(
                      'FD_Product_Details ',
                      'Click_Goto_Favorites',
                      `upc:{${selectedFrame.Upc}}`
                    );
                    navigate(`/favorites`);
                  }}
                >
                  Go to Favorites
                </Product.Button>
              </Product.Info>
            </Product.Row>
          </Product>
        </> }
      </>
    </>
  );
};

export default ProductCard;

