import * as React from 'react';
import {
  SelectionContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './lens-selector.styled';

import OldMan from '../../images/lens-select/Old-Man.svg';
import Man from '../../images/lens-select/Man.svg';
import YoungMan from '../../images/lens-select/Young-Man.svg';
import Boy from '../../images/lens-select/Boy.svg';

import OldWoman from '../../images/lens-select/Old-Woman.svg';
import Woman from '../../images/lens-select/Woman.svg';
import YoungWoman from '../../images/lens-select/Young-Woman.svg';
import Girl from '../../images/lens-select/Girl.svg';

const LensPageAge = ({
  handleButtonClick,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  return (
    <SelectionContainer>
      <ImageGrid>
        <Choice>
          <Image
            src={gender === 'M' ? OldMan : OldWoman}
            onClick={() => handleButtonClick(question, '45+ Years Old')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='45+ Years Old'
          >
            45+ Years Old
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? Man : Woman}
            onClick={() => handleButtonClick(question, '30 - 44 Years Old')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='30 - 44 Years Old'
          >
            30 - 44 Years Old
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? YoungMan : YoungWoman}
            onClick={() => handleButtonClick(question, '18 - 29 Years Old')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='18 - 29 Years Old'
          >
            18 - 29 Years Old
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={gender === 'M' ? Boy : Girl}
            onClick={() => handleButtonClick(question, 'For My Children')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(question, e.target.value)}
            value='For My Children'
          >
            For My Children
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </SelectionContainer>
  );
};

export default LensPageAge;
