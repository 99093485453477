import React, { useContext, useEffect, useState, createContext } from 'react';
import { Event } from '../utils';
import { useCatalog } from './catalog-context';
import { useLensSelector } from './lens-context';
import { isBrowser } from '../utils';
import { navigate } from 'gatsby';

const FrameSelectorContext = createContext();
const useFrameSelector = () => useContext(FrameSelectorContext);
const FrameSelectorContextProvider = (props) => {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [gender, setGender] = React.useState('M');
  const lastFrameSelectorIndex = 4;

  const {
    selectOneFacetFilters,
    addOrRemoveFacetFilter,
    addFacetFilterList,
    selectAllOfOneFacet,
    clearAllFacetFilters,
    clearOneFacetFilter,
  } = useCatalog();

  const { lensIndex, handleOpenLensOption } = useLensSelector();

  const checkIfLastScreen = () => {
    if (index >= lastFrameSelectorIndex) {
      handleClose();
      if (lensIndex === 0) {
        handleOpenLensOption();
      }
      return;
    }
  };

  const handleSelectAll = (facetName) => {
    selectAllOfOneFacet(facetName);
    setIndex(index + 1);
    checkIfLastScreen();
  };

  const handleButtonClick = (facetName, facetValue) => {
    Event(
      'FD_Frame_Selector',
      getActionValue(index),
      `filter_name:{${facetName}},filter_value:{${facetValue}}`
    );
    if (facetName === 'Gender') {
      if (facetValue === 'Kids') {
        setGender('M');
        setIndex(index + 3);
        addOrRemoveFacetFilter(facetName, facetValue);
      }
      if (facetValue === 'Women') {
        setGender('F');
        setIndex(index + 1);
        addFacetFilterList('Gender', ['Women', 'Unisex']);
      }
      if (facetValue === 'Men') {
        setGender('M');
        setIndex(index + 1);
        addFacetFilterList('Gender', ['Men', 'Unisex']);
      }
    } else {
      addOrRemoveFacetFilter(facetName, facetValue);
      setIndex(index + 1);
    }
    checkIfLastScreen();
  };

  const handleBack = () => {
    clearOneFacetFilter(
      index === 1
        ? 'Gender'
        : index === 2
        ? 'Type'
        : index === 3
        ? 'Shape'
        : 'Material'
    );
    setIndex(index - 1);
  };

  const getActionValue = (index) => {
    switch (index) {
      case 0:
        return 'Click_Frame_Type_Page_1';
      case 1:
        return 'Click_Frame_Style_Page_2';
      case 2:
        return 'Click_Frame_Shape_Page_3';
      case 3:
        return 'Click_Frame_Material_Page_4';
      case 4:
        return 'Click_Frame_Style_Page_5';
      default:
        return '';
    }
  };

  const handleOpen = () => {
    clearAllFacetFilters();
    setOpen(true);
  };

  const handleClose = () => {
    if(document.getElementById('filters')){
      document.getElementById('filters').scrollIntoView();
    }
    window.scrollBy(0, -64);
    setIndex(0);
    setOpen(false);
  };

  const handleShopClick = (facetName, facetValue) => {
    Event('FD_Catalog', 'Click_Gender_Buttons', `gender_name:{${facetValue}}`);
    setOpen(true);
    if (facetName === 'Gender' && facetValue) {
      if (facetValue === 'Kids') {
        setGender('M');
        setIndex(index + 3);
        selectOneFacetFilters(facetName, facetValue);
      }
      if (facetValue === 'Women') {
        setGender('F');
        setIndex(index + 1);
        selectOneFacetFilters(facetName, facetValue);
      }
      if (facetValue === 'Men') {
        setGender('M');
        setIndex(index + 1);
        selectOneFacetFilters(facetName, facetValue);
      }
    }
  };

  const queryParams = isBrowser()
    ? window.location.search.match(/(\?|&)frame-selector\=([^&]*)/)
    : null;

  React.useEffect(() => {
    if (queryParams) {
      let showFrameSelect = queryParams[2];
      if (showFrameSelect === 'true') {
        navigate('/');
        setOpen(true);
      }
    }
  }, []);

  const value = {
    open,
    setOpen,
    index,
    gender,
    handleSelectAll,
    handleButtonClick,
    handleBack,
    handleOpen,
    handleClose,
    handleShopClick,
  };

  return (
    <FrameSelectorContext.Provider value={value}>
      {props.children}
    </FrameSelectorContext.Provider>
  );
};

export { FrameSelectorContextProvider, useFrameSelector };
