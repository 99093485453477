import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Info = styled.p`
  width: 90%;
  position: absolute;
  top: 10px;
  letter-spacing: 2px;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    width: 80%;
    right: 10%;
  }
`;

export const Number = styled.div`
  margin: 0;
  align-self: center;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: var(--app-font-size-medium);
  padding: 0 5px;
`;

export const Question = styled.h1`
  width: 60%;
  font-weight: 600;
  align-self: center;
  text-align: center;
  color: var(--app-color-primary);
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-top: 60px;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    width: 80%;
  }
`;

export const Instruction = styled.p`
  position: relative;
  width: fit-content;
  align-self: center;
  text-align: center;
  font-style: italic;
  font-size: var(--app-font-size-large);

  &:before {
    content: '';
    position: absolute;
    width: 200px;
    height: 3px;
    bottom: 9px;
    left: 250px;
    background: var(--app-color-bg-dark-shadow);
    display: block;
  }
  &:after {
    content: '';
    position: absolute;
    width: 200px;
    height: 3px;
    bottom: 9px;
    right: 250px;
    background: var(--app-color-bg-dark-shadow);
    display: block;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    &:before {
      width: 125px;
    }
    &:after {
      width: 125px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    font-size: var(--app-font-size);

    &:before {
      width: 75px;
      left: 150px;
    }
    &:after {
      width: 75px;
      right: 150px;
    }
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  align-self: center;
  justify-content: center;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    flex-direction: column;
    overflow: scroll;
    height: 100%;
    display: flex;
    justify-content: revert;
    max-height: 420px;
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    max-height: 100%;
  }
`;

export const SelectAllContainer = styled.div`
  align-self: center;
  margin-top: 15px;
`;

export const SelectAll = styled.div`
  width: fit-content;
  font-size: var(--app-font-size-large);
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -5px;
    background: var(--app-color-bg-dark-shadow);
    display: block;
  }
`;

export const Image = styled.img`
  object-fit: contain;
  height: 250px;
  margin-bottom: -20px;
  margin-top: -40px;
  align-self: center;
  text-align: center;
  max-width: 100%;
  height: auto;
  min-heigth: 300px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    max-height: fit-content;
    width: auto;
    max-height: 175px;
    min-width: 120px;
    margin: -30px 0 !important;
  }
`;

export const ImageSmall = styled.img`
  object-fit: contain;
  height: 200px;
  margin-bottom: -20px;
  margin-top: -40px;
  align-self: center;
  text-align: center;
  max-width: 100%;
  height: 200px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    max-height: fit-content;
    height: auto;

    max-width: max-content;

    width: 140%;
    margin: auto;
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid lightgrey;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const Button = styled.button`
  margin: auto;
  width: fit-content;
  padding: 10px 50px;
  border: none;
  font-size: var(--app-font-size);
  font-weight: 900;
  color: 'black';
  background: var(--app-color-bg-bright);
  border: 1px solid var(--app-color-primary);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  z-index: 2;

  :hover {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    height: 100%;
    min-height: 45px;
  }
`;

export const ButtonHorizontal = styled.button`
  min-width: 230px;
  max-width: 400px;
  margin: auto;
  width: fit-content;
  padding: 10px 50px;
  border: none;
  font-size: var(--app-font-size);
  font-weight: 900;
  color: 'black';
  background: var(--app-color-bg-bright);
  border: 1px solid var(--app-color-primary);
  border-radius: var(--app-border-radius);
  cursor: pointer;
  z-index: 2;
  line-height: 20px;

  :hover {
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: solid 1px var(--app-color-primary-border);
  }

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    width: 100%;
    padding: 12px 35px;
    z-index: 3;
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    height: 45px;
  }
`;

export const Choice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    padding-bottom: 0px;
    min-height: 150px;
  }
`;

export const ChoiceHorizontal = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    flex-direction: row;
  }
`;

export const ChoiceSmall = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    width: 50%;
    padding-bottom: 0px;
  }
`;

export const ImageGrid = styled.div`
  direction: row;
  justify: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    flex-direction: column;
  }
`;

export const ImageGridWrap = styled.div`
  direction: row;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: ${SCREEN_SIZE.md}) {
    flex-wrap: wrap;
  }
`;
