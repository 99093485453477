import * as React from 'react';
import { Container, InnerHeadline, BgBar, Title } from './headline.styled';
import classNames from 'classnames';
import { FlexRow } from '../app/app.styled';

const Headline = ({ classes, children, ...restProps }) => {
  return (
    <Container className={classNames('hero', classes)} {...restProps}>
      <InnerHeadline className={'layout__max-width'}>{children}</InnerHeadline>
    </Container>
  );
};

Headline.Title = ({ children, alt, classes, ...restProps }) => {
  return <h2>{children}</h2>;
};

export default Headline;
