import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const XIcon = ({ handleClick, ...restProps }) => {
  return (
    <span onClick={handleClick} {...restProps}>
      <CloseIcon />
    </span>
  );
};

export default XIcon;
