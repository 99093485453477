import * as React from 'react';
import { Container, Title, Description } from './favorites-limit-alert.styled';
import FDButton from '../button/button';
import { navigate } from 'gatsby';

const FavoritesLimitAlert = ({
  handleOpen,
  handleClose,
  classes,
  children,
  ...restProps
}) => {
  return (
    <Container>
      <Title>It's hard to just pick a few.</Title>
      <Description>
        We are currently limiting your favorites to 10 frames.
      </Description>
      <br />
      <br />
      <FDButton
        handleClick={() => {
          navigate(`/favorites`);
          handleClose();
        }}
      >
        Go to Favorites
      </FDButton>
    </Container>
  );
};

export default FavoritesLimitAlert;
