import * as React from 'react';
import {
  SelectionContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  ChoiceHorizontal,
} from './lens-selector.styled';

import LightExposure from '../../images/lens-select/Light-Exposure.svg';

const LensPageExposure = ({
  handleButtonClick,
  isActive,
  handleSingleSelect,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  return (
    <>
      <SelectionContainer style={{ height: 'auto', overflow: 'unset' }}>
        <Image margin={'0px 0px'} src={LightExposure} />
      </SelectionContainer>
      <SelectionContainer>
        <ImageGrid>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className={'btn__custom'}
              style={{ minWidth: '240px' }}
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='All Day Indoors'
            >
              All Day Indoors
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className={'btn__custom'}
              style={{ minWidth: '240px' }}
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='Mostly Indoors'
            >
              Mostly Indoors
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className={'btn__custom'}
              style={{ minWidth: '240px' }}
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='Both Indoors and Outdoors'
            >
              Both Indoors {'&'} Outdoors
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className={'btn__custom'}
              style={{ minWidth: '240px' }}
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='Mostly Outdoors'
            >
              Mostly Outdoors
            </ButtonHorizontal>
          </ChoiceHorizontal>
          <ChoiceHorizontal>
            <ButtonHorizontal
              className={'btn__custom'}
              style={{ minWidth: '240px' }}
              onClick={(e) => handleButtonClick(question, e.target.value)}
              value='All Day Outdoors'
            >
              All Day Outdoors
            </ButtonHorizontal>
          </ChoiceHorizontal>
        </ImageGrid>
      </SelectionContainer>
    </>
  );
};

export default LensPageExposure;
