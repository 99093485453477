import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';
import { useProduct } from '../provider/product-context';

const PastPurchaseContext = createContext();
const usePastPurchase = () => useContext(PastPurchaseContext);
const PastPurchaseContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [pastPurchaseCurrentPage, setPastPurchaseCurrentPage] = useState(0);
  const [pastPurchasePaginatedArray, setPastPurchasePaginatedArray] = useState(
    []
  );
  const [pastPurchaseProductsPerFrame, setPastPurchaseProductsPerFrame] =
    useState(5);

  useEffect(() => {
    if (pastPurchasePaginatedArray.length === 0) {
      fetchList([]);
    }
  }, []);

  useEffect(() => {
    let cards = products ?? [
      {
        Model: {},
        Frames: [{}],
        PreviewImageURL: '',
        ModelName: '',
        ModelNumber: '',
      },
    ];
    setPastPurchasePaginatedArray([]);
    let index = 0;
    for (
      index = 0;
      index < cards.length;
      index += pastPurchaseProductsPerFrame
    ) {
      let page = cards.slice(index, index + pastPurchaseProductsPerFrame);
      setPastPurchasePaginatedArray((pastPurchasePaginatedArray) => [
        ...pastPurchasePaginatedArray,
        page,
      ]);
    }
  }, [products, pastPurchaseProductsPerFrame]);

  const fetchList = (list) => {
    const url = environment.getBaseAPI() + 'api/frames/purchase';
    const data = {
      AccountCode: environment.getAccountCode(),
      GoCode: environment.getPatientId(),
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': '*',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        AccountCode: data.AccountCode,
        PatientId: data.GoCode,
      }),
      credentials: 'same-origin',
    })
      .then((res) => res.json())
      .then((result) => Promise.resolve(result))
      .then((response) => {
        setPastPurchaseCurrentPage(0);
        setProducts(
          list.concat(
            response.models.map((model) => {
              return {
                ModelNumber: model.modelNumber,
                ModelName: model.modelName,
                Brand: model.brand,
                Frames: model.frameItems.map((frame) => {
                  return {
                    Upc: frame.upc,
                    Fpc: frame.fpc,
                    Color: frame.color,
                    PreviewImageURL: frame.largeImageUrl,
                  };
                }),
                SelectedFrame: {
                  Upc: model.frameItems[0].upc,
                  Fpc: model.frameItems[0].fpc,
                  Color: model.frameItems[0].color,
                  PreviewImageURL: model.frameItems[0].largeImageUrl,
                },
              };
            })
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const value = {
    pastPurchaseCurrentPage,
    setPastPurchaseCurrentPage,
    pastPurchasePaginatedArray,
    setPastPurchaseProductsPerFrame,
  };

  return (
    <PastPurchaseContext.Provider value={value}>
      {props.children}
    </PastPurchaseContext.Provider>
  );
};

export { PastPurchaseContextProvider, usePastPurchase };
