import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';

export const Container = styled.footer`
  margin-top: 50px;
  height: 200px;
  padding: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: var(--app-color-bg-light);
  border-top: 1px solid var(--app-color-bg-light-shadow);
  display: flex;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    display: block;
    height: auto;
    margin-bottom: 75px;
  }
`;
export const AlignLeft = styled.div`
  width: 50%;
  text-align: left;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
  }
`;
export const AlignRight = styled.div`
  width: 50%;
  text-align: end;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
`;

export const EcpName = styled.div`
  text-decoration: none;
  color: var(--app-color-dark);
  font-weight: 600;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-left: 0px;
    margin-right: 25px;
  }
`;

export const EcpInfo = styled.div`
  text-decoration: none;
  color: var(--app-color-dark);
  margin-top: 0.5em;
  address {
    font-style: normal;
  }
  a {
    text-decoration: none;
    color: var(--app-color-dark);
    margin: 0;

    &.txt__blue {
      color: var(--app-color-link);
      font-weight: var(--app-font-weight-bolder);
      cursor: pointer;
      &:hover {
        border-bottom: 1px solid var(--app-color-primary);
        color: var(--app-color-primary);
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-left: 0px;
    margin-right: 25px;
  }
`;

export const Copyright = styled.div`
  text-decoration: none;
  color: var(--app-color-dark);
  margin-top: 2.5em;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-left: 0px;
    margin-right: 25px;
  }
`;

export const LinkContainer = styled.div`
  width: fit-content;
  margin: auto 0 auto auto;
`;

export const FDLink = styled.a`
  margin-left: 25px;
  text-decoration: none;

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-left: 0px;
    margin-right: 25px;
    display: block;
  }
`;

export const FDSnackbar = styled.div`
  background: var(--app-color-bg-bright);
  padding: 10px 20px;
  width: 100%;
  color: var(--app-color-dark);
  font-weight: 900;
  font-size: var(--app-font-large);
  border-top: 1px solid var(--app-color-bg-light-shadow);
  position: relative;
  bottom: -30px;
  position: fixed;
  bottom: 0px;
  justify-content: center;
  background: var(--app-color-bg-bright);
  z-index: 100;

  display: ${(props) => props.isVtoModalOpen ? 'none' : 'flex'};

  h2 {
    font-weight: 900px;
    margin: 0;
  }
  p {
    margin: 0;
    font-weight: normal;
  }
  span {
    padding: 10px 30px;
    display: inline-block;
  }
  a.txt-link {
    color: var(--app-color-dark);
    text-decoration: none;
    :hover {
      color: var(--app-color-primary);
    }
  }
  a:not(.txt-link) {
    display: inline-block;
    padding: 10px 30px;
    position: relative;
    top: -5px;
    border-radius: 5px;
    -webkit-text-decoration: none;
    text-decoration: none;
    background: var(--app-color-primary);
    color: var(--app-color-bg-bright);
    border: 1px solid var(--app-color-primary);
    margin: 15px 0 0 20px;
    white-space: nowrap;
    :hover {
      border: 1px solid var(--app-color-primary);
      color: var(--app-color-primary);
      background: var(--app-color-bg-bright);
    }
  }
  .btn__filter {
    font-size: var(--app-font-size-large);
    border: 2px solid var(--app-color-primary);
    border-radius: 50px;
    padding: 8px 11px 3px 8px;
    height: 40px;
    width: 40px;
    margin: 10px 0 0;
    img {
      position: relative;
      top: 3px;
    }
  }
  .center__width {
  }
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    justify-content: flex-end;

    h2 {
      vertical-align: center;
      font-size: var(--app-font-size-medium);
    }
    a,
    a.txt-link,
    a:not(.txt-link) {
      margin-left: 20px;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const FavoriteIcon = styled.div`
  vertical-align: middle;
  display: inline;
  span {
    padding: 0;
  }
`;
