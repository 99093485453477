import React from 'react';
import { useShortcutMenu } from '../../provider/menus-context';
import { Img, MenuLink } from './menu.styled';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import { FlexRow } from '../app/app.styled';
import { useLocation } from '@reach/router';
import { Event } from '../../utils';

const ShortcutMenu = () => {
  const { shortcutMenu } = useShortcutMenu();
  let pathName = useLocation().pathname;

  return (
    <FlexRow>
      {shortcutMenu.map((item, i) => (
        <MenuLink
          to={item.link}
          key={i}
          className={
            item.link === pathName
              ? 'menu__shortcut__txt txt__bold'
              : 'menu__shortcut__txt'
          }
          onClick={()=>{
            if(item.text === 'home'){
              Event('FD_Header','Click_Home','')
            }else if(item.text === 'favorites'){
              Event('FD_Header','Click_Goto_Favorites','')
            }
          }}
        >
          <span className='menu__shortcut__txt'>{item.text} </span>
          {item.icon && 
            <Img>
              <SVG src={item.icon} aria-label={item.text} />
            </Img>
          }
          {item.trailingText && (
            <span style={{ fontWeight: 700 }}>{item.trailingText} </span>
          )}
        </MenuLink>
      ))}
    </FlexRow>
  );
};

export default ShortcutMenu;
