import * as React from 'react';
import LensOptionModal from '../lens-option/lens-option-modal';
import LenseSelectorModal from '../lens-selector/lens-selector-modal';
import FrameSelectorModal from '../guided-filtering/frame-selector-modal';
import BrowseOptionModal from '../browse-option/browse-option-modal';
import SaveFavoritesFormModal from '../send-favorites-form/save-favorites-form-modal';
import FavoritesLimitAlertModal from '../favorites-limit-alert/favorites-limit-alert-modal';

const ModalOverlay = () => {
  return (
    <>
      <BrowseOptionModal />
      <FrameSelectorModal />
      <LenseSelectorModal />
      <LensOptionModal />
      <SaveFavoritesFormModal />
      <FavoritesLimitAlertModal />
    </>
  );
};

export default ModalOverlay;
