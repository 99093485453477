import * as React from 'react';
import {
  SelectionContainer,
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './lens-selector.styled';

import ScreenGlare from '../../images/lens-select/Screen-Glare.svg';
import GlassSmudge from '../../images/lens-select/Glass-Smudge.svg';
import GlassScratching from '../../images/lens-select/Glass-Scratching.svg';
import DirtRainWind from '../../images/lens-select/Dirt-Rain-Wind.svg';

const LensPageChallenges = ({
  handleButtonClick,
  isActive,
  handleSingleSelect,
  question,
  gender,
  classes,
  children,
  ...restProps
}) => {
  return (
    <SelectionContainer>
      <ImageGrid>
        <Choice>
          <Image
            style={{ margin: '25px' }}
            src={ScreenGlare}
            onClick={() =>
              handleSingleSelect(question, 'Night and Digital Screen Glare')
            }
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Night and Digital Screen Glare')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Night and Digital Screen Glare'
          >
            Night {'&'} Digital Screen Glare
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            style={{ margin: '25px' }}
            src={GlassSmudge}
            onClick={() => handleSingleSelect(question, 'Glass Smudging')}
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Glass Smudging')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Glass Smudging'
          >
            Glass Smudging
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            style={{ margin: '25px' }}
            src={GlassScratching}
            onClick={() => handleSingleSelect(question, 'Glass Scratching')}
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Glass Scratching')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Glass Scratching'
          >
            Glass Scratching
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            style={{ margin: '25px' }}
            src={DirtRainWind}
            onClick={() =>
              handleSingleSelect(question, 'Dirt, Rain and Wind Damage')
            }
          />
          <ButtonHorizontal
            className={
              isActive(question, 'Dirt, Rain and Wind Damage')
                ? 'btn__custom active'
                : 'btn__custom'
            }
            onClick={(e) => handleSingleSelect(question, e.target.value)}
            value='Dirt, Rain and Wind Damage'
          >
            Dirt, Rain {'&'} Wind Damage
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </SelectionContainer>
  );
};

export default LensPageChallenges;
