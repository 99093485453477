import React, { useContext, useEffect } from 'react';
import { useState, createContext } from 'react';
import { isBrowser } from '../utils';
import { environment } from '../environments/environments';

const ProductContext = createContext();
const useProduct = () => useContext(ProductContext);
const ProductContextProvider = (props) => {
  const pathname = () => {
    if (!isBrowser()) {
      return;
    }
    return window.location.href;
  };

  let modelNumber = null;
  let selectedUpc = null;
  if (isBrowser()) {
    if (pathname().indexOf('product/') > 0) {
      let modelAndUpcSplit = pathname()
        .split('/')
        [pathname().split('/').length - 1].split('?upc=');
      if (modelAndUpcSplit[0] === '') {
        modelAndUpcSplit = pathname()
          .split('/')
          [pathname().split('/').length - 2].split('?upc=');
      }
      modelNumber = modelAndUpcSplit[0];
      if (modelAndUpcSplit.length > 0) {
        selectedUpc = modelAndUpcSplit[1];
      }
    }
  }

  const [product, setProduct] = useState({});
  const [selectedFrame, setSelectedFrame] = useState({});
  const [index, setIndex] = useState(2);

  const check360 = (frame) => frame.Has360 && frame.List360URLs.length > 0;

  useEffect(() => {
    if (modelNumber && modelNumber !== product.ModelNumber + '') {
      const data = {
        AccountCode: environment.getAccountCode(),
      };
      if (data.AccountCode && modelNumber) {
        const url =
          environment.getBaseAPI() +
          'api/frames/details?AccountCode=' +
          encodeURIComponent(data.AccountCode) +
          '&ModelNumber=' +
          encodeURIComponent(modelNumber);

        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((result) => Promise.resolve(result))
          .then((product) => {
            let frame = selectedUpc
              ? product.frameItems.find((frame) => frame.upc == selectedUpc)
              : product.frameItems[0];
            setSelectedFrame({
              Upc: frame.upc,
              HasVto: frame.hasVto,
              Fpc: frame.fpc,
              PreviewImageURL: frame.largeImageUrl,
              Color: frame.color,
              Gender: frame.gender,
              EdgeType: frame.frameStyle,
              FrameMaterial: frame.material,
              FrameSize: frame.size,
              FrameShape: frame.shape,
              Has360: frame.has360,
              List360URLs: frame.list360Urls,
            });
            setProduct({
              ModelNumber: product.modelNumber,
              ModelName: product.modelName,
              Brand: product.brand,
              Manufacturer: product.manufacturer,
              Frames: product.frameItems.map((frame) => {
                return {
                  Upc: frame.upc,
                  HasVto: frame.hasVto,
                  Fpc: frame.fpc,
                  PreviewImageURL: frame.largeImageUrl,
                  Color: frame.color,
                  Gender: frame.gender,
                  EdgeType: frame.frameStyle,
                  FrameMaterial: frame.material,
                  FrameSize: frame.size,
                  FrameShape: frame.shape,
                  Has360: frame.has360,
                  List360URLs: frame.list360Urls,
                };
              }),
            });
          })
          .catch((e) => {});
      }
    }
  }, [modelNumber]);

  useEffect(() => {
    if (!pathname().includes('product')) {
      setProduct({});
      setSelectedFrame({});
    }
  }, [pathname()]);

  const value = {
    product,
    selectedFrame,
    setSelectedFrame,
    index,
    setIndex,
    check360
  };

  return (
    <ProductContext.Provider value={value}>
      {props.children}
    </ProductContext.Provider>
  );
};

export { ProductContextProvider, useProduct };
