import styled from 'styled-components';
import { SCREEN_SIZE } from '../app/app.styled';
import HeroBg from '../hero/hero-bg.jpg';

export const Container = styled.div`
  position: relative;
  padding-bottom: 65px;

  h2 {
    position: relative;
    z-index: 2;
    font-size: var(--app-font-size-xlarge);
    max-width: 620px;
    margin-top: 50px;
  }
  h2 {
    font-size: var(--app-font-size-large);
  }

  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    padding-bottom: 0px;

    h2 {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding-left: 0px;
      max-width: 300px;
      padding-left: 20px;
      padding-right: 20px;
    }
    h2 {
      font-size: var(--app-font-size-medium);
    }
  }
`;

export const BgBar = styled.div`
  background-image: url(${HeroBg});
  margin-top: 35px;
  height: 45px;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    margin-top: 40px;
  }
`;

export const InnerHeadline = styled.div`
  width: 90vw;
  margin: auto;
  margin-top: 65px;
  @media only screen and (max-width: ${SCREEN_SIZE.sm}) {
    width: 100%;
    margin-top: 0;
  }
`;

export const Title = styled.h2`
  color: red;
`;
