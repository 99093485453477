import * as React from 'react';
import { Container, HeaderLogo, Headline } from './header.styled';
import { ShortcutMenu } from '../index';
import { navigate } from 'gatsby';
import { useECPLocation } from '../../provider/location.context';
import FrameDreamLogo from '/src/images/FrameDream-Logo.png';
import { Event } from '../../utils';
import { useVto } from '../../provider/vto-context';

const Header = () => {
  const { location } = useECPLocation();
  const { isVtoModalOpen } = useVto();

  const dynamicStyles = `
  :root {
    --app-color-primary: ${location.ThemePrimaryColor} ;
    --app-color-secondary: ${location.ThemeSecondaryColor};
  }`;

  return (
    <Container
      isVtoModalOpen={isVtoModalOpen}
      style={{ backgroundColor: `${location.themeTertiaryColor}` }}
    >
      {<style>{dynamicStyles}</style>}

      <HeaderLogo
        style={{
          backgroundImage: `url(${location.LogoURL})`,
        }}
        onClick={() => {
          Event('FD_Header', 'Click_ECP_Logo', '');
          navigate(`/`);
        }}
      />

      <Headline>
        <h1>{location.Name}</h1>
      </Headline>

      <ShortcutMenu />
    </Container>
  );
};

export default Header;
