import { isBrowser } from '../utils';

export const environment = {
  version: 0.02,
  production: false,
  apiLocal: 'http://localhost:5000/',
  apiProd: 'https://api-preview.4patientcare.com/',
  apiQA: 'https://qa-api-preview.4patientcare.com/',
  apiStage: 'https://stage-api-preview.4patientcare.com/',
  apiDev: 'https://dev-api-preview.4patientcare.com/',
  Id: '25284156096',
  gaLocal: 'UA-198792156-2',
  gaProd: 'UA-198792156-4',
  gaQA: 'UA-198792156-1',
  gaStage: 'UA-198792156-3',
  gaDev: 'UA-198792156-2',
  themePrimaryColor: '#B28E01',
  themeSecondaryColor: '#f9f2d3',
};

environment.getAccountCode = () => {
  if (isBrowser()) {
    let accountCode = localStorage.getItem('accountCode');
    if (accountCode) {
      accountCode = accountCode.toUpperCase();
    }
    return accountCode;
  }
};

environment.getPatientId = () => {
  if (isBrowser()) {
    return localStorage.getItem('patientId');
  }
};

environment.getBaseAPI = () => {
  let api = environment.apiLocal;
  if (isBrowser()) {
    const path = window.location.host;
    switch (true) {
      case path.includes('local'):
        api = environment.apiDev;
        break;
      case path.includes('qa'):
        api = environment.apiQA;
        break;
      case path.includes('stage'):
        api = environment.apiStage;
        break;
      case path.includes('dev'):
        api = environment.apiDev;
        break;
      default:
        environment.production = true;
        api = environment.apiProd;
    }
  }
  return api;
};

environment.getEnv = () => {
  let env = '';
  if (isBrowser()) {
    const path = window.location.host;
    switch (true) {
      case path.includes('local'):
        env = 'local';
        break;
      case path.includes('qa'):
        env = 'qa';
        break;
      case path.includes('stage'):
        env = 'stage';
        break;
      case path.includes('dev'):
        env = 'dev';
        break;
      default:
        environment.production = true;
        env = 'prod';
    }
  }
  return env;
};

environment.getGA = () => {
  let ga = environment.gaLocal;
  if (isBrowser()) {
    const path = window.location.host;
    switch (true) {
      case path.includes('local'):
        ga = environment.gaDev;
        break;
      case path.includes('qa'):
        ga = environment.gaQA;
        break;
      case path.includes('stage'):
        ga = environment.gaStage;
        break;
      case path.includes('dev'):
        ga = environment.gaDev;
        break;
      default:
        ga = environment.gaProd;
    }
  }
  return ga;
};
