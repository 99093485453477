import * as React from 'react';
import {
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './frame-selector.styled';
import Eyeglasses from '../../images/frame-select/kind_eyeglasses.png';
import Sunglasses from '../../images/frame-select/kind_sunglasses.png';

const GuidedScreen2 = ({
  handleButtonClick,
  classes,
  children,
  ...restProps
}) => {
  const facetType = 'Type';

  return (
    <>
      <ImageGrid>
        <Choice>
          <Image
            src={Eyeglasses}
            onClick={() => handleButtonClick(facetType, 'Eyeglasses')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Eyeglasses'
          >
            Eyeglasses
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={Sunglasses}
            onClick={() => handleButtonClick(facetType, 'Sunglasses')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Sunglasses'
          >
            Sunglasses
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </>
  );
};

export default GuidedScreen2;
