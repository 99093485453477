import * as React from 'react';
import {
  ButtonHorizontal,
  Image,
  ImageGrid,
  Choice,
} from './frame-selector.styled';
import Plastic from '../../images/frame-select/material_plastic.png';
import Metal from '../../images/frame-select/material_metal.png';
import Mixed from '../../images/frame-select/material_mixed.png';

const GuidedScreen4 = ({
  handleButtonClick,
  classes,
  children,
  ...restProps
}) => {
  const facetType = 'Material';

  return (
    <>
      <ImageGrid>
        <Choice>
          <Image
            src={Plastic}
            onClick={() => handleButtonClick(facetType, 'Plastic')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Plastic'
          >
            Plastic
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={Metal}
            onClick={() => handleButtonClick(facetType, 'Metal')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Metal'
          >
            Metal
          </ButtonHorizontal>
        </Choice>
        <Choice>
          <Image
            src={Mixed}
            onClick={() => handleButtonClick(facetType, 'Mixed')}
          />
          <ButtonHorizontal
            className='btn__custom'
            onClick={(e) => handleButtonClick(facetType, e.target.value)}
            value='Mixed'
          >
            Mixed
          </ButtonHorizontal>
        </Choice>
      </ImageGrid>
    </>
  );
};

export default GuidedScreen4;
