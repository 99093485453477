import * as React from 'react';
import {
  Question,
  SelectionContainer,
  ButtonHorizontal,
  Image,
  Choice,
  Container,
  Text,
} from './lens-option.styled';

import Lenses from '../../images/lens-select/Lenses.png';

const LensOption = ({
  handleButtonClick,
  handleClose,
  classes,
  children,
  ...restProps
}) => {
  return (
    <>
      <Question>Don't forget your lenses!</Question>
      <Container>
        <Text>
          Tell us about your needs so we can find a customized solution for your
          best vision possible.
        </Text>
      </Container>
      <SelectionContainer style={{ height: 'auto' }}>
        <Image margin={'0px 0px'} src={Lenses} />
      </SelectionContainer>
      <SelectionContainer>
        <Choice style={{ width: 'auto', minHeight: '40px' }}>
          <ButtonHorizontal
            style={{ margin: '10px' }}
            className={'btn__custom'}
            onClick={(e) => handleClose()}
          >
            Skip For Now
          </ButtonHorizontal>
        </Choice>
        <Choice style={{ width: 'auto', minHeight: '40px' }}>
          <ButtonHorizontal
            style={{ margin: '10px' }}
            className={'btn__custom active'}
            onClick={(e) => handleButtonClick()}
          >
            Personalize My Lenses
          </ButtonHorizontal>
        </Choice>
      </SelectionContainer>
    </>
  );
};

export default LensOption;
